import React from "react";
import { useSelector } from "react-redux";
import Mqtt from "../Mqtt/Mqtt";
import FirstLogin from "./FirstLogin/FirstLogin";
import Login from "./Login/Login";
import Main from "./Main/Main";
import TopBar from "./TopBar/TopBar";
import { BASE_URL } from "../config";

const Project = (props) => {
  const user = useSelector((state) => state.user.user);

  const changeUI = () => {
    if (user?.isFirstLogin) {
      return (
        <>
          <FirstLogin />
        </>
      );
    } else if (user) {
      if ([16, 17, 18, 19, 20, 21].includes(user.roleId)) {
        // alert(BASE_URL);
        window.location.href = BASE_URL;
        return (
          <>
            <div>Access denied</div>
          </>
        );
      } else {
        return (
          <>
            <Mqtt />
            <TopBar />
            <Main />
          </>
        );
      }
    } else {
      return <Login />;
    }
  };

  return <>{changeUI()}</>;
};

export default Project;
