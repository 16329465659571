import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDBConfig, UpdateDBConfig } from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { setIsLoading } from "../../../../../redux/slices/userSlice";

const ConfigDB = (props) => {
  const [escToSupTime, setEscToSupTime] = useState("2");
  const [thirdpartyDeviceTime, setThirdpartyDeviceTime] = useState("3");
  const [fallBackOfResTime, setFallBackOfResTime] = useState("2");
  const [nonAttandenceTime, setNonAttandenceTime] = useState("2");
  const [deviceUnknownTime, setDeviceUnknownTime] = useState("2");
  const [deviceOfflineTime, setDeviceOfflineTime] = useState("2");

  const isLoading = useSelector((state) => state.user.isLoading);
  const config = useSelector((state) => state.user.config);

  const dispatch = useDispatch();

  useEffect(() => {
    GetDBConfig(dispatch);
  }, []);

  useEffect(() => {
    if (config) {
      setFallBackOfResTime(config.fallBackOfResTime);
      setEscToSupTime(config.escToSupTime);
      setThirdpartyDeviceTime(config.thirdpartyDeviceTime);
      setNonAttandenceTime(config.nonAttandenceTime);
      setDeviceUnknownTime(config.deviceUnknownTime);
      setDeviceOfflineTime(config.deviceOfflineTime);
    }
  }, [config]);

  const update = async () => {
    dispatch(setIsLoading(true));
    await UpdateDBConfig(dispatch, {
      thirdpartyDeviceTime,
      escToSupTime,
      fallBackOfResTime,
      nonAttandenceTime,
      deviceOfflineTime,
      deviceUnknownTime,
    })
      .then(async (res) => {
        succesToast("Configuration Updated.");
        await GetDBConfig(dispatch);
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  return (
    <>
      <Modal title={"Config"} name={"configDB"} icon={"fa fa-gears"}>
        <form>
          <>
            <div className="row">
              <div className="col-10">
                <div style={{ fontWeight: "bold" }}>
                  Alert Esclation to Supervisor
                </div>
                <div>
                  Time in minutes after which an unattended alert will be
                  escalated to Supervisor. If there was no supervisor the alert
                  request will be escalated to the service contacts
                </div>
              </div>
              <div className="col-2">
                {/* <label className="col-form-label">Select Time:</label> */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={escToSupTime}
                  onChange={(e) => setEscToSupTime(e.target.value)}
                >
                  <option value="1">1 Minute</option>
                  <option value="2">2 Minutes</option>
                  <option value="3">3 Minutes</option>
                  <option value="4">4 Minutes</option>
                  <option value="5">5 Minutes</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-10">
                <div style={{ fontWeight: "bold" }}>
                  Alert Esclation to Serive Contacts
                </div>
                <div>
                  Time in minutes after which an alert redirected to supervisor
                  and remaining unattended will be escalated to the Service
                  Contacts
                </div>
              </div>
              <div className="col-2">
                {/* <label className="col-form-label">Select Time:</label> */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={nonAttandenceTime}
                  onChange={(e) => setNonAttandenceTime(e.target.value)}
                >
                  <option value="1">1 Minute</option>
                  <option value="2">2 Minutes</option>
                  <option value="3">3 Minutes</option>
                  <option value="4">4 Minutes</option>
                  <option value="5">5 Minutes</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-10">
                <div style={{ fontWeight: "bold" }}>
                  Thirdparty alarm escalation
                </div>
                <div>
                  Time in minutes after which an alert reported by third party
                  device will be converted into SOS Residence category
                </div>
              </div>
              <div className="col-2">
                {/* <label className="col-form-label">Select Time:</label> */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={thirdpartyDeviceTime}
                  onChange={(e) => setThirdpartyDeviceTime(e.target.value)}
                >
                  <option value="1">1 Minute</option>
                  <option value="2">2 Minutes</option>
                  <option value="3">3 Minutes</option>
                  <option value="4">4 Minutes</option>
                  <option value="5">5 Minutes</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-10">
                <div style={{ fontWeight: "bold" }}>
                  Alert Fall Back from Responder
                </div>
                <div>
                  Time in minutes after which an unattended alert task sent to a
                  responder will come back to the operator for reassignment
                </div>
              </div>
              <div className="col-2">
                {/* <label className="col-form-label">Select Time:</label> */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={fallBackOfResTime}
                  onChange={(e) => setFallBackOfResTime(e.target.value)}
                >
                  <option value="1">1 Minute</option>
                  <option value="2">2 Minutes</option>
                  <option value="3">3 Minutes</option>
                  <option value="4">4 Minutes</option>
                  <option value="5">5 Minutes</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-10">
                <div style={{ fontWeight: "bold" }}>
                  Responder Device Unknown
                </div>
                <div>
                  Time elapsed in minutes after the last location update was
                  received from a responder device, after which if no update is
                  received, the system will declare its status as UNKNOWN
                </div>
              </div>
              <div className="col-2">
                {/* <label className="col-form-label">Select Time:</label> */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={deviceUnknownTime}
                  onChange={(e) => setDeviceUnknownTime(e.target.value)}
                >
                  <option value="5">5 Minutes</option>
                  <option value="10">10 Minutes</option>
                  <option value="15">15 Minutes</option>
                  <option value="20">20 Minutes</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>
            {/* <hr /> */}
            {/* <div className="row">
              <div className="col-10">
                <div style={{ fontWeight: "bold" }}>
                  Responder Device Offline
                </div>
                <div>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Similique voluptas cum delectus explicabo.
                </div>
              </div>
              <div className="col-2">
                {/* <label className="col-form-label">Select Time:</label> 
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={deviceOfflineTime}
                  onChange={(e) => setDeviceOfflineTime(e.target.value)}
                >
                  <option value="7">7 Minute</option>
                  <option value="8">8 Minute</option>
                  <option value="9">9 Minute</option>
                  <option value="10">10 Minute</option>
                  {/* <option value="5">Device</option> 
                </select>
              </div>
            </div> */}
            <hr />
            <br />
            <div className="row">
              <br />
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-50"
                    onClick={(e) => {
                      e.preventDefault();
                      update();
                    }}
                  >
                    Update Config
                  </button>
                )}
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

export default ConfigDB;
