import React from "react";

const MsgRight = (props) => {
  const formatTime = (t) => {
    let date = new Date(t);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  return (
    <>
      <div
        style={{
          background: "#D9FDD3",
          color: "#111B21",
          display: "block",
          // flexDirection: "row",
          marginTop: "5px",
          padding: "10px",
          borderRadius: "8px",
          marginLeft: "50px",
          marginRight: "5px",
          float: props.direction || "left",
          fontSize: "14px",
          boxShadow: "1px 2px 9px grey",
        }}
      >
        {props.text.msg || ""}
        {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              marginLeft: "8px",
              marginTop: "1px",
              fontSize: "11px",
              color: "grey",
              alignSelf: "flex-end",
            }}
          >
            {formatTime(props.text.createdAt)}
          </div>
          <div
            style={{
              // marginLeft: "8px",
              marginTop: "2px",
              alignSelf: "flex-end",
              // fontSize: "12px",
              // color: "grey",
            }}
          >
            {props.text.msgStage === 1 ? (
              <div style={{ paddingLeft: "4px" }}>
                <i
                  className="fa fa-circle-exclamation"
                  style={{
                    paddingLeft: "5px !important",
                    fontSize: "14px",
                    color: "red",
                  }}
                ></i>
              </div>
            ) : props.text.msgStage === 2 ? (
              <>
                <div style={{ paddingLeft: "4px" }}>
                  <i
                    className="fa fa-check"
                    style={{
                      paddingLeft: "5px !important",
                      fontSize: "14px",
                      color: "grey",
                    }}
                  ></i>
                </div>
              </>
            ) : props.text.msgStage > 2 ? (
              props.text.resReadState === 1 && props.text.cusReadState === 1 ? (
                <img
                  alt=""
                  src={require("../../../../../assets/pins/doubleTick.png")}
                  style={{
                    width: "28px",
                    padding: "2px",
                  }}
                />
              ) : props.text.resReadState === 2 &&
                props.text.cusReadState === 1 ? (
                <img
                  alt=""
                  src={require("../../../../../assets/pins/doubleTick.png")}
                  style={{
                    width: "28px",
                    padding: "2px",
                  }}
                />
              ) : props.text.resReadState === 1 &&
                props.text.cusReadState === 2 ? (
                <img
                  alt=""
                  src={require("../../../../../assets/pins/doubleTick.png")}
                  style={{
                    width: "28px",
                    padding: "2px",
                  }}
                />
              ) : props.text.resReadState === 2 &&
                props.text.cusReadState === 2 ? (
                <img
                  alt=""
                  src={require("../../../../../assets/pins/tickgreen.png")}
                  style={{
                    width: "28px",
                    padding: "2px",
                  }}
                />
              ) : (
                <>
                  <div style={{ paddingLeft: "4px" }}>
                    <i
                      className="fa fa-check"
                      style={{
                        paddingLeft: "5px !important",
                        fontSize: "14px",
                        color: "grey",
                      }}
                    ></i>
                  </div>
                </>
              )
            ) : null}
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="clearfix"></div>
    </>
  );
};

export default MsgRight;
