import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddDeviceAPI,
  GetAllDevice,
  UpdateDeviceAPI,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";

const AddDevice = (props) => {
  const [deviceName, setDeviceName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [type, setType] = useState("1");
  const [network, setNetwork] = useState("");
  const [des, setDes] = useState("");
  const [imei, setImei] = useState("");
  const [contact, setContact] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [edit, setEdit] = useState("");
  const [iconName, setIconName] = useState(null);
  const [groupId, setGroupId] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const editDeviceData = useSelector((state) => state.user.editDeviceData);
  const groups = useSelector((state) => state.user.groups);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editDeviceData) {
      // console.log(editDeviceData);
      setDeviceName(editDeviceData?.name);
      setDeviceId(editDeviceData?.id);
      setIconName(editDeviceData?.iconName);
      setGroupId(editDeviceData?.groupId);
      setImei(editDeviceData?.imei);
      setNetwork(editDeviceData?.network);
      setContact(
        editDeviceData?.contact?.substr(3, editDeviceData?.contact?.length)
      );
      setDes(editDeviceData?.des);
      setUniqueId(editDeviceData?.uniqueId);
      setType(editDeviceData?.type?.toString());
      setDeviceType(editDeviceData?.deviceType?.toString());
      setEdit("1");
      dispatch(setEditDeviceData(null));
    }
  }, [editDeviceData]);

  const Add = async () => {
    if (IsEmpty(deviceName)) {
      errorToast("Device Name is required");
      return;
    }
    if (IsEmpty(deviceType)) {
      errorToast("Vehicle Type is required");
      return;
    }
    if (IsEmpty(type)) {
      errorToast("Device Type is required");
      return;
    }
    if (IsEmpty(imei)) {
      errorToast("IMEI is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Cell Phone # is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network provider is required");
      return;
    }
    if (IsEmpty(iconName)) {
      errorToast("Please select an Icon");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    // if (IsEmpty(uniqueId) && deviceType === "1") {
    //   errorToast("Unique ID is required");
    //   return;
    // }

    dispatch(setIsLoading(true));

    await AddDeviceAPI(dispatch, {
      name: deviceName,
      des,
      network,
      contact: "+92" + contact,
      imei,
      type,
      deviceType,
      iconName,
      groupId,
    })
      .then(async () => {
        setDeviceName("");
        setImei("");
        setNetwork("");
        setContact("");
        setDes("");
        setIconName(null);
        setUniqueId("");
        setType("1");
        setDeviceType("");
        setGroupId("");
        await GetAllDevice(dispatch);
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return <option value={val.id}>{val.name}</option>;
    });
  };

  const emptyFields = (emptyEdit = true) => {
    setDeviceName("");
    setImei("");
    setNetwork("");
    setContact("");
    setDes("");
    setUniqueId("");
    setDeviceType("1");
    setGroupId("");
    if (emptyEdit) {
      setEdit(null);
    }
  };

  const update = async () => {
    if (IsEmpty(deviceId)) {
      errorToast("Device Id is required");
      return;
    }
    if (IsEmpty(deviceName)) {
      errorToast("Device Name is required");
      return;
    }
    if (IsEmpty(deviceType)) {
      errorToast("Vehicle Type is required");
      return;
    }
    if (IsEmpty(type)) {
      errorToast("Device Type is required");
      return;
    }
    if (IsEmpty(imei)) {
      errorToast("IMEI is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Cell Phone # is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network provider is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }

    dispatch(setIsLoading(true));

    await UpdateDeviceAPI(dispatch, {
      deviceId,
      name: deviceName,
      des,
      network,
      contact: "+92" + contact,
      imei,
      type,
      deviceType,
      iconName,
      groupId,
    })
      .then(async () => {
        setDeviceName("");
        setImei("");
        setNetwork("");
        setContact("");
        setDes("");
        setIconName(null);
        setUniqueId("");
        setDeviceType("1");
        await GetAllDevice(dispatch);
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <>
      <Modal title={"Add Device"} name={"addDevice"} icon={"fa fa-mobile"}>
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              onClick={() => {
                setEdit(null);
                emptyFields();
              }}
              title="Go To List"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#listDevices"}
            ></i>
          </a>
        </div>
        <form>
          <>
            <div className="col-4">
              <label className="col-form-label">Select Device Type:</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="1">Mobile</option>
                <option value="2">Tracker</option>
                {/* <option value="5">Device</option> */}
              </select>
            </div>
            <hr />
            <div className="row">
              <div className="mb-3 col-3">
                <MyTextInput
                  name={"Device Name"}
                  val={deviceName}
                  placeholder={"Device Name"}
                  setVal={setDeviceName}
                />
              </div>
              <div className="mb-3 col-3">
                <MyTextInput
                  name={"Description"}
                  val={des}
                  placeholder={"Function of the device"}
                  setVal={setDes}
                />
              </div>
              <div className="col-3">
                <label className="col-form-label">Select Vehicle Type:</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={deviceType}
                  onChange={(e) => setDeviceType(e.target.value)}
                >
                  <option value="">Set Vehicle Type</option>
                  <option value="1">Emergengy</option>
                  <option value="2">Medical</option>
                  <option value="3">Fire</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
              <div className="mb-3 col-3">
                <MyTextInput
                  name={"Device ID"}
                  val={imei}
                  placeholder={""}
                  setVal={setImei}
                />
              </div>

              {/* {deviceType === "1" ? (
                <div className="mb-3 col-3">
                  <label className="col-form-label">Unique Id:</label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <a
                        href="#"
                        className="nav-link"
                        onClick={() => {
                          setUniqueId(uuidv4().substring(0, 6));
                        }}
                      >
                        <i className="fa-solid fa-rotate-right"></i>
                      </a>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Unique Id"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={uniqueId}
                      maxLength={6}
                      onChange={(e) => setUniqueId(e.target.value)}
                      // readOnly={true}
                    />
                  </div>
                </div>
              ) : null} */}
              <div className="mb-3 col-3">
                <MyTextInput
                  name={"Cell Phone #"}
                  val={contact}
                  placeholder={"3xxxxxxxxxx"}
                  setVal={setContact}
                  max={10}
                  pre={true}
                />
              </div>
              <div className="col-3">
                <label className="col-form-label">
                  Select Network Provider:
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)}
                >
                  <option value=""></option>
                  <option value="zong">Zong</option>
                  <option value="warid">Warid</option>
                  <option value="jazz">Jazz</option>
                  <option value="ufone">Ufone</option>
                  <option value="telenor">Telenor</option>
                  <option value="s-com">S-com</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
              <div className="col-3">
                <label className="col-form-label">Select Group:</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={groupId}
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option value=""></option>
                  {groupList()}
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label className="col-form-label">Select Marker Icon</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "bike" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("bike")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/bike.png")}
                      alt=""
                      style={{ width: "24px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    checked={iconName === "car" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("car")}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    <img
                      src={require("../../../../../assets/pins/car.png")}
                      alt=""
                      style={{ width: "24px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    checked={iconName === "firebrigade" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("firebrigade")}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    <img
                      src={require("../../../../../assets/pins/firebrigade.png")}
                      alt=""
                      style={{ width: "24px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    checked={iconName === "ambulance" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("ambulance")}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    <img
                      src={require("../../../../../assets/pins/ambulance.png")}
                      alt=""
                      style={{ width: "24px" }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : edit ? (
                  <button
                    type="submit"
                    className="btn btn-primary w-50"
                    onClick={(e) => {
                      e.preventDefault();
                      update();
                    }}
                  >
                    Update Device
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-50"
                    onClick={(e) => {
                      e.preventDefault();
                      Add();
                    }}
                  >
                    Add Device
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default AddDevice;
