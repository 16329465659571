/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";

import "react-datepicker/dist/react-datepicker.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setAlertDetail,
  setIsAlertLoading,
} from "../../../../../redux/slices/alertSlice";
import { SearchPanics } from "../../../../../actions";

var arr = [];

const AlertHistory = (props) => {
  const dispatch = useDispatch();
  const isAlertLoading = useSelector((state) => state.alert.isAlertLoading);
  const searchedType2Alerts = useSelector(
    (state) => state.alert.searchedType2Alerts
  );
  const searchedCusAlerts = useSelector(
    (state) => state.alert.searchedCusAlerts
  );

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [address, setAddress] = useState("");
  const [allAlerts, setAllAlerts] = useState([]);

  const [currentList, setCurrentList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  // const itemsPerPage = 10;

  useEffect(() => {
    setAllAlerts([...searchedCusAlerts, ...searchedType2Alerts]);
  }, [searchedCusAlerts, searchedType2Alerts]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentList(allAlerts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allAlerts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, allAlerts]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allAlerts.length;

    setItemOffset(newOffset);
  };

  const search = async () => {
    // if (IsEmpty(startTime)) {
    //   errorToast("Start time is required");
    //   return;
    // }
    // if (IsEmpty(endTime)) {
    //   errorToast("End Time is required");
    //   return;
    // }
    let endTimeUTC = endTime ? new Date(endTime).toUTCString() : null;
    let startTimeUTC = startTime ? new Date(startTime).toUTCString() : null;

    dispatch(setIsAlertLoading(true));
    await SearchPanics(dispatch, {
      name,
      address,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
      disposal: status,
    })
      .then(() => {
        dispatch(setIsAlertLoading(false));
      })
      .catch((e) => {
        dispatch(setIsAlertLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  const getList = () => {
    let count = 0;
    return currentList?.map((val, index) => {
      count++;
      return (
        <tr key={index}>
          <th scope="row">{count}</th>
          <td>
            <a
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#alertDetails"}
              href="#"
              className="nav-link"
              style={{ color: "blue" }}
              onClick={() => {
                dispatch(setAlertDetail(val));
              }}
            >
              {`${val.customer?.firstname || ""} ${
                val.customer?.lastname || ""
              }`}
            </a>
          </td>
          <td style={{}}>{`${val.address}`}</td>
          <td style={{}}>
            {new Date(val.createdAt).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </td>
          <td
            title={val.alertType === 2 ? "Responder Alert" : "Customer Alert"}
            style={{}}
          >
            {val.isClose
              ? "Closed by operator"
              : val.isResolved
              ? "Resolved"
              : "Cancelled by initiator"}
          </td>
          <td
            title={val.alertType === 2 ? "Responder Alert" : "Customer Alert"}
            style={{ fontSize: "20px", textAlign: "center" }}
          >
            {val.alertType ? (
              <img
                alt=""
                title="Responder Alert"
                src={require("../../../../../assets/pins/resann.png")}
                style={{
                  width: "34px",
                  // padding: "2px",
                }}
              />
            ) : (
              <img
                alt=""
                title="Customer Alert"
                src={require("../../../../../assets/pins/houseann.png")}
                style={{
                  width: "28px",
                  // padding: "2px",
                }}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Alert History"}
        name={"alertHistory"}
        icon={"fa fa-clock-rotate-left"}
      >
        <div className="">
          <div className="row">
            <div className="col-4">
              {/* <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a> */}
            </div>
            <div className="col-4">
              <div className="text-center">
                <h6>{`Total Records: ${allAlerts.length}`}</h6>
              </div>
            </div>
            <div className="col-4">
              <div className="float-end">
                <select
                  style={{ width: "80px" }}
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value=""></option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
              {/* <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a> */}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">
              <DatePicker
                placeholderText="Start Date Time"
                className="form-control form-control-sm"
                selected={startTime}
                onChange={(date) => setStartTime(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd hh:mm:ss"
              />
            </div>
            <div className="col-3">
              <DatePicker
                placeholderText="End Date Time"
                className="form-control form-control-sm"
                selected={endTime}
                onChange={(date) => setEndTime(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd hh:mm:ss"
              />
            </div>
            <div className="col-1">
              <div className="">
                {isAlertLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      search();
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                )}
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={name}
                      placeholder={"Initiator Name"}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Initiator Name</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={address}
                      placeholder={"Address"}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="col-form-label">Address</label>
                  </th>
                  <th style={{ width: "340px" }}>
                    <div className="row">
                      <div className="col-6">
                        <DatePicker
                          placeholderText="Start Date Time"
                          className="form-control form-control-sm"
                          selected={startTime}
                          onChange={(date) => setStartTime(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd hh:mm:ss"
                        />
                      </div>
                      <div className="col-6">
                        <DatePicker
                          placeholderText="End Date Time"
                          className="form-control form-control-sm"
                          selected={endTime}
                          onChange={(date) => setEndTime(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd hh:mm:ss"
                        />
                      </div>
                    </div>
                    <label className="col-form-label">Generated At</label>
                  </th>

                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="cancelled">Cancelled by initiator</option>
                      <option value="closed">Closed by operator</option>
                      <option value="resolved">Resolved</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Disposal</label>
                  </th>
                  <th style={{ width: "80px" }}>
                    {isAlertLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            width: "40px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">{`Search `}</label>
                  </th>
                </tr>
              </thead>

              <tbody style={{ height: currentList?.length ? "" : "58vh" }}>
                {getList()}
              </tbody>
            </table>
            <div className="row">
              <div>
                <div className="float-end">
                  <ReactPaginate
                    nextLabel="&raquo;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="&laquo;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertHistory;
