import React from "react";
// import { ToastsStore, ToastsContainer } from "react-toasts";
import { useState } from "react";
import { useEffect } from "react";
import mqtt from "mqtt/dist/mqtt";
// import { connect } from "react-redux";

import { errorToast, succesToast, warnToast } from "../helpers/toasts";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewAlertChatMsg,
  AddNewApplication,
  AddNewFreeChatMsg,
  FetchAlertChat,
  FetchFreeChat,
  GetActivePanics,
  GetCenter,
  GetUsers,
  HandleNewAlert,
  HandleUpdateAlert,
  SetAlertChatBadges,
  SetFreeChatBadges,
  UpdateAlertChatMsgFromCus,
  UpdateAlertChatMsgFromRes,
  UpdatedeviceInfo,
  UpdateFreeChatMsg,
  UpdateOperator,
  UpdateResponder,
  UpdateSupervisor,
} from "../actions";
import {
  setAlertChatBadges,
  setChatboxVisible,
  setFreeChatMsgs,
  setOpenedChatbox,
} from "../redux/slices/chatSlice";
import { alertBeep, alertBeepStop, Beep5, Noti1, Sms } from "../Howler/Beeper";
import { MQTT_URL, URL } from "../config";
import axios from "axios";
import { setMqttConnected, setUser } from "../redux/slices/userSlice";

var mqttClient = null;
var oldMsgs = null;
var alertOldMsgs = null;
var golbalModal = null;
var globalOpr = null;
var globalSup = null;
var globalFreeChatBadges = null;
var globalRes = null;
var globalAlertChatBadges = null;
var globalOpenedChatBox = null;
var globalType2Panics = null;
var globalCusPanics = null;
var globalUser = null;
var globalList = null;
var globalApplications = null;

function Mqtt(props) {
  const [client, setClient] = useState("");
  const user = useSelector((state) => state.user.user);
  const supervisors = useSelector((state) => state.user.supervisors);
  const operators = useSelector((state) => state.user.operators);
  const responders = useSelector((state) => state.user.responders);
  const list = useSelector((state) => state.user.devices);
  const requestApplications = useSelector(
    (state) => state.user.requestApplications
  );

  const type2Alerts = useSelector((state) => state.alert.type2Alerts);
  const cusAlerts = useSelector((state) => state.alert.cusAlerts);

  const alertChatBadges = useSelector((state) => state.chat.alertChatBadges);
  const freeChatBadges = useSelector((state) => state.chat.freeChatBadges);
  const openedChatbox = useSelector((state) => state.chat.openedChatbox);
  const freeChatMsgs = useSelector((state) => state.chat.freeChatMsgs);
  const chatbox = useSelector((state) => state.chat.chatBoxVisible);
  const alertChatMsgs = useSelector((state) => state.chat.alertChatMsgs);

  const dispatch = useDispatch();

  const mqttConnect = async () => {
    // console.log("User", user);
    const options = {
      // path: "/mqtt",
      username: `t_${localStorage.getItem("token")}`,
      // username: "sarmad",
      password: "100uos.Uni",
      // keepalive: 0,
    };
    // console.log("mqtt urllllll to connect", MQTT_URL);
    const c = mqtt.connect(MQTT_URL, options);
    // console.log("dsadsadasd", c);
    // await  setClient(c);
    setClient(c);
  };

  useEffect(() => {
    mqttConnect();
  }, []);

  useEffect(() => {
    globalAlertChatBadges = alertChatBadges;
    globalFreeChatBadges = freeChatBadges;
    globalOpenedChatBox = openedChatbox;
    globalUser = user;
    globalType2Panics = type2Alerts;
    globalCusPanics = cusAlerts;
    globalList = list;
  }, [
    freeChatBadges,
    alertChatBadges,
    openedChatbox,
    user,
    type2Alerts,
    cusAlerts,
    list,
  ]);

  useEffect(() => {
    oldMsgs = freeChatMsgs;
    golbalModal = chatbox;
    globalOpr = operators;
    globalSup = supervisors;
    globalRes = responders;
    alertOldMsgs = alertChatMsgs;
    globalApplications = requestApplications;
  }, [
    freeChatMsgs,
    chatbox,
    operators,
    supervisors,
    alertChatMsgs,
    responders,
    requestApplications,
  ]);

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        // console.log("connected to Mqtt", user.serviceKey);
        GetActivePanics(dispatch);
        FetchAlertChat(dispatch);
        FetchFreeChat(dispatch);
        GetUsers(dispatch);
        dispatch(setMqttConnected(true));
        mqttClient = client;
        if (user?.serviceKey) {
          setClient(client);
          doSubscribe(
            `ears/${user.serviceKey}/forceLogout/${user.id.toString()}`
          );
          // doSubscribe(`ears/${user.serviceKey}/alert`);
          doSubscribe(`ears/${user.serviceKey}/responder/location`);
          doSubscribe(`ears/${user.serviceKey}/responder/update`); // useractivation status
          doSubscribe(`ears/${user.serviceKey}/freeChat/${user.id.toString()}`);
          doSubscribe(
            `ears/${user.serviceKey}/freeChat/${user.id.toString()}/ack`
          );
          doSubscribe(
            `ears/${user.serviceKey}/freeChat/${user.id.toString()}/read`
          );
          doSubscribe(
            `ears/${user.serviceKey}/alertChat/${user.id.toString()}`
          );
          doSubscribe(
            `ears/${user.serviceKey}/alertChat/${user.id.toString()}/ack`
          );
          doSubscribe(
            `ears/${user.serviceKey}/alertChat/${user.id.toString()}/read`
          );
          doSubscribe(`ears/${user.serviceKey}/alert/${user.id.toString()}`);
          doSubscribe(
            `ears/${user.serviceKey}/alert/${user.id.toString()}/update`
          );
          if (user.roleId === 1 || user.roleId === 2) {
            doSubscribe(`ears/${user.serviceKey}/operator/update`);
          }
          if (user.roleId === 1) {
            doSubscribe(`ears/${user.serviceKey}/application/new`);
            doSubscribe(`ears/${user.serviceKey}/supervisor/update`);
            doSubscribe(`ears/${user.serviceKey}/alert/admin`);
            doSubscribe(`ears/${user.serviceKey}/alert/admin/update`);
          }
          if (user.roleId === 7 || user.roleId === 13 || user.roleId === 15) {
            doSubscribe(`ears/${user.serviceKey}/alert/admin`);
            doSubscribe(`ears/${user.serviceKey}/alert/admin/update`);
            doSubscribe(`ears/${user.serviceKey}/operator/update`);
            doSubscribe(`ears/${user.serviceKey}/supervisor/update`);
          }
        }
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        dispatch(setMqttConnected(false));
        errorToast(err);
        client.end();
        // alert(JSON.stringify(err.code));
        // if (err.code == 5) {
        //   refreshToken().then(() => {
        //     mqttConnect();
        //   });
        // }
      });
      client.on("reconnect", () => {
        // setConnectStatus('Reconnecting');
      });
      client.on("message", async (topic, message) => {
        // ToastsStore.success(topic);
        const data = JSON.parse(message);
        // console.log(topic, data);
        // responder location
        if (topic === `ears/${user.serviceKey}/responder/location`) {
          UpdatedeviceInfo(dispatch, globalList, globalRes, data);
        } else if (
          topic === `ears/${user.serviceKey}/freeChat/${user.id.toString()}`
        ) {
          // console.log("modal condition", golbalModal, globalOpenedChatBox);
          Sms();

          if (data.toId !== globalOpenedChatBox.id) {
            if (!globalFreeChatBadges.includes(data.fromId)) {
              let arr = [...globalFreeChatBadges];
              arr.push(data.fromId);
              SetFreeChatBadges(dispatch, arr);
            }
          }

          data.msgType = "r";
          data.ackSent = golbalModal && data.toId === globalOpenedChatBox.id;
          await AddNewFreeChatMsg(dispatch, data, oldMsgs);
          const ackObj = { ...data };
          // ackObj.toId = data.fromId;
          // ackObj.fromId = data.toId;
          ackObj.roleId = user.roleId;
          if (golbalModal && data.fromId === globalOpenedChatBox.id) {
            client.publish(
              `ears/${user.serviceKey}/freeChat/read`,
              JSON.stringify(ackObj)
            );
          } else {
            client.publish(
              `ears/${user.serviceKey}/freeChat/ack`,
              JSON.stringify(ackObj)
            );
          }
        } else if (
          topic === `ears/${user.serviceKey}/freeChat/${user.id.toString()}/ack`
        ) {
          // console.log("msg list mqttttttttttttttttttttt", oldMsgs, data);
          UpdateFreeChatMsg(dispatch, data, oldMsgs);
        } else if (
          topic ===
          `ears/${user.serviceKey}/freeChat/${user.id.toString()}/read`
        ) {
          UpdateFreeChatMsg(dispatch, data, oldMsgs);
        } else if (topic === `ears/${user.serviceKey}/alert`) {
          warnToast("New alert");
        } else if (topic === `ears/${user.serviceKey}/responder/update`) {
          UpdateResponder(dispatch, data, globalRes);
        } else if (topic === `ears/${user.serviceKey}/operator/update`) {
          UpdateOperator(dispatch, data, globalOpr);
        } else if (topic === `ears/${user.serviceKey}/supervisor/update`) {
          UpdateSupervisor(dispatch, data, globalSup);
        } else if (
          topic === `ears/${user.serviceKey}/alert/${user.id.toString()}`
        ) {
          if (
            data.statusId == 1 &&
            data.operatorInvolved == null &&
            data.supervisorInvolved == globalUser.id
          ) {
            alertBeep();
          }
          if (data.statusId == 1 && data.operatorInvolved == globalUser.id) {
            alertBeep();
          }
          // warnToast("New alert");
          if (data.lat && data.lng) {
            GetCenter(dispatch, [
              {
                latitude: Number(data.lat),
                longitude: Number(data.lng),
              },
            ]);
          }
          HandleNewAlert(dispatch, data, globalType2Panics, globalCusPanics);
        } else if (topic === `ears/${user.serviceKey}/alert/admin`) {
          // warnToast("New alert admin");
          HandleNewAlert(dispatch, data, globalType2Panics, globalCusPanics);
        } else if (topic === `ears/${user.serviceKey}/alert/admin/update`) {
          HandleUpdateAlert(dispatch, data, globalType2Panics, globalCusPanics);
        } else if (
          topic === `ears/${user.serviceKey}/alert/${user.id.toString()}/update`
        ) {
          if (
            data.isCancelled ||
            data.isClose ||
            data.isResolved ||
            data.isRedirected
          ) {
            // alert(JSON.stringify([...alertChatBadges, ...freeChatBadges]));
            // dispatch(setChatboxVisible(false));
            dispatch(setOpenedChatbox({ id: null, name: null }));
            if (globalAlertChatBadges.includes(data.id)) {
              var badges = [...globalAlertChatBadges];
              var index = badges.indexOf(data.id);
              if (index !== -1) {
                badges.splice(index, 1);
                dispatch(setAlertChatBadges(badges));
              }
            }
            alertBeepStop();
          } else if (data.statusId === 4) {
            if (
              data.operatorInvolved == null &&
              data.supervisorInvolved == globalUser.id
            ) {
              alertBeep();
            }
            if (
              data.operatorInvolved == globalUser.id &&
              globalUser.roleId == "3"
            ) {
              alertBeep();
            }
          } else if (
            data.statusId == 10 &&
            globalUser.id === data.operatorInvolved
          ) {
            alertBeep();
          } else if (data.statusId === 1) {
            if (
              data.operatorInvolved == null &&
              data.supervisorInvolved == globalUser.id
            ) {
              alertBeepStop();

              alertBeep();
            }
          }

          HandleUpdateAlert(dispatch, data, globalType2Panics, globalCusPanics);
        } else if (
          topic === `ears/${user.serviceKey}/alertChat/${user.id.toString()}`
        ) {
          Sms();

          if (globalOpenedChatBox.id != data.panicId) {
            if (!globalAlertChatBadges.includes(data.panicId)) {
              let arr = [...globalAlertChatBadges];
              arr.push(data.panicId);
              SetAlertChatBadges(dispatch, arr);
            }
          }
          data.msgType = "r";
          data.ackSent =
            golbalModal && data.panicId === globalOpenedChatBox.id
              ? true
              : false;
          await AddNewAlertChatMsg(dispatch, data, alertOldMsgs);
          const ackObj = { ...data };
          ackObj.roleId = user.roleId;
          if (golbalModal && data.panicId === globalOpenedChatBox.id) {
            client.publish(
              `ears/${user.serviceKey}/alertChat/read`,
              JSON.stringify(ackObj)
            );
          } else {
            client.publish(
              `ears/${user.serviceKey}/alertChat/ack`,
              JSON.stringify(ackObj)
            );
          }
        } else if (
          topic ===
          `ears/${user.serviceKey}/alertChat/${user.id.toString()}/ack`
        ) {
          if (data.roleId === 5 || data.roleId === 6) {
            UpdateAlertChatMsgFromCus(dispatch, data, alertOldMsgs);
          } else {
            UpdateAlertChatMsgFromRes(dispatch, data, alertOldMsgs);
          }
        } else if (
          topic ===
          `ears/${user.serviceKey}/alertChat/${user.id.toString()}/read`
        ) {
          if (data.roleId === 5 || data.roleId === 6) {
            UpdateAlertChatMsgFromCus(dispatch, data, alertOldMsgs);
          } else {
            UpdateAlertChatMsgFromRes(dispatch, data, alertOldMsgs);
          }
        } else if (topic === `ears/${user.serviceKey}/application/new`) {
          succesToast("New Application Received");
          Noti1();
          AddNewApplication(dispatch, data, globalApplications);
        } else if (
          topic === `ears/${user.serviceKey}/forceLogout/${user.id.toString()}`
        ) {
          client.end(true, {}, () => {
            console.log(
              "connection closed ........................................"
            );
          });
          dispatch(setUser(null));
          localStorage.clear();
          window.location.reload();
        }
      });
      // client.subscribe("ears/test");
    }
  }, [client]);

  const doSubscribe = (topic) => {
    client.unsubscribe(topic);
    client.subscribe(topic);
    // console.log("sub to ->", topic);
  };

  const refreshToken = async () => {
    errorToast("refreshing");
    await axios
      .post(
        URL + "auth/refresh",
        {
          refreshToken: localStorage.getItem("refreshToken"),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // console.warn("refresh token man", res.data);
        localStorage.removeItem("token");
        localStorage.setItem("token", res.data.token);
        localStorage.removeItem("refreshToken");
        localStorage.setItem("refreshToken", res.data.refreshToken);
      })
      .catch((e) => {
        errorToast(e.response.data.err);
      });
  };

  return <></>;
}

// const mapStateToProps = (state) => ({
//   user: state.logger,
//   openedChatbox: state.openedChatbox,
//   alertChatBadges: state.alertChatBadges,
//   freeChatBadges: state.freeChatBadges,
//   operators: state.operators,
//   supervisors: state.supervisors,
//   list: state.listResponders,
//   msgList: state.freeChatMsgs,
//   alertChatMsgs: state.alertChatMsgs,
//   modal: state.chatbox,
//   type2Panics: state.type2Panics,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setFreeChatBadges: (list) => SetFreeChatBadges(dispatch, list),
//   setAlertChatBadges: (list) => SetAlertChatBadges(dispatch, list),
//   setClient: (client) => SetClient(dispatch, client),
//   HandleNewAlert:dispatch, (client) => HandleNewAlert(dispatch,dispatch, client),
//   HandleUpdateAlert:dispatch, (client) => HandleUpdateAlert(dispatch,dispatch, client),
//   updateDevice: (list, data) => UpdatedeviceInfo(dispatch, list, data),
//   UpdateOperator: dispatch, (opr, oprList) => UpdateOperator( dispatch,dispatch, opr, oprList),
//   updateSupervisor: (sup, supList) => UpdateSupervisor(dispatch, sup, supList),
//   addNewFreeChatMsg: (newMsg, msgList) =>
//     AddNewFreeChatMsg(dispatch, newMsg, msgList),
//   addNewAlertChatMsg: (newMsg, msgList) =>
//     AddNewAlertChatMsg(dispatch, newMsg, msgList),
//   UpdateFreeChatMsg: (newMsg, msgList) =>
//     UpdateFreeChatMsg(dispatch, newMsg, msgList),
//   updateAlertChatMsg: (newMsg, msgList) =>
//     UpdateAlertChatMsg(dispatch, newMsg, msgList),
// });

export default Mqtt;

export const sendMsgFreeChat = (msg) => {
  if (mqttClient?.connected) {
    // console.log("masaaasssssssssssssssssssssssssssssssss", msg);
    mqttClient.publish(
      `ears/${globalUser.serviceKey}/freeChat`,
      JSON.stringify(msg)
    );
  }
};

export const sendMsgAlertChat = (msg) => {
  // console.log("msg >>>>>>>>>>>>>>>>>>>>>>>.", msg, mqttClient);
  if (mqttClient?.connected) {
    mqttClient.publish(
      `ears/${globalUser.serviceKey}/alertChat`,
      JSON.stringify(msg)
    );
  }
};

export const checkNotReceivedMsgsFreeChat = (user) => {
  // console.log("msg >>>>>>>>>>>>>>>>>>>>>>>.", msg, mqttClient);
  if (mqttClient?.connected) {
    mqttClient.publish(
      `ears/${user.serviceKey}/hasPendingFreeChatMsg`,
      JSON.stringify(user)
    );
  }
};

export const checkNotReceivedMsgsAlertChat = (user, panic) => {
  // console.log("msg >>>>>>>>>>>>>>>>>>>>>>>.", msg, mqttClient);
  if (mqttClient?.connected) {
    mqttClient.publish(
      `ears/${user.serviceKey}/hasPendingAlertChatMsg`,
      JSON.stringify({ user, panic })
    );
  }
};

export const alertSubscribe = (user, alert) => {
  // console.log("subscribe >>>>>>>>>>>>>>>>>>>>>>>.", alert);
  // if (mqttClient?.connected) {
  //   mqttClient.subscribe(
  //     `ears/${user.serviceKey}/alertChat/${user.id.toString()}/read`
  //   );
  //   mqttClient.subscribe(
  //     `ears/${user.serviceKey}/alertChat/${user.id.toString()}/ack`
  //   );
  //   mqttClient.subscribe(
  //     `ears/${user.serviceKey}/alertChat/${alert.id.toString()}`
  //   );
  // }
};

export const alertUnSubscribe = (user, alert) => {
  // console.log("Un >>>>>> subscribe >>>>>>>>>>>>>>>>>>>>>>>.", alert);
  // if (mqttClient?.connected) {
  //   mqttClient.unsubscribe(
  //     `ears/${user.serviceKey}/alertChat/${user.id.toString()}/read`
  //   );
  //   mqttClient.unsubscribe(
  //     `ears/${user.serviceKey}/alertChat/${user.id.toString()}/ack`
  //   );
  //   mqttClient.unsubscribe(
  //     `ears/${user.serviceKey}/alertChat/${alert.id.toString()}`
  //   );
  // }
};

export const sendReadMsgAckFreeChat = (data, user) => {
  // console.log(":::: msg >>>>>>>>>>>>>>>>>>>>>>>.", data, mqttClient);
  const ackObj = { ...data };
  // ackObj.toId = data.fromId;
  // ackObj.fromId = data.toId;
  ackObj.roleId = user.roleId;
  mqttClient.publish(
    `ears/${globalUser.serviceKey}/freeChat/read`,
    JSON.stringify(ackObj),
    {},
    (error) => {
      errorToast(error);
    }
  );
};

export const sendReadMsgAckAlertChat = (data, user) => {
  const ackObj = { ...data };
  ackObj.roleId = user.roleId;
  mqttClient.publish(
    `ears/${globalUser.serviceKey}/alertChat/read`,
    JSON.stringify(ackObj),
    {},
    (error) => {
      errorToast(error);
    }
  );
};

export const disconnectMqtt = (data, user) => {
  if (mqttClient) {
    mqttClient.end(true, {}, () => {
      console.log("connection closed ........................................");
    });
  }
};

// export default Mqtt;
