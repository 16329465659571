/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconContRound from "../../../../components/IconsContRound";
import {
  setMapStyle,
  setShowStaticMarkerLayer,
} from "../../../../redux/slices/mapSlice";
import { setTheme } from "../../../../redux/slices/themeSlice";

const MqttStatus = (props) => {
  const dispatch = useDispatch();
  const mqttConnected = useSelector((state) => state.user.mqttConnected);

  return (
    <>
      <div
        title={mqttConnected ? "Internet Connected" : "Internet Disconnected"}
        style={{
          position: "absolute",
          bottom: 12,
          left: 8,
          zIndex: 100,
          width: "76px",
          backgroundColor: mqttConnected ? "green" : "red",
          padding: "0px 6px",
          borderRadius: "5px",
          boxShadow: "1px 2px 9px grey",
          textAlign: "center",
        }}
      >
        <div>
          <label
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "white",
            }}
            className="form-check-label"
            for="inlineCheckbox2"
          >
            Internet
          </label>
        </div>
      </div>
    </>
  );
};

export default MqttStatus;
