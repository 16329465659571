import React from "react";
import { useState } from "react";
import "./map.css";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
JavascriptTimeAgo.addLocale(en);
JavascriptTimeAgo.addLocale(ru);

const ResPin = (props) => {
  return (
    <React.Fragment>
      <div style={{}}>
        <div>
          <img
            src={props.pin}
            alt="Marker Pin Icon"
            style={{
              width: props.focused ? "36px" : "26px",
              cursor: "pointer",
              height: props.focused ? "58px" : "44px",
              opacity: 1,
              padding: "4px",
              marginTop: -28,
              marginLeft: -28,
              backgroundColor:
                props.status === 1
                  ? "lightgreen"
                  : props.status === 2
                  ? "#fa9c3e"
                  : "#D94344",
              borderRadius: 5,
              transform: `rotate(${
                props.course ? props.course?.toString() : 0
              }deg)`,
              transformOrigin: "center",
            }}
            onClick={() => {
              // alert(!props.pop);
              props.setPop(true);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResPin;
