/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import MapGL, {
  Marker,
  FullscreenControl,
  NavigationControl,
  Popup,
} from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "./style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";
import DatePicker from "react-datepicker";
import {
  AddMarker,
  DelGeofences,
  DelMarker,
  GetGeofences,
  GetMarkers,
  UpdateGeofences,
  UpdateMarker,
} from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import {
  setGeoIsLoading,
  setUbricaMapStyle,
  setUbricaTheme,
} from "../../../../../redux/slices/mapSlice";
import { IsEmpty } from "../../../../../helpers/utils";
import MarkerAndPop from "./Marker/MarkerAndPop";
import {
  setActionVal,
  setConfirmText,
} from "../../../../../redux/slices/userSlice";

var arr = [];

const ManageMap = (props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [des, setDes] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [active, setActive] = useState("1");
  const [iconName, setIconName] = useState(null);
  const [groupId, setGroupId] = useState("");

  const ubricaMapStyle = useSelector((state) => state.map.ubricaMapStyle);
  const ubricaTheme = useSelector((state) => state.map.ubricaTheme);
  const latitude = useSelector((state) => state.map.latitude);
  const longitude = useSelector((state) => state.map.longitude);
  const staticMarker = useSelector((state) => state.map.staticMarker);
  const geoIsLoading = useSelector((state) => state.map.geoIsLoading);
  // console.log("Supervisor - Geo View");
  const groups = useSelector((state) => state.user.groups);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  const [pop, setPop] = useState(false);
  const [dragPop, setDragPop] = useState(true);
  const [page, setPage] = useState("");
  const [mon, setMon] = useState(true);
  const [tue, setTue] = useState(true);
  const [wed, setWed] = useState(true);
  const [thu, setThu] = useState(true);
  const [fri, setFri] = useState(true);
  const [sat, setSat] = useState(true);
  const [sun, setSun] = useState(true);
  const [edit, setEdit] = useState(false);
  const [markerId, setMarkerId] = useState("");
  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [position, setPosition] = useState({
    longitude: 0,
    latitude: 0,
  });
  const [map, setMap] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  const myPop = (
    <div
      className=""
      style={{
        display: !pop ? "none" : "",
        backgroundColor: "#fff",
        minWidth: 120,
        padding: 10,
        position: "absolute",
        top: -40,
        left: 0,
        lineHeight: "5px",
        boxShadow: "1px 2px 9px grey",
      }}
    >
      <span style={{ zIndex: 1010 }}>
        <span
          className="float-end nav-link"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setPop(false);
          }}
        >
          <i
            className="fa fa-remove"
            style={{ fontSize: "12px", color: "red" }}
          ></i>
        </span>
      </span>
      Hy There Man
    </div>
  );

  const onDragEnd = (lngLat) => {
    setPosition({ longitude: lngLat.lng, latitude: lngLat.lat });
  };

  const mapMarker = () => {
    if (staticMarker?.length > 0) {
      return staticMarker?.map((val, index) => {
        return (
          <React.Fragment key={index}>
            <MarkerAndPop
              deleteMarker={deleteMarker}
              editMarker={editMarker}
              setPage={setPage}
              item={val}
            />
          </React.Fragment>
        );
      });
    }
  };

  const editMarker = (obj) => {
    setEdit(true);
    setMarkerId(obj.id);
    setGroupId(obj.groupId);
    setName(obj.title);
    setDes(obj.des);
    setPosition({
      latitude: obj.lat,
      longitude: obj.lng,
    });
    setIconName(obj.markerLogoName);
  };

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  // let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <MapGL
          mapStyle={ubricaMapStyle}
          style={{ width: "100%", height: "560px" }}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={latitude || 31.4676}
          longitude={longitude || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          {mapMarker()}
          <div className="fullscreen">
            <FullscreenControl />
          </div>
        </MapGL>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    GetMarkers(dispatch);
  }, []);

  useEffect(() => {
    setPosition({
      latitude: latitude,
      longitude: longitude,
    });
  }, [latitude, longitude]);

  const getMarkerMap = () => {
    if (!IsEmpty(name) && iconName) {
      return (
        <MapGL
          mapStyle={ubricaMapStyle}
          style={{ width: "100%", height: "440px" }}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={latitude || 31.4676}
          longitude={longitude || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          {iconName ? (
            <>
              <Marker
                longitude={position.longitude}
                latitude={position.latitude}
                onClick={() => setDragPop(true)}
                onDragEnd={onDragEnd}
                draggable
              >
                <div style={{ zIndex: 12345, cursor: "pointer" }}>
                  <img
                    src={require(`../../../../../assets/pins/${iconName}.png`)}
                    alt=""
                    style={{ width: "48px" }}
                  />
                </div>
              </Marker>
              {dragPop ? (
                <Popup
                  longitude={position.longitude}
                  latitude={position.latitude}
                  closeButton={false}
                  closeOnClick={false}
                  // anchor={"left"}
                  offset={[0, -20]}
                >
                  <div onClick={() => setDragPop(false)}>
                    {"Drag to change its position"}
                  </div>
                </Popup>
              ) : null}
            </>
          ) : null}

          <div className="fullscreen">
            <FullscreenControl />
          </div>
        </MapGL>
      );
    } else {
      return null;
    }
  };

  const addMarker = async () => {
    if (IsEmpty(name)) {
      errorToast("Marker name is required.");
      return;
    }
    if (IsEmpty(iconName)) {
      errorToast("Marker Icon is required.");
      return;
    }
    if (IsEmpty(position.latitude)) {
      errorToast("Lattitude is required.");
      return;
    }
    if (IsEmpty(position.longitude)) {
      errorToast("Longitude is required.");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }

    dispatch(setGeoIsLoading(true));
    await AddMarker(dispatch, {
      title: name,
      des,
      lat: position.latitude,
      lng: position.longitude,
      markerType: 1,
      markerLogoName: iconName,
      groupId,
    })
      .then(async (res) => {
        await GetMarkers(dispatch);
        dispatch(setGeoIsLoading(false));
        empty();
      })
      .catch((e) => {
        dispatch(setGeoIsLoading(false));
      });
    dispatch(setGeoIsLoading(false));
  };

  const editMarkerAction = async () => {
    if (IsEmpty(markerId)) {
      errorToast("Marker ID is required.");
      return;
    }
    if (IsEmpty(name)) {
      errorToast("Marker name is required.");
      return;
    }
    if (IsEmpty(iconName)) {
      errorToast("Marker Icon is required.");
      return;
    }
    if (IsEmpty(position.latitude)) {
      errorToast("Lattitude is required.");
      return;
    }
    if (IsEmpty(position.longitude)) {
      errorToast("Longitude is required.");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    dispatch(setGeoIsLoading(true));
    await UpdateMarker(dispatch, {
      markerId,
      title: name,
      des,
      lat: position.latitude,
      lng: position.longitude,
      markerType: 1,
      markerLogoName: iconName,
      groupId,
    })
      .then(async (res) => {
        await GetMarkers(dispatch);
        dispatch(setGeoIsLoading(false));
        empty();
      })
      .catch((e) => {
        dispatch(setGeoIsLoading(false));
      });
    dispatch(setGeoIsLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "marker") {
      deleteMarkerAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const deleteMarker = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Marker?",
        type: "marker",
        id,
      })
    );
    delBtn.click();
  };

  const deleteMarkerAction = async (markerId) => {
    if (IsEmpty(markerId)) {
      errorToast("Marker ID is required.");
      return;
    }
    await DelMarker(dispatch, markerId)
      .then(async (res) => {
        dispatch(setGeoIsLoading(false));
        await GetMarkers(dispatch);
        empty();
      })
      .catch((e) => {
        dispatch(setGeoIsLoading(false));
      });
    dispatch(setGeoIsLoading(false));
  };

  const empty = () => {
    setName("");
    setIconName("");
    setDes("");
    setMarkerId("");
    setEdit(false);
    setGroupId("");
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return <option value={val.id}>{val.name}</option>;
    });
  };

  return (
    <>
      <Modal
        title={"Map Layer"}
        name={"manageMap"}
        icon={"fa fa-map-location-dot"}
      >
        {page === "" ? (
          <>
            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setPage("add");
                  }}
                  style={{ marginBottom: "10px" }}
                >
                  <i
                    style={{ color: "#438AA2", fontSize: "14px" }}
                    className="fa-solid fa-plus"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add
                </button>
              </div>
            </div>
            <div className="" onMouseEnter={() => setMap(true)}>
              {map ? (
                <>
                  <div
                    className="col-12"
                    style={{ border: "2px solid #438AA2" }}
                  >
                    <a
                      href="#"
                      className="nav-link"
                      onClick={() => {
                        if (ubricaTheme === "light") {
                          dispatch(
                            setUbricaMapStyle(
                              "mapbox://styles/mapbox/satellite-streets-v12"
                            )
                          );
                          dispatch(setUbricaTheme("satellite"));
                        } else {
                          dispatch(
                            setUbricaMapStyle(
                              "mapbox://styles/mapbox/streets-v12"
                            )
                          );
                          dispatch(setUbricaTheme("light"));
                        }
                      }}
                    >
                      <div
                        className="mx-auto my-auto"
                        style={{
                          position: "absolute",
                          width: "35px",
                          height: "35px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                          boxShadow: "1px 2px 9px black",
                          top: 75,
                          left: 26,
                          zIndex: 1212,
                          textAlign: "center",
                          paddingTop: "6px",
                        }}
                      >
                        {ubricaTheme === "light" ? (
                          <i
                            style={{ fontSize: "24px", opacity: 0.9 }}
                            className="fa fa-satellite"
                          ></i>
                        ) : (
                          <i
                            style={{ fontSize: "24px", opacity: 0.9 }}
                            className="fa fa-street-view"
                          ></i>
                        )}
                      </div>
                    </a>
                    {getMap()}
                  </div>
                  <br />
                </>
              ) : (
                <>
                  <div style={{ textAlign: "center" }}>
                    <p> Hover to see map. </p>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  setPage("");
                }}
                style={{ marginBottom: "10px" }}
              >
                <i
                  style={{ color: "#438AA2", fontSize: "14px" }}
                  className="fa-solid fa-chevron-left"
                ></i>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                Go Back
              </button>
            </div>
            {/* <hr /> */}
            <div className="row">
              <div className="col-3">
                <input
                  type="text"
                  placeholder="Title "
                  className="form-control form-control-sm"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-3">
                <input
                  type="text"
                  placeholder="Description"
                  className="form-control form-control-sm"
                  value={des}
                  onChange={(e) => setDes(e.target.value)}
                />
              </div>
              <div className="col-3">
                {/* <label className="col-form-label">Select Group:</label> */}
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={groupId}
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option value="">Select Group</option>
                  {groupList()}
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
              {/* <div className="col-2">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={active}
                  onChange={(e) => setActive(e.target.value)}
                >
                  <option value="1">Active 24 Hours</option>
                  <option value="2">Select active hours</option>
                </select>
              </div> */}
              {active === "2" ? (
                <>
                  <div className="col-2">
                    <DatePicker
                      className="form-control form-control-sm"
                      selected={start}
                      onChange={(date) => setStart(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Start Time"
                    />
                  </div>
                  <div className="col-2">
                    <DatePicker
                      className="form-control form-control-sm"
                      selected={end}
                      onChange={(date) => setEnd(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="End Time"
                    />
                  </div>
                </>
              ) : null}
            </div>
            {active === "2" ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <label className="col-form-label">Repeat</label>
                  </div>
                  <div className="col-12">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={mon}
                        id="inlineCheckbox1"
                        value={mon}
                        onChange={() => setMon(!mon)}
                      />
                      <label class="form-check-label" for="inlineCheckbox1">
                        Mon
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={tue}
                        id="inlineCheckbox2"
                        value={tue}
                        onChange={() => setTue(!tue)}
                      />
                      <label class="form-check-label" for="inlineCheckbox2">
                        Tue
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={wed}
                        id="inlineCheckbox3"
                        value={wed}
                        onChange={() => setWed(!wed)}
                      />
                      <label class="form-check-label" for="inlineCheckbox3">
                        Wed{" "}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={thu}
                        id="inlineCheckbox4"
                        value={thu}
                        onChange={() => setThu(!thu)}
                      />
                      <label class="form-check-label" for="inlineCheckbox4">
                        Thu{" "}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={fri}
                        id="inlineCheckbox5"
                        value={fri}
                        onChange={() => setFri(!fri)}
                      />
                      <label class="form-check-label" for="inlineCheckbox5">
                        Fri{" "}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={sat}
                        id="inlineCheckbox6"
                        value={sat}
                        onChange={() => setSat(!sat)}
                      />
                      <label class="form-check-label" for="inlineCheckbox6">
                        Sat{" "}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={sun}
                        id="inlineCheckbox7"
                        value={sun}
                        onChange={() => setSun(!sun)}
                      />
                      <label class="form-check-label" for="inlineCheckbox7">
                        Sun{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {/* <br /> */}
            <div className="row">
              <div className="col-12">
                <label className="col-form-label">Select Marker Icon</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "000" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("000")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/000.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "111" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("111")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/111.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "222" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("222")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/222.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "333" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("333")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/333.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "444" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("444")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/444.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "555" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("555")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/555.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "666" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("666")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/666.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "777" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("777")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/777.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "888" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("888")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/888.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "999" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("999")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/999.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1100" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1100")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1100.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1122" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1122")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1122.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1133" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1133")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1133.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1144" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1144")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1144.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1155" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1155")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1155.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1166" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1166")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1166.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1177" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1177")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1177.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1188" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1188")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1188.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "1199" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("1199")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/1199.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2211" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2211")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2211.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2222" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2222")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2222.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2233" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2233")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2233.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2244" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2244")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2244.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2255" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2255")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2255.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2266" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2266")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2266.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2277" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2277")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2277.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2288" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2288")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2288.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "2299" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("2299")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/2299.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                {/* <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={iconName === "roadblock" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("roadblock")}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    <img
                      src={require("../../../../../assets/pins/roadblock.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    checked={iconName === "checkpost" ? true : false}
                    value={iconName}
                    onChange={() => setIconName("checkpost")}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    <img
                      src={require("../../../../../assets/pins/checkpost.png")}
                      alt=""
                      style={{ width: "48px" }}
                    />
                  </label>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12">{getMarkerMap()}</div>
            </div>
            {name && iconName ? (
              <div className="col-12">
                <div className="text-center" style={{ marginTop: "10px" }}>
                  {geoIsLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        if (edit) {
                          editMarkerAction();
                        } else {
                          addMarker();
                        }
                      }}
                      style={{ marginBottom: "10px" }}
                    >
                      {edit ? "Update Marker" : "Add Marker"}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </>
        )}
      </Modal>
    </>
  );
};

export default ManageMap;
