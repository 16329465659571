import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconContRound from "../../../../components/IconsContRound";
import { setMapStyle } from "../../../../redux/slices/mapSlice";
import { setTheme } from "../../../../redux/slices/themeSlice";

const ThemeIcon = (props) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 80,
          right: 115,
          zIndex: 100,
        }}
      >
        <a
          onClick={() => {
            if (theme === "light") {
              dispatch(setTheme("dark"));
              dispatch(
                setMapStyle("mapbox://styles/mapbox/navigation-night-v1")
              );
            } else {
              dispatch(setTheme("light"));
              dispatch(setMapStyle("mapbox://styles/mapbox/streets-v9"));
            }
          }}
          className="nav-link"
          href="#Theme"
          role="button"
          title={`${theme === "light" ? "Dark" : "Light"} Mode`}
        >
          <IconContRound name={`${theme === "light" ? "moon" : "sun"}`} />
        </a>
      </div>
      {theme !== "satellite" ? (
        <div
          style={{
            position: "absolute",
            top: 80,
            right: 195,
            zIndex: 100,
          }}
        >
          <a
            onClick={() => {
              dispatch(setTheme("satellite"));
              dispatch(
                setMapStyle("mapbox://styles/mapbox/satellite-streets-v12")
              );
            }}
            className="nav-link"
            href="#Theme"
            role="button"
            title={`Satellite View`}
          >
            <IconContRound name={`satellite`} />
          </a>
        </div>
      ) : null}
    </>
  );
};

export default ThemeIcon;
