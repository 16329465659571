/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconContRound from "../../../../components/IconsContRound";
import {
  setMapStyle,
  setShowStaticMarkerLayer,
} from "../../../../redux/slices/mapSlice";
import { setTheme } from "../../../../redux/slices/themeSlice";

const ManageLayers = (props) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const showStaticMarkerLayer = useSelector(
    (state) => state.map.showStaticMarkerLayer
  );

  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: 40,
          right: 25,
          zIndex: 100,
          width: "120px",
          backgroundColor:
            theme === "light"
              ? "white"
              : theme === "satellite"
              ? "#212529"
              : "white",
          padding: "5px",
          borderRadius: "5px",
          boxShadow: "1px 2px 9px grey",
          textAlign: "center",
        }}
      >
        <div className="form-check form-switch">
          <label
            style={{
              fontSize: "12px",
              color: theme === "satellite" ? "white" : "black",
            }}
            className="form-check-label"
            for="inlineCheckbox2"
          >
            Toggle Layer
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="inlineCheckbox2"
            checked={showStaticMarkerLayer}
            value={showStaticMarkerLayer}
            onChange={(e) =>
              dispatch(setShowStaticMarkerLayer(!showStaticMarkerLayer))
            }
          />
        </div>
      </div>
    </>
  );
};

export default ManageLayers;
