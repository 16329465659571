/* eslint-disable import/no-webpack-loader-syntax */
import React, { useState, useEffect } from "react";

import MapGL, {
  Marker,
  FullscreenControl,
  NavigationControl,
} from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "./style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";
import { AddGeofences, GetGeofences } from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { setGeoIsLoading } from "../../../../../redux/slices/mapSlice";
import { errorToast } from "../../../../../helpers/toasts";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const AddGeofence = (props) => {
  // console.log("Supervisor - Geofence Map");
  const dispatch = useDispatch();
  const geoIsLoading = useSelector((state) => state.map.geoIsLoading);

  const [data, setData] = useState([]);
  const [map, setMap] = useState(false);
  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [geofenceArr, setGeofenceArr] = useState([]);
  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  useEffect(() => {
    if (name !== "") {
      setMap(true);
    } else {
      setMap(false);
    }
  }, [service, name]);

  useEffect(() => {
    // console.log("list >>>>>", geofenceArr);
  }, [geofenceArr]);

  const addGeofence = async () => {
    // console.log("geofence", geofenceArr);
    if (geofenceArr.length === 0) {
      errorToast("Please draw or update a Geofence.");
      return;
    }
    if (geofenceArr.length > 1) {
      errorToast("You can add only one closed geofence at a time.");
      return;
    }

    dispatch(setGeoIsLoading(true));

    for (let i = 0; i < geofenceArr.length; i++) {
      const element = geofenceArr[i];
      // console.log("elelelelelelelele", element);
      await AddGeofences(dispatch, name, element)
        .then(async () => {
          dispatch(setGeoIsLoading(false));
          setName("");
          setGeofenceArr([]);
          await GetGeofences(dispatch);
        })
        .catch((e) => {
          dispatch(setGeoIsLoading(false));
        });
    }
  };

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <MapGL
          initialState={{
            data: {
              type: "FeatureCollection",
              features: [],
            },
          }}
          style={{ width: "100%", height: "500px" }}
          mapStyle={mapStyle}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={props.lat || 31.4676}
          longitude={props.lng || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          <Draw
            pointControl={false}
            lineStringControl={false}
            combineFeaturesControl={false}
            uncombineFeaturesControl={false}
            onDrawSelectionChange={(data) => {
              // alert("Selected : " + data.features[0].id);
            }}
            onDrawDelete={async (geofence) => {
              // console.log("data", geofence);
              var index = -1;
              const arr = geofenceArr;
              const data = arr.filter((val, ind) => {
                if (val.id === geofence.features[0].id) {
                  index = ind;
                  return true;
                }
                return false;
              });
              if (index !== -1) {
                arr.splice(index, 1);
                setGeofenceArr(arr);
              }
            }}
            onDrawCreate={async (d) => {
              // console.log("geofence ", d);
              var limit = d.features.length;
              if (limit - 1 == 0) {
                limit = 0;
              } else {
                limit = limit - 1;
              }
              const arr = geofenceArr;

              arr.push({
                id: d.features[limit].id,
                coordinates: d.features[limit],
              });

              setGeofenceArr(arr);
            }}
            onDrawUpdate={(d) => {
              // console.log("draw update data", d);
              var id = d.features[0].id;
              var limit = d.features.length;
              if (limit - 1 == 0) {
                limit = 0;
              } else {
                limit = limit - 1;
              }
              var index = null;
              var last = d.features[limit];
              const arr = geofenceArr;
              const data = arr.filter((val, ind) => {
                if (val.id === id) {
                  index = ind;
                  return true;
                }
                return false;
              });
              arr[index] = { id: id, coordinates: last };
              setGeofenceArr(arr);
            }}
            // onChange={(d) => {}}
          />
          <div className="fullscreen">
            <FullscreenControl />
            <NavigationControl showZoom position="bottom-right" />
          </div>
        </MapGL>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal title={"Add GeoFence"} name={"addGeofence"} icon={"fa fa-globe"}>
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              title="Go To View"
              onClick={() => setName("")}
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#viewGeofence"}
            ></i>
          </a>
        </div>
        <div className="col-12">
          <input
            type="text"
            placeholder="Enter geofence Name "
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {name ? (
          <>
            <div className="col-12" style={{ border: "2px solid #438AA2" }}>
              {getMap()}
            </div>
            <div className="font-monospace">Add one geofence at a time.</div>
            <div className="text-center">
              {geoIsLoading ? (
                <button className="btn btn-primary" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    addGeofence();
                  }}
                >
                  Add Geofence
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <br />
            <br />
          </>
        )}
      </Modal>
    </>
  );
};

export default AddGeofence;
