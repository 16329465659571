import axios from "axios";
import { URL } from "../config/index";
import { errorToast } from "../helpers/toasts";

const addBearerAuthHeader = (headers) => {
  let token = localStorage.getItem("token");
  // if (!token) throw new Error("unable to get token from localStorage");
  headers.Authorization = "Bearer " + token;
  return headers;
};

export const api = axios.create({
  baseURL: URL,
  timeout: 60000,
  headers: addBearerAuthHeader({}),
  // headers: {'X-Custom-Header': 'foobar'}
});

api.interceptors.response.use(
  async (response) => {
    // console.warn(
    //   "Global Response mannnnnnnnnnnnnnnnnnnnnnnnn",
    //   localStorage.getItem("refreshToken")
    // );

    // await axios({
    //   method: "get",
    //   url: URL + "auth/refresh",
    //   headers: addBearerAuthHeader({}),
    //   data: {
    //     refreshToken: localStorage.getItem("refreshToken"),
    //   },
    // })
    //   .then((res) => {
    //     console.warn("kkkkkkkkkk", response.data);
    //   })
    //   .catch((error) => {
    //     console.warn("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", error);
    //   });

    // const url = URL + "auth/refresh";

    // await axios
    //   .get(url, {
    //     headers: addBearerAuthHeader({}),
    //     data: {
    //       refreshToken: localStorage.getItem("refreshToken"),
    //     },
    //   })
    //   .then(async (response) => {
    //     console.warn(
    //       "><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><",
    //       response.data
    //     );
    //   })
    //   .catch((e) => {
    //     console.warn("refresh error", e.response.data);
    //   });
    return response;
  },
  async (error) => {
    if (
      error.response.status == 400 &&
      error.response.data.err == "TokenExpiredError: jwt expired"
    ) {
      const originalRequest = error.config;
      // console.warn("Refresh token orignal requret", originalRequest);
      const url = URL + "auth/refresh";
      return await axios
        .post(
          url,
          {
            refreshToken: localStorage.getItem("refreshToken"),
          },
          {
            headers: addBearerAuthHeader({}),
          }
        )
        .then((res) => {
          // console.warn("refresh token man", res.data);
          localStorage.removeItem("token");
          localStorage.setItem("token", res.data.token);
          localStorage.removeItem("refreshToken");
          localStorage.setItem("refreshToken", res.data.refreshToken);
          originalRequest.headers["Authorization"] = "Bearer " + res.data.token;
          return axios(originalRequest);
        })
        .catch((e) => {
          // if (!localStorage.getItem("expiredShown")) {
          errorToast("User Token expired. User not logged in.");
          localStorage.setItem("expiredShown", "1");
          // }
          throw new Error("Error");
        });
    }
    throw error;
  }
);

class ApiClient {
  // async getResponders() {
  //   return axios({
  //     method: "post",
  //     url: URL + "responder/all",
  //     headers: addBearerAuthHeader({}),
  //   });
  // }

  async getAlerts() {
    return axios({
      method: "get",
      url: URL + "panic",
      headers: addBearerAuthHeader({}),
    });
  }

  async getActiveAlerts() {
    return axios({
      method: "get",
      url: URL + "panic/active",
      headers: addBearerAuthHeader({}),
    });
  }
}

export const apiClient = new ApiClient();
