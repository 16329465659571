import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../helpers/toasts";
import { IsEmpty } from "../helpers/utils";
import {
  setActionVal,
  setApproveGroupId,
  setDelBtn,
  setRejectNote,
} from "../redux/slices/userSlice";
import MyTextInput from "./MyTextInput";

const DeleteConfirm = (props) => {
  const [note, setNote] = useState("");
  const [groupId, setGroupId] = useState("");

  const dispatch = useDispatch();
  const text = useSelector((state) => state.user.confirmText);
  const groups = useSelector((state) => state.user.groups);

  const deleteBtn = useRef();

  useEffect(() => {
    if (deleteBtn.current) {
      dispatch(setDelBtn(deleteBtn.current));
    }
  }, [deleteBtn.current]);

  const groupListForCus = () => {
    if (groups.length > 1) {
      const newList = groups.filter((val, index) => {
        if (val.id !== 1) {
          return true;
        }
      });
      return newList.map((val, index) => {
        return <option value={val.id}>{val.name}</option>;
      });
    } else {
      return groups.map((val, index) => {
        return <option value={val.id}>{val.name}</option>;
      });
    }
  };

  return (
    <>
      <button
        ref={deleteBtn}
        style={{ display: "none" }}
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasTop"
        aria-controls="offcanvasTop"
      >
        Toggle top offcanvas
      </button>

      <div
        className="offcanvas offcanvas-top"
        tabIndex="-1"
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
        style={{
          width: "480px",
          // marginRight: "550px",
          margin: "0 auto",
          justifyContent: "center",
          alignSelf: "center",
          height:
            text?.type === "rejectReq" ||
            text?.type === "rejectReqModal" ||
            text?.type === "approveReq" ||
            text?.type === "approveReqModal"
              ? "220px"
              : "180px",
          // maxHeight: "230px",
          zIndex: 11111,
          boxShadow: "1px 2px 9px grey",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          // background: "transparent",
        }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasTopLabel">
            <i
              className="fa-solid fa-circle-exclamation"
              style={{ color: "red", fontSize: "24px", marginRight: "12px" }}
            ></i>
            Alert!
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              dispatch(
                setActionVal({
                  action: "0",
                  type: text?.type,
                  id: text?.id,
                })
              );
            }}
          ></button>
        </div>
        <div className="offcanvas-body">
          <h6 style={{ fontWeight: "" }}>
            {text?.msg || "Are you sure you want to delete this user?"}
          </h6>
          {text?.type === "rejectReq" || text?.type === "rejectReqModal" ? (
            <div style={{ margin: "10px 0" }}>
              <MyTextInput
                // name={""}
                val={note}
                setVal={setNote}
                placeholder={"Reject Note"}
              />
            </div>
          ) : null}
          {text?.type === "approveReq" || text?.type === "approveReqModal" ? (
            <div style={{ margin: "10px 0" }}>
              <div className="col-12">
                {/* <label className="col-form-label">Select Group:</label> */}
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={groupId}
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option value="">Select Group</option>
                  {groupListForCus()}
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>
          ) : null}
          <br />

          <div className="float-end">
            <button
              type="button"
              className="btn btn-sm btn-success"
              data-bs-dismiss={
                (text?.type === "rejectReq" ||
                  text?.type === "rejectReqModal" ||
                  text?.type === "approveReq" ||
                  text?.type === "approveReqModal") &&
                IsEmpty(note) &&
                IsEmpty(groupId)
                  ? ""
                  : "offcanvas"
              }
              aria-label="Close"
              style={{ width: "80px" }}
              onClick={() => {
                if (
                  text?.type === "rejectReq" ||
                  text?.type === "rejectReqModal"
                ) {
                  if (IsEmpty(note)) {
                    errorToast("Please enter note.");
                    return;
                  }
                } else if (
                  text?.type === "approveReq" ||
                  text?.type === "approveReqModal"
                ) {
                  if (IsEmpty(groupId)) {
                    errorToast("Please select group.");
                    return;
                  }
                }
                dispatch(setRejectNote(note));
                dispatch(setApproveGroupId(groupId));
                dispatch(
                  setActionVal({
                    action: "1",
                    type: text?.type,
                    id: text?.id,
                  })
                );
                setNote("");
                setGroupId("");
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "10px", width: "80px" }}
              type="button"
              className="btn btn-sm btn-danger"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                dispatch(
                  setActionVal({
                    action: "0",
                    type: text?.type,
                    id: text?.id,
                  })
                );
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteConfirm;
