import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertChatMsgs: [],
  freeChatMsgs: [],
  alertChatSingle: [],
  chatType: "free",
  freeChatBadges: [],
  alertChatBadges: [],
  openedChatbox: { id: null, name: null },
  openedChatboxType: null,
  chatbox: null,
  newBadges: [],
  chatBoxVisible: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setAlertChatMsgs: (state, action) => {
      state.alertChatMsgs = action.payload;
    },
    setFreeChatMsgs: (state, action) => {
      state.freeChatMsgs = action.payload;
    },
    setFreeChatBadges: (state, action) => {
      state.freeChatBadges = action.payload;
    },
    setAlertChatBadges: (state, action) => {
      state.alertChatBadges = action.payload;
    },
    setChatType: (state, action) => {
      state.chatType = action.payload;
    },
    setOpenedChatbox: (state, action) => {
      state.openedChatbox = action.payload;
    },
    setOpenedChatboxType: (state, action) => {
      state.openedChatboxType = action.payload;
    },
    setChatbox: (state, action) => {
      state.chatbox = action.payload;
    },
    setNewBadges: (state, action) => {
      state.newBadges = action.payload;
    },
    setChatboxVisible: (state, action) => {
      state.chatBoxVisible = action.payload;
    },
    setAlertChatSingle: (state, action) => {
      state.alertChatSingle = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAlertChatBadges,
  setAlertChatMsgs,
  setChatType,
  setChatbox,
  setFreeChatBadges,
  setFreeChatMsgs,
  setNewBadges,
  setOpenedChatbox,
  setOpenedChatboxType,
  setChatboxVisible,
  setAlertChatSingle,
} = chatSlice.actions;

export default chatSlice.reducer;
