import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
};

export const reportsSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReports: (state, action) => {
      state.reports = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTheme } = reportsSlice.actions;

export default reportsSlice.reducer;
