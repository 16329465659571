import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const IconContRound = (props) => {
  const theme = useSelector((state) => state.theme.theme);

  return (
    <>
      {theme === "light" ? (
        <>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 30,
              border: "2px solid grey",
              boxShadow: "1px 2px 9px grey",
              textAlign: "center",
              backgroundColor: "grey",
              opacity: 0.4,
            }}
          ></div>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 30,
              textAlign: "center",
              marginTop: "-27px",
            }}
          >
            <i
              style={{ fontSize: "18px", color: "#525252" }}
              className={`fa fa-light fa-${props.name}`}
            ></i>
          </div>
        </>
      ) : theme === "satellite" ? (
        <>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 30,
              border: "2px solid #212529",
              boxShadow: "1px 2px 9px white",
              textAlign: "center",
              backgroundColor: "#212529",
              opacity: 1,
            }}
          ></div>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 30,
              textAlign: "center",
              marginTop: "-27px",
            }}
          >
            <i
              style={{ fontSize: "16px", color: "#438AA2" }}
              className={`fa fa-light fa-${props.name}`}
            ></i>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 30,
              border: "2px solid white",
              boxShadow: "1px 2px 9px white",
              textAlign: "center",
              backgroundColor: "white",
              opacity: 0.4,
            }}
          ></div>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 30,
              textAlign: "center",
              marginTop: "-27px",
            }}
          >
            <i
              style={{ fontSize: "18px", color: "#c9c7c7" }}
              className={`fa fa-light fa-${props.name}`}
            ></i>
          </div>
        </>
      )}
    </>
  );
};

export default IconContRound;
