import axios from "axios";
import { URL } from "./../config/index";
import { apiClient, api } from "./apiClient";
// import { alertUnSubscribe } from "../components/Project/Dashboard/Admin/Mqtt/Mqtt";
import {
  setAdmins,
  setAnnouncements,
  setApplications,
  setApprovedApplications,
  setCusFamily,
  setCustomers,
  setDevices,
  setOperators,
  setRejectedApplications,
  setRequestApplications,
  setResponders,
  setSearchedDevices,
  setSearchedOperators,
  setSearchedResponders,
  setSearchedServiceContacts,
  setSearchedSocietyAdmins,
  setSearchedSupervisors,
  setService,
  setServiceContacts,
  setSocietyAdmins,
  setSupervisors,
  setUser,
  setWhiteList,
  setConfig,
  setDirectorSecurity,
  setSearchedDirectorSecurity,
  setRefresh,
  setInternetStatus,
  setSearchedGroups,
  setGroups,
} from "../redux/slices/userSlice";
import { errorToast, succesToast } from "../helpers/toasts";
import {
  setAlertChatBadges,
  setAlertChatMsgs,
  setAlertChatSingle,
  setChatbox,
  setChatType,
  setFreeChatBadges,
  setFreeChatMsgs,
  setNewBadges,
  setOpenedChatbox,
  setOpenedChatboxType,
} from "../redux/slices/chatSlice";
import {
  setFocusedMarker,
  setGeofences,
  setGeoLatLng,
  setLatLng,
  setStaticMarker,
  setZoom,
} from "../redux/slices/mapSlice";
import {
  setCusAlerts,
  setIsResolveLoading,
  setPanicId,
  setSearchedCusAlerts,
  setSearchedType2Alerts,
  setType2Alerts,
} from "../redux/slices/alertSlice";
import { setAttandance } from "../redux/slices/logsSlice";
import { toast } from "react-toastify";

// const dispatch = useDispatch();

export const UploadFile = async (file, type) => {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("file", file);
  data.append("storageType", type);

  var config = {
    method: "post",
    url: URL + "upload",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };
  const res = await axios(config);
  return res.data;

  // .then(function (response) {
  //   console.log("file uploaded", response.data);
  //   return response.data;
  // })
  // .catch(function (error) {
  //   errorToast("Upload image error");
  //   console.log(error);
  //   throw new Error("Error");
  // });
};

export const UploadCSV = async (file, type) => {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("file", file);

  var config = {
    method: "post",
    url: URL + "whitelist/addBulk",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };
  const res = await axios(config);
  return res.data;

  // .then(function (response) {
  //   console.log("file uploaded", response.data);
  //   return response.data;
  // })
  // .catch(function (error) {
  //   errorToast("Upload image error");
  //   console.log(error);
  //   throw new Error("Error");
  // });
};

// Fetch All Staff
export const GetUsers = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/all";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      // console.log("data", data);
      dispatch(setResponders(data.responders));
      dispatch(setOperators(data.operators));
      dispatch(setSupervisors(data.supervisors));
      dispatch(setSocietyAdmins(data.societyAdmins));
      dispatch(setAdmins(data.setAdmins));
      dispatch(setServiceContacts(data.serviceContacts));
      dispatch(setDirectorSecurity(data.directorSecurity));

      dispatch(setSearchedResponders(data.responders));
      dispatch(setSearchedOperators(data.operators));
      dispatch(setSearchedSupervisors(data.supervisors));
      dispatch(setSearchedSocietyAdmins(data.societyAdmins));
      dispatch(setSearchedServiceContacts(data.serviceContacts));
      dispatch(setSearchedDirectorSecurity(data.directorSecurity));
    })
    .catch((e) => {
      console.error("Get Users Expections");
    });
};

//
export const GetApplicationAPI = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/applications";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      // console.log("data", data);
      dispatch(setApplications(data.all));
      dispatch(setRequestApplications(data.pending));
      dispatch(setApprovedApplications(data.approved));
      dispatch(setRejectedApplications(data.rejected));
    })
    .catch((e) => {
      // console.error("Get Users Expections");
    });
};

export const ApproveApplicationAPI = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/application/approve";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Application Approved.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("approve application exception", e);
    });
};

export const RejectApplicationAPI = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/application/reject";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Application Rejected.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("reject application exception", e);
    });
};

export const ChangeUserPassword = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/changePassword";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Password changed.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("Change password excwption", e);
    });
};

export const DeleteUser = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/delete";
  await api
    .post(url, id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // console.log("asdksakdhaskjdhas", response.data);
      succesToast("User Deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error(" Delete User error ", e);
      throw new Error("Error");
    });
};

export const DeleteServiceContact = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/deleteServiceContact";
  await api
    .post(url, id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User Deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error(" Delete User error ", e);
      throw new Error("Error");
    });
};

export const SearchStaff = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      // console.log("sadsadasdasdasdsadsad", data.directorSecurity);
      dispatch(setSearchedResponders(data.responders));
      dispatch(setSearchedOperators(data.operators));
      dispatch(setSearchedSupervisors(data.supervisors));
      dispatch(setSearchedSocietyAdmins(data.societyAdmins));
      dispatch(setSearchedServiceContacts(data.serviceContacts));
      dispatch(setSearchedDirectorSecurity(data.directorSecurity));
    })
    .catch((e) => {
      console.error("Search Users Expections");
      errorToast(e.response.data.err);
    });
};

export const SearchCustomers = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "customer/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setCustomers(data.customers));
      dispatch(setWhiteList(data.whiteList));
    })
    .catch((e) => {
      console.error("Get Users Expections", e);
    });
};

export const VerifyEmailAPI = async (dispatch, data) => {
  const url = URL + "user/verifyEmail";
  await api
    .post(url, data)
    .then((response) => {
      succesToast("Email Verified");
    })
    .catch((e) => {
      errorToast(e.response?.data?.err || "Something went wrong");
      throw new Error(e);
    });
};

export const SearchDevices = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "device/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setSearchedDevices(data));
    })
    .catch((e) => {
      console.error("Get Devices Expections", e);
    });
};

export const ResetPasswordAPI = async (dispatch, data) => {
  const url = URL + "user/resetPassword";
  await api
    .post(url, data)
    .then((response) => {
      succesToast("Password Updated");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("Error Updating password", e);
    });
};

export const SendResetLink = async (dispatch, data) => {
  const url = URL + "user/sendResetLink";
  await api
    .post(url, data)
    .then((response) => {
      // dispatch(setSearchedDevices(data));
      succesToast("Password reset link sent to provided number or email");
    })
    .catch((e) => {
      console.error("error send link", e);
    });
};

export const SearchAnnouncements = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setAnnouncements(data));
    })
    .catch((e) => {
      console.error("Get Announcements Expections", e);
    });
};

export const DeleteAnnouncement = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement/delete";
  await api
    .post(url, id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Announcement Deleted");
      // console.log("ressssss", response.data);
    })
    .catch((e) => {
      console.error("Delete Announcement Expections", e);
    });
};

export const AddUserAPI = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/addUser";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "User Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding user error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const AddMarker = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "marker";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Marker Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding marker error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateMarker = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "marker/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Marker Updated");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("updating marker error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const GetPosition = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "position/search";
  const res = await api.post(url, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const GetPositionWhileAlert = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "panic/searchEvent";
  const res = await api.post(url, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res;
};

export const GetMarkers = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "marker";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setStaticMarker(data));
    })
    .catch((e) => {
      console.error("Get Marker Expections");
    });
};

export const DelMarker = async (dispatch, markerId = 0) => {
  const token = localStorage.getItem("token");
  var url = URL + "marker/delete";
  let data = {
    markerId,
  };
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await api
    .post(url, data, headers)
    .then((result) => {
      succesToast("Marker Deleted successfully");
    })
    .catch((e) => {
      errorToast("Marker deleting Error");
      // console.log("Error deleted Marker", e);
      throw new Error("Error");
    });
};

export const UpdateUsers = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("updateUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateServiceContact = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/updateServiceContact";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("updateUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateUserProfile = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/updateProfile";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("update User Error", e);
      throw new Error("Error");
    });
};

export const UpdateUserInfo = async (dispatch, props, user) => {
  const newUserData = { ...user };
  const token = localStorage.getItem("token");
  const url = URL + "user/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // console.log("rrrrrrr", response.data);
      newUserData.isFirstLogin = false;
      localStorage.removeItem("userData");
      localStorage.removeItem("user");
      localStorage.setItem("userData", JSON.stringify(newUserData));
      localStorage.setItem("user", JSON.stringify(newUserData));
      dispatch(setUser(newUserData));
      succesToast("User updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("updateUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateWhiteListCustomer = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "whitelist/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Whitelist Memeber updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("update Whitelist Error", e);
      throw new Error("Error");
    });
};

export const AddNewAlertChatMsg = async (dispatch, newMsg, msgList) => {
  const newList = [...msgList];
  newList.push(newMsg);
  dispatch(setAlertChatMsgs(newList));
};

export const UpdateAlertChatMsgFromRes = async (dispatch, data, msgList) => {
  const newList = msgList.map((msg) => {
    if (msg.uuid === data.uuid) {
      return {
        ...msg,
        msgStage: data.msgStage > msg.msgStage ? data.msgStage : msg.msgStage,
        resReadState:
          data.resReadState > msg.resReadState
            ? data.resReadState
            : msg.resReadState,
        ackSent: data.ackSent,
      };
    }
    return msg;
  });
  dispatch(setAlertChatMsgs(newList));
};

export const UpdateAlertChatMsgFromCus = async (dispatch, data, msgList) => {
  const newList = msgList.map((msg) => {
    if (msg.uuid === data.uuid) {
      return {
        ...msg,
        msgStage: data.msgStage > msg.msgStage ? data.msgStage : msg.msgStage,
        cusReadState:
          data.cusReadState > msg.cusReadState
            ? data.cusReadState
            : msg.cusReadState,
        ackSent: data.ackSent,
      };
    }
    return msg;
  });
  dispatch(setAlertChatMsgs(newList));
};

export const UpdateAlertChatMsg = async (dispatch, data, msgList) => {
  const newList = msgList.map((msg) => {
    if (msg.uuid === data.uuid) {
      return {
        ...msg,
        ackSent: data.ackSent,
      };
    }
    return msg;
  });
  dispatch(setAlertChatMsgs(newList));
};

export const AddNewFreeChatMsg = async (dispatch, newMsg, msgList) => {
  const newList = [...msgList];
  newList.push(newMsg);
  // console.log("mmmm", newList);
  dispatch(setFreeChatMsgs(newList));
};

export const AddNewApplication = async (
  dispatch,
  newApplication,
  applicationList
) => {
  const newList = [...applicationList];
  newList.push(newApplication);
  dispatch(setRequestApplications(newList));
};

export const UpdateFreeChatMsg = async (dispatch, data, msgList) => {
  const newList = msgList.map((msg) => {
    if (msg.uuid === data.uuid) {
      return {
        ...msg,
        msgStage: data.msgStage > msg.msgStage ? data.msgStage : msg.msgStage,
        resReadState:
          data.resReadState > msg.resReadState
            ? data.resReadState
            : msg.resReadState,
        ackSent: data.ackSent,
      };
    }
    return msg;
  });
  dispatch(setFreeChatMsgs(newList));
};

export const UpdateFreeChatMsgBulk = async (dispatch, data) => {
  dispatch(setFreeChatMsgs(data));
};

// TODO??
// export const GetNatures = (dispatch) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "nature";
//   api
//     .get(url)
//     .then((response) => {
//       //   console.log(response.data);
//       let data = response.data.result.data;
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.GET_NATURES,
//           payload: data,
//         });
//       } else {
//         console.log("GET Natures Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Get Natures Expections");
//     });
// };

// TODO??
// export const GetStatuses = (dispatch) => {
//   const token = localStorage.getItem("token");

//   const url = URL + "status";
//   api
//     .get(url)
//     .then((response) => {
//       //   console.log(response.data);
//       let data = response.data.result.data;
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.GET_STATUSES,
//           payload: data,
//         });
//       } else {
//         console.log("GET Status Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Get Status Expections");
//     });
// };

export const GetActivePanics = async (dispatch) => {
  // console.log("fettttttttttttttttt alertssssssssssssssssssss");
  try {
    const response = await apiClient.getActiveAlerts();

    let data = response.data;
    // console.log("dadadadadada", data);
    let type2Alerts = data.filter(
      (item) =>
        item.isCancelled !== true &&
        item.isClose !== true &&
        item.alertType === 2
    );

    let allCusPanics = data.filter(
      (item) =>
        item.isCancelled !== true &&
        item.isClose !== true &&
        item.isResolved !== true &&
        item.alertType !== 2
    );

    // console.log("alerts >>>>>>>>>>>>>.", allCusPanics, type2Alerts, data);

    dispatch(setType2Alerts(type2Alerts));
    dispatch(setCusAlerts(allCusPanics));
  } catch (error) {
    console.error("panic retrive error", error);
  }
};

export const SearchPanics = async (dispatch, props = {}) => {
  try {
    const token = localStorage.getItem("token");
    const url = URL + "panic/search";
    await api
      .post(url, props, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(async (response) => {
        // alert("here");
        let data = response.data;
        let type2Alerts = data.filter((item) => item.alertType === 2);

        let allCusPanics = data.filter((item) => item.alertType !== 2);
        dispatch(setSearchedType2Alerts(type2Alerts));
        dispatch(setSearchedCusAlerts(allCusPanics));
      })
      .catch((e) => {
        alert(JSON.stringify(e.response));
        errorToast(e.response.data.err || "Something went wrong");
        console.error("Get Devices Expections", e);
      });

    // const response = await apiClient.getAlerts();

    // let data = response.data;
    // let type2Alerts = data.filter((item) => item.alertType === 2);

    // let allCusPanics = data.filter((item) => item.alertType !== 2);

    // // console.log("alerts >>>>>>>>>>>>>.", allCusPanics, type2Alerts, data);

    // dispatch(setSearchedType2Alerts(type2Alerts));
    // dispatch(setSearchedCusAlerts(allCusPanics));
  } catch (error) {
    console.error("panic retrive error", error);
  }
};

export const SearchStaffAttandance = async (dispatch, props) => {
  try {
    const token = localStorage.getItem("token");
    const url = URL + "logs/staffAttandance";
    await api
      .post(url, props, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        dispatch(setAttandance(response.data));
      })
      .catch((e) => {
        errorToast(e.response.data.err);
        console.error("updateUser Error", e);
        throw new Error("Error");
      });
  } catch (error) {
    console.error("attandance error", error);
  }
};

// TODO
// export const GetPanics = async (dispatch) => {
//   try {
//     const response = await apiClient.getAlerts();
//     let data = response.data;
//     let summary = data.filter(
//       (item) =>
//         item.isClose === true ||
//         item.isResolved === true ||
//         item.isCancelled === true
//     );

//     summary = summary.filter((item) => {
//       var plus24Hour = new Date(item.panicGeneratedDate);
//       plus24Hour.setHours(plus24Hour.getHours() + 24);
//       if (Date.parse(Date()) < Date.parse(plus24Hour)) {
//         return true;
//       }
//     });
//     // console.error("summary 2", summary);
//     let cancelled = summary.filter((item) => item.isCancelled === true);
//     let resolved = summary.filter((item) => item.isResolved === true);
//     let onlyClosed = summary.filter((item) => item.isClose === true);
//     let closed = summary.filter(
//       (item) => item.isClose === true || item.isResolved === true
//     );
//     let type2Alerts = data.filter(
//       (item) =>
//         item.isCancelled !== true &&
//         // item.statusId === 1 &&
//         item.isClose !== true &&
//         // item.statusId === 1 &&
//         item.alertType === 2
//     );

//     let type2 = type2Alerts.filter(
//       (item) =>
//         item.isCancelled !== true &&
//         // item.statusId === 1 &&
//         item.isClose !== true &&
//         item.statusId === 1
//     );

//     let allPanics = data.filter(
//       (item) =>
//         item.isCancelled !== true &&
//         item.isClose !== true &&
//         item.isResolved !== true &&
//         item.alertType !== 2
//     );
//     data = allPanics;
//     let inQuery = data.filter(
//       (item) =>
//         item.isCancelled !== true &&
//         item.statusId === 1 &&
//         item.isClose !== true
//       // &&
//       // item.operatorInvolved !== 0 &&
//       // item.operatorInvolved != null &&
//       // item.supervisorInvolved !== 0 &&
//       // item.supervisorInvolved != null
//     );

//     let ConfirmedByOperator = data.filter(
//       (item) =>
//         (item.statusId === 2 || item.statusId === 10) &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     let AssignedToResponder = data.filter(
//       (item) =>
//         item.statusId === 3 &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     let ResolvedByResponder = data.filter(
//       (item) =>
//         item.statusId === 4 &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     let ResolvedByOperator = data.filter(
//       (item) =>
//         item.statusId === 5 &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     let ClosedBySupervisor = data.filter(
//       (item) =>
//         item.statusId === 6 &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     let ConfirmedByResponder = data.filter(
//       (item) =>
//         item.statusId === 7 &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     let ClosedByCustomer = data.filter(
//       (item) =>
//         item.statusId === 8 &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     let type2Confirmed = type2Alerts.filter(
//       (item) =>
//         item.statusId >= 2 &&
//         item.statusId < 9 &&
//         item.isCancelled !== true &&
//         item.isClose !== true
//     );
//     type2 = [...type2, ...type2Confirmed];
//     // console.warn("dddddddddddddddddddddddddddddd", allPanics);
//     dispatch({
//       type: Actions.GET_PANICS,
//       payload: {
//         data: allPanics,
//         inQuery,
//         ConfirmedByOperator,
//         AssignedToResponder,
//         ConfirmedByResponder,
//         ResolvedByResponder,
//         ResolvedByOperator,
//         ClosedBySupervisor,
//         ClosedByCustomer,
//         cancelled,
//         closed,
//         type2,
//         type2Confirmed,
//         resolved,
//         onlyClosed,
//       },
//     });
//   } catch (error) {
//     console.error("panic retrive error", error);
//   }
// };

// TODO??
// export const GetServices = (dispatch) => {
//   const t = localStorage.getItem("token");

//   const url = URL + "service";
//   api
//     .get(
//       url,
//       {},
//       {
//         headers: {
//           Authorization: "Bearer " + t,
//         },
//       }
//     )
//     .then((response) => {
//       // console.warn("res service >>>>>>> ", response.data);
//       let data = response.data;
//       // let code = response.data.result.status;
//       // if (code === 200) {
//       dispatch({
//         type: Actions.GET_SERVICES,
//         payload: data,
//       });
//       // } else {
//       //   console.log("GET Services Invalid Response");
//       // }
//     })
//     .catch((e) => {
//       console.error("Get Services Expections");
//     });
// };

// TODO??
// export const GetThirdParty = (dispatch) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "third-party";
//   api
//     .get(url)
//     .then((response) => {
//       //   console.log(response.data);
//       let data = response.data.result.data;
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.GET_THIRD_PARTY,
//           payload: data,
//         });
//       } else {
//         console.log("GET Third Party Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Get Third Party Expections");
//     });
// };

export const GetAllDevice = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "device";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;

      dispatch(setDevices(data));
      dispatch(setSearchedDevices(data));
    })
    .catch((e) => {
      errorToast("Error Getting devices");
      console.error("Get Devices Expections", e);
    });
};

export const UpdatedeviceInfo = async (dispatch, list, resList, data) => {
  let newList = [];
  let newResList = [];
  if (list.length === 0) {
    newList.push(data);
  } else {
    for (let i = 0; i < list.length; i++) {
      const device = { ...list[i] };
      if (device.imei === data.imei) {
        device.latitude = data.latitude;
        device.longitude = data.longitude;
        device.course = data.course;
        device.speed = data.speed;
        device.status = data.status;
        device.timeAgo = data.timeAgo;
        device.user = data.user;
      }
      newList.push(device);
    }
  }
  dispatch(setDevices(newList));

  // if (data.user) {
  //   console.log("userrrrrrrrrrrrrrrr", data.user);
  //   const res = { ...data.user };
  //   for (let i = 0; i < resList.length; i++) {
  //     const s = { ...resList[i] };
  //     if (s.id === res.id) {
  //       newResList.push(res);
  //     } else {
  //       newResList.push(s);
  //     }
  //   }
  //   console.log("ressssssssss list", newResList);
  //   dispatch(setResponders(newResList));
  // }
};

const polygonStringToCoords = (data) => {
  var part1 = data.split("POLYGON((");
  var part2 = part1[1].split("))");
  var part3 = part2[0];
  var part4 = part3.split(", ");
  var part5 = [];
  for (let index = 0; index < part4.length; index++) {
    const element = part4[index];
    var part6 = element.split(" ");
    part5.push([Number(part6[0]), Number(part6[1])]);
  }
  return part5;
};

export const GetGeofences = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "geofence";
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await api
    .get(url, headers)
    .then((response) => {
      let data = response.data;

      let info = [];
      for (let i = 0; i < data.length; i++) {
        let element = data[i];
        let all = JSON.parse(element.area).coordinates;
        all.mapId = element.mapId;
        info.push(all);
      }

      // console.log("infoooooooooooooooooooooooooooooooo", info);
      dispatch(setGeofences(info));
    })
    .catch((e) => {
      console.error("Get Geofences Expections", e);
    });
};

export const UserLogin = async (dispatch, props) => {
  try {
    var url = URL + "auth/login";
    var data = {
      username: props.username,
      password: props.password,
      deviceId: props.deviceId,
    };
    const res = await axios.post(url, data);
    if (!res.data.token) {
      throw new Error("Token not recieved");
    }
    // console.log("asdsadasdsadasdas", res.data);
    localStorage.removeItem("token");
    localStorage.setItem("token", res.data.token);
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    localStorage.setItem("refreshToken", res.data.refreshToken);
    localStorage.setItem("userData", JSON.stringify(res.data.user));
    localStorage.setItem("user", JSON.stringify(res.data.user));
    dispatch(setUser(res.data.user));
  } catch (e) {
    errorToast(JSON.stringify(e.response.data.err));
    console.error(e);
  }
};

export const UserLogout = async (dispatch, user) => {
  const token = localStorage.getItem("token");
  const url = URL + "auth/logout";
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await api
    .post(url, { userId: user.id }, headers)
    .then((res) => {
      dispatch(setUser(null));
      localStorage.clear();
      return res;
    })
    .catch((e) => {
      // errorToast(e.response.data.err || "");
      toast.error(
        <>
          <div style={{ fontSize: "18px" }}>Something went wrong</div>
          <button
            style={{ flex: 1, width: "100%", marginTop: "8px" }}
            title="Force Logout"
            className="btn btn-sm btn-secondary"
            onClick={() => {
              // setIsLoading(true);
              dispatch(setUser(null));
              localStorage.clear();
              window.location.reload();
            }}
          >
            Force Logout
          </button>
        </>,
        {
          // hook will be called whent the component unmount
          // onClose: () => alert("Closed"),
        }
      );
      throw new Error(e);
    });
};

const fetchPolygon = (data) => {
  var info = [];
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    var detail = {
      id: element.id,
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [polygonStringToCoords(element.area)],
        type: "Polygon",
      },
    };
    info.push(detail);
  }
  return info;
};

const makePolygonString = (coords) => {
  var line = "";
  for (let index = 0; index < coords.length; index++) {
    const element = coords[index];
    var piece = element[1] + " " + element[0];
    if (index !== 0) {
      line += ", ";
    }
    line += piece;
  }
  var mainLine = "POLYGON((" + line + "))";
  return mainLine;
};

export const AddGeofences = async (dispatch, name, coords = []) => {
  const token = localStorage.getItem("token");
  var url = URL + "geofence";
  let data = {
    name: name,
    coords: coords,
  };
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await api
    .post(url, data, headers)
    .then((result) => {
      succesToast("Geofence added successfully");
    })
    .catch((e) => {
      errorToast("Geofence Adding Error");
      // console.log("Error adding geofence", e);
      throw new Error("Error");
    });
};

export const AddGeofenceRestriction = (dispatch, id, name, coords = []) => {
  const token = localStorage.getItem("token");

  var url = URL + "geofence/restrict";
  let data = {
    serviceId: id,
    name: name,
    coords: coords,
  };

  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  api
    .post(url, data, headers)
    .then((result) => {
      succesToast("Geofence Restriction added successfully");
    })
    .catch((e) => {
      errorToast("Geofence  restriction Adding Error");
      // console.log("Error adding restriction, e");
    });
};

// TODO
export const UpdateGeofences = async (dispatch, mapId, coords = []) => {
  const token = localStorage.getItem("token");
  var url = URL + "geofence/update";
  let data = {
    mapId,
    coords,
  };
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await api
    .post(url, data, headers)
    .then((result) => {
      succesToast("Geofence Updated successfully");
    })
    .catch((e) => {
      errorToast("Geofence Updation Error");
      // console.log("Error Updation geofence", e);
      throw new Error("Error");
    });
};

// TODO
export const DelGeofences = async (dispatch, mapId = 0) => {
  const token = localStorage.getItem("token");
  var url = URL + "geofence/delete";
  let data = {
    mapId,
  };
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await api
    .post(url, data, headers)
    .then((result) => {
      succesToast("Geofence Deleted successfully");
    })
    .catch((e) => {
      errorToast("Geofence deleted Error");
      // console.log("Error deleted geofence", e);
      throw new Error("Error");
    });
};

export const FetchAllGeofences = (dispatch) => {
  var url = URL + "geofence";
  api
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((result) => {
      let data = fetchPolygon(result.data);
      dispatch(setGeofences(data));
    })
    .catch((e) => {
      // console.log("Error getting geofence", e);
    });
};

// TODO
// export const FetchAllRestrictGeofences = (dispatch) => {
//   var url = URL + "geofence/fetchRestrict";
//   api
//     .get(url, {
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//       },
//     })
//     .then((result) => {
//       if (result.data) {
//         // console.error("geodata", result.data);
//         // let data = result.data.map((item) => {
//         //   item.coords = fetchPolygon(item);
//         //   return item;
//         // });
//         let data = fetchPolygon(result.data);
//         dispatch({
//           type: Actions.GET_TRACCAR_RESTRICTED_GEOFENCES,
//           payload: data,
//         });
//       } else {
//         console.log("REsult not Found");
//       }
//     })
//     .catch((e) => {
//       console.log("Error");
//     });
// };

// TODO
// export const FetchRestrictGeofenceCAD = (dispatch) => {
//   var url = URL + "geofence/fetchRestrictCAD";

//   api
//     .get(url, {
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//       },
//     })
//     .then((result) => {
//       if (result.data) {
//         // console.error("restriction", result.data);
//         dispatch({
//           type: Actions.GET_TRACCAR_RESTRICTED_GEOFENCES_CAD,
//           payload: result.data,
//         });
//       } else {
//         console.log("REsult not Found");
//       }
//     })
//     .catch((e) => {
//       console.log("Error");
//     });
// };

// TODO
// export const CreateServices = (dispatch, props) => {
//   const token = localStorage.getItem("token");

//   const url = URL + "service";
//   console.log(url, props);
//   api
//     .post(url, props)
//     .then((response) => {
//       //   console.log("Logs: ");
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.CREATE_SERVICES,
//         });
//       } else {
//         console.log("CREATE Services Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Create Services Expections");
//     });
// };

// TODO
// export const UpdateServices = (dispatch, id, props) => {
//   const token = localStorage.getItem("token");

//   const url = URL + "service/" + id;
//   api
//     .put(url, props)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.EDIT_SERVICES,
//         });
//       } else {
//         console.log("Update Services Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Update Services Expections");
//     });
// };
// export const DelServices = (dispatch, id) => {
//   const token = localStorage.getItem("token");

//   const url = URL + "service/" + id;
//   api
//     .delete(url)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.DEL_SERVICES,
//         });
//       } else {
//         console.log("DEL Services Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("DEL Services Expections");
//     });
// };

// TODO??
// export const CreateNatures = (dispatch, props) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "nature";
//   api
//     .post(url, props)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         ToastsStore.success("Nature added successfully");
//         dispatch({
//           type: Actions.CREATE_NATURES,
//         });
//       } else {
//         console.log("CREATE Natures Invalid Response");
//         ToastsStore.error("CREATE Natures Invalid Response");
//       }
//     })
//     .catch((e) => {
//       ToastsStore.error("Create Nature Exception.");
//       dispatch({
//         type: Actions.SET_MSG,
//         payload: true,
//       });
//       console.error("Create Natures Expections");
//     });
// };
// export const UpdateNatures = (dispatch, id, props) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "nature/" + id;
//   api
//     .put(url, props)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         ToastsStore.success("Nature updated successfully");
//         dispatch({
//           type: Actions.EDIT_NATURES,
//         });
//       } else {
//         ToastsStore.error("Update Nature Invalid Response");

//         console.log("Update Natures Invalid Response");
//       }
//     })
//     .catch((e) => {
//       ToastsStore.error("Update Nature Exception");

//       console.error("Update Natures Expections");
//     });
// };
// export const DelNatures = (dispatch, id) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "nature/" + id;
//   api
//     .delete(url)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         ToastsStore.success("Nature deleted successfully");
//         dispatch({
//           type: Actions.DEL_NATURES,
//         });
//       } else {
//         ToastsStore.error("DEL Nature Invalid Response");
//         console.log("DEL Natures Invalid Response");
//       }
//     })
//     .catch((e) => {
//       ToastsStore.error("DEL Nature Exception");
//       console.error("DEL Natures Expections");
//     });
// };

// TODO
// export const UpdatePanics = (dispatch, id, props) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "panic/" + id;
//   props.id = id;
//   api
//     .put(url, props)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.EDIT_PANICS,
//         });
//       } else {
//         console.log("Update Panic Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Update Panic Expections");
//     });
// };

// TODO
export const SupervisorAcceptPanic_UpdatePanics = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "panic/supervisorAcceptPanic";
  await api
    .put(url, props, headers)
    .then((response) => {
      dispatch(setIsResolveLoading(false));
    })
    .catch((e) => {
      dispatch(setIsResolveLoading(false));
      throw new Error(e);
    });
};

export const OperatorAcceptPanic_UpdatePanics = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "panic/operatorAcceptPanic";
  await api
    .put(url, props, headers)
    .then((response) => {
      dispatch(setIsResolveLoading(false));
    })
    .catch((e) => {
      dispatch(setIsResolveLoading(false));
      errorToast(e?.response?.data?.err || e.err);
      console.error("Accept error", e);
      throw new Error("Error");
    });
};

export const SupervisorAssignPanicToRes_UpdatePanics = async (
  dispatch,
  props
) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "panic/supervisorAssignPanicToRes";

  await api
    .put(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const BlockCustomer = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "user/blockCustomer";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      // console.log("block", e);
      throw new Error(e);
    });
};

export const GenerateAndSendPassword = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "user/generateAndSendPassword";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const UnBlockCustomer = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "user/unBlockCustomer";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const SupervisorAssignPanicToOpr_UpdatePanics = async (
  dispatch,
  props
) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "panic/supervisorAssignPanicToOpr";
  await api
    .put(url, props, headers)
    .then((response) => {
      // console.log("sasadasdasdsadasdsad", response.data);
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const OperatorAssignPanicToRes_UpdatePanics = async (
  dispatch,
  id,
  props
) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "panic/operatorAssignPanicToRes";
  props.id = id;
  await api
    .put(url, props, headers)
    .then((res) => {
      succesToast("Alert assigned to responder.");
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const OperatorRelocatePanic = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "panic/relocatePanic";
  await api
    .post(url, { id }, headers)
    .then((res) => {
      return res.data;
      // succesToast("Alert assigned to responder.");
    })
    .catch((e) => {
      throw new Error(e);
    });
};
export const SupervisorResolvePanic_UpdatePanics = async (
  dispatch,
  props = {}
) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "panic/supervisorResolvePanic";
  // alert(url);
  await api
    .put(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const OperatorResolvePanic_UpdatePanics = async (
  dispatch,
  id,
  props = {}
) => {
  const token = localStorage.getItem("token");
  const url = URL + "panic/operatorResolvePanic";
  props.id = id;
  // alert(url);
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {})
    .catch((e) => {
      console.error("Update Panic Expections");
      throw new Error(e);
    });
};
export const ClosePanic_UpdatePanics = async (dispatch, props = {}) => {
  const token = localStorage.getItem("token");
  const url = URL + "panic/closePanic";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Alert closed");
    })
    .catch((e) => {
      console.error("Close Panic Expections");
      errorToast(e?.response?.data?.err);
      throw new Error("Error");
    });
};
// export const SolvePanic_UpdatePanics = (dispatch, id, props = {}) => {
//   const token = localStorage.getItem("token");
//   const headers = {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   };
//   const url = URL + "panic/solvePanic";
//   props.id = id;
//   // alert(url);
//   api
//     .put(url, props, headers)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.EDIT_PANICS,
//         });
//       } else {
//         console.log("Update Panic Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Update Panic Expections");
//     });
// };
// export const DelPanics = (dispatch, id) => {
//   const token = localStorage.getItem("token");
//   const headers = {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   };
//   const url = URL + "panic/" + id;
//   api
//     .delete(url)
//     .then((response) => {
//       //   console.log(response.data);
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.DEL_PANICS,
//         });
//       } else {
//         console.log("DEL Panic Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("DEL Panic Expections");
//     });
// };

export const changeZoom = (dispatch, data) => {
  dispatch(setZoom(data));
};

export const GetCenter = (dispatch, data) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  var url = URL + "geo/find-center";
  api
    .post(url, { data }, headers)
    .then(async (response) => {
      var info = response.data.result.data;
      let lat = Math.random() / 100000;
      let lng = Math.random() / 100000;
      lat = info.latitude + lat;
      lng = info.longitude + lng;
      dispatch(
        setLatLng({
          latitude: lat,
          longitude: lng,
        })
      );
    })
    .catch((e) => {
      // console.log(e);
    });
};

export const ChangeGeoLatLng = (dispatch, data) => {
  dispatch(setGeoLatLng(data));
};

export const AddDeviceAPI = async (dispatch, props) => {
  const url = URL + "device/add";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Device added successfully.");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      console.error("Add Device Expections", e);
      throw new Error("Error");
    });
};

export const UpdateDeviceAPI = async (dispatch, props) => {
  const url = URL + "device/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Device updated successfully.");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      console.error("Update Device Expections", e);
      throw new Error("Error");
    });
};

export const DeleteDeviceAPI = async (dispatch, props) => {
  const url = URL + "device/delete";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Device deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("Deleting Device Expections", e);
      throw new Error("Error");
    });
};

export const AddGroupAPI = async (dispatch, props) => {
  const url = URL + "group/add";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Group added successfully.");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      console.error("Add Group Expections", e);
      throw new Error("Error");
    });
};

export const UpdateGroupAPI = async (dispatch, props) => {
  const url = URL + "group/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Group updated successfully.");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      console.error("Update Group Expections", e);
      throw new Error("Error");
    });
};

export const DeleteGroupAPI = async (dispatch, props) => {
  const url = URL + "group/delete";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Group deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("Deleting Group Expections", e);
      throw new Error("Error");
    });
};

export const GetAllGroups = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "group";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;

      // console.log("datatatatatata", data);

      dispatch(setGroups(data));
      dispatch(setSearchedGroups(data));
    })
    .catch((e) => {
      errorToast("Error Getting Groups");
      console.error("Get Groups Expections", e);
    });
};

export const SearchGroups = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "group/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setSearchedGroups(data));
    })
    .catch((e) => {
      console.error("Get Group Expections", e);
    });
};

// TODO
// export const GetGeoDistances = (dispatch, single, list) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "geo/find-distance";
//   const data = {
//     data: {
//       latitude: single.latitude,
//       longitude: single.longitude,
//       list: [...list],
//     },
//   };
//   api
//     .post(url, data)
//     .then((response) => {
//       //   console.log(response.data);
//       let data = response.data.result.data;
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.GET_GEO_DISTANCES,
//           payload: data,
//         });
//       } else {
//         console.log("Geo Distances Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Geo Distances Expections");
//     });
// };
// TODO
// export const GetChatLogs = (dispatch) => {
//   const token = localStorage.getItem("token");
//   const headers = {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   };
//   const url = URL + "chat-logs";
//   api
//     .get(url, headers)
//     .then((response) => {
//       let data = response.data.result.data;
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.GET_CHAT_LOGS,
//           payload: data,
//         });
//       } else {
//         console.log("GET Chat Logs Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Get Chat Logs Expections");
//     });
// };
// export const GetEventLogs = (dispatch) => {
//   const token = localStorage.getItem("token");
//   const url = URL + "event-logs";
//   api
//     .get(url)
//     .then((response) => {
//       let data = response.data.result.data;
//       let code = response.data.result.status;
//       if (code === 200) {
//         dispatch({
//           type: Actions.GET_EVENT_LOGS,
//           payload: data,
//         });
//       } else {
//         console.log("GET Event Logs Invalid Response");
//       }
//     })
//     .catch((e) => {
//       console.error("Get Event Logs Expections");
//     });
// };

export const AddWhitelistCustomer = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "whitelist/add";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // console.error("White list member created.", response.data);
      succesToast("White List Resident added.");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("White List Customer adding exception.");
        console.error("Create White list Expections", e);
      }
      throw new Error("Error");
    });
};

export const DelWhitelistCustomer = (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "whitelist/delete";

  api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("White list member deleted.");
    })
    .catch((e) => {
      console.error("Create White list Expections", e);
    });
};

export const DelUser = (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/delete";
  api
    .post(
      url,
      {
        userId: id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((response) => {
      succesToast("User deleted successfully.");
    })
    .catch((e) => {
      errorToast("Something went wrong");
      console.error("Delete User Expections", e);
    });
};

export const AddContact = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/addServiceContact";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Service contact created successfully.");
    })
    .catch((e) => {
      // console.warn("ddddddddddddddddddddddddddddd serveice", e);
      if (e.response.data.err) {
        errorToast(JSON.stringify(e.response.data.err));
      }
      console.error("Service Contact adding exception.", e);
      throw new Error("Error");
    });
};

export const AddAnnouncement = (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement";
  api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Announcement Added.");
    })
    .catch((e) => {
      errorToast("Adding Announcement exception.");

      console.error("Adding Announcement exception.", e);
    });
};

export const GetContact = (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/getServiceContacts";
  api
    .post(
      url,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((response) => {
      if (response.data) {
        dispatch(setServiceContacts(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Contact Expections");
    });
};

export const BroadCast = async (props) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const BroadCastForResponder = async (props) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement/responder";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const GetAnnouncements = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      if (response.data) {
        dispatch(setAnnouncements(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Announcement Expection");
    });
};

// TODO??
// export const SetContacter = (dispatch, props) => {
//   dispatch({
//     type: Actions.SET_CONTACTER,
//     payload: props,
//   });
// };

export const SetChatbox = (dispatch, props) => {
  dispatch(setChatbox(props));
};

export const SetOpenedChatbox = (dispatch, props) => {
  dispatch(setOpenedChatbox(props));
};

export const SetOpenedChatboxType = (dispatch, props) => {
  dispatch(setOpenedChatboxType(props));
};

export const SetNewBadges = (dispatch, props) => {
  dispatch(setNewBadges(props));
};

// TODO??
// export const SetMsg = (dispatch, props) => {
//   dispatch({
//     type: Actions.SET_MSG,
//     payload: props,
//   });
// };

export const SetPanicId = (dispatch, props) => {
  dispatch(setPanicId(props));
};

// TODO
// export const SetIsLoading = (dispatch, props) => {
//   dispatch({
//     type: Actions.SET_IS_LOADING,
//     payload: props,
//   });
// };

export const SetChatType = (dispatch, props) => {
  dispatch(setChatType(props));
};

export const SetRefresh = (dispatch, props) => {
  dispatch(setRefresh(props));
};

export const SetInternetState = (dispatch, props) => {
  dispatch(setInternetStatus(props));
};

export const FetchFreeChat = async (dispatch) => {
  // setIsLoading(true);
  const t = localStorage.getItem("token");
  const url = URL + "chat-logs/freeChat";
  api
    .get(url, {
      headers: {
        Authorization: "Bearer " + t,
      },
    })
    .then((response) => {
      if (response.data) {
        // console.log("freeeeeee", response.data);
        dispatch(setFreeChatMsgs(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Free Chat Expection", e);
    });
};

export const FetchAlertChat = async (dispatch) => {
  // setIsLoading(true);
  const t = localStorage.getItem("token");
  const url = URL + "chat-logs/alertChat";
  api
    .get(url, {
      headers: {
        Authorization: "Bearer " + t,
      },
    })
    .then((response) => {
      if (response.data) {
        dispatch(setAlertChatMsgs(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Free Chat Expection", e);
    });
};

export const FetchAlertChatSingle = async (dispatch, props) => {
  // setIsLoading(true);
  const t = localStorage.getItem("token");
  const url = URL + "chat-logs/alertChatSingle";
  api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + t,
      },
    })
    .then((response) => {
      if (response.data) {
        // alert(JSON.stringify(response.data));
        if (response.data.length === 0) {
          errorToast("No Chat Record Found");
        }
        dispatch(setAlertChatSingle(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Free Chat Single Expection", e);
      throw new Error(e);
    });
};

export const UpdateResponder = (dispatch, res, resList) => {
  const newList = [];
  for (let i = 0; i < resList.length; i++) {
    const s = { ...resList[i] };
    if (s.id === res.id) {
      newList.push(res);
    } else {
      newList.push(s);
    }
  }
  dispatch(setResponders(newList));
};

export const UpdateOperator = (dispatch, opr, oprList) => {
  const newList = [];
  for (let i = 0; i < oprList.length; i++) {
    const o = oprList[i];
    if (o.id === opr.id) {
      newList.push(opr);
    } else {
      newList.push(o);
    }
  }
  dispatch(setOperators(newList));
};

export const UpdateSupervisor = (dispatch, sup, supList) => {
  const newList = [];
  for (let i = 0; i < supList.length; i++) {
    const s = supList[i];
    if (s.id === sup.id) {
      newList.push(sup);
    } else {
      newList.push(s);
    }
  }
  dispatch(setSupervisors(newList));
};

export const SetFreeChatBadges = (dispatch, props) => {
  dispatch(setFreeChatBadges(props));
};

export const SetAlertChatBadges = (dispatch, props) => {
  dispatch(setAlertChatBadges(props));
};

// TODO
export const HandleNewAlert = (
  dispatch,
  alert,
  resAlertList = [],
  cusAlertList = []
) => {
  if (alert.alertType === 2) {
    let newResList = [];
    if (resAlertList) {
      if (resAlertList?.length > 0) {
        newResList = [...resAlertList];
      }
    }
    newResList.push(alert);

    dispatch(setType2Alerts(newResList));
  } else {
    let newCusList = [];
    if (cusAlertList) {
      if (cusAlertList?.length > 0) {
        newCusList = [...cusAlertList];
      }
    }
    newCusList.push(alert);
    dispatch(setCusAlerts(newCusList));
    // dispatch({
    //   type: Actions.HANDLE_NEW_CUS_ALERT,
    //   payload: newCusList,
    // });
  }
};

// TODO
export const HandleUpdateAlert = (
  dispatch,
  alert,
  resAlertList = [],
  cusAlertList = []
) => {
  // console.log("aaaaaaaaaaaaa", alert.statusId, alert);
  let remove = false;
  if (alert.alertType === 2) {
    if (
      alert.isCancelled ||
      alert.isClose ||
      alert.isResolved ||
      alert.isRedirected
    ) {
      remove = true;
    }

    let newResList = [];
    let index = -1;
    if (remove) {
      newResList = [...resAlertList];
      for (let i = 0; i < resAlertList.length; i++) {
        const element = { ...resAlertList[i] };
        if (element.id === alert.id) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        newResList.splice(index, 1);
      }
    } else {
      for (let i = 0; i < resAlertList.length; i++) {
        const element = { ...resAlertList[i] };
        if (element.id === alert.id) {
          // element.statusId = alert.statusId;
          newResList.push(alert);
        } else {
          newResList.push(element);
        }
      }
    }

    dispatch(setType2Alerts(newResList));
  } else {
    if (
      alert.isCancelled ||
      alert.isClose ||
      alert.isResolved ||
      alert.isRedirected
    ) {
      remove = true;
    }

    let newCusList = [];
    let index = -1;
    if (remove) {
      newCusList = [...cusAlertList];
      for (let i = 0; i < cusAlertList.length; i++) {
        const element = { ...cusAlertList[i] };
        if (element.id === alert.id) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        newCusList.splice(index, 1);
      }
    } else {
      for (let i = 0; i < cusAlertList.length; i++) {
        const element = { ...cusAlertList[i] };
        if (element.id === alert.id) {
          // element.statusId = alert.statusId;
          newCusList.push(alert);
        } else {
          newCusList.push(element);
        }
      }
    }

    dispatch(setCusAlerts(newCusList));
  }
};

export const SetFocusedMarker = (dispatch, props) => {
  dispatch(setFocusedMarker(props));
};

export const UpdateDBConfig = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "config/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // succesToast("Config Updated");
      return response;
    })
    .catch((e) => {
      errorToast(e.response?.data?.err);
      // console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const GetDBConfig = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "config";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch(setConfig(response.data));
    })
    .catch((e) => {
      // errorToast(e.response?.data?.err);
      // console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const GetServiceDetails = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "service/one";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch(setService(response.data));
    })
    .catch((e) => {
      // errorToast(e.response?.data?.err);
      // console.error("addUser Error", e);
      throw new Error("Error");
    });
};
