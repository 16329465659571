/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";

import MapGL, {
  Marker,
  FullscreenControl,
  NavigationControl,
} from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "./style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";
import {
  ApproveApplicationAPI,
  DelGeofences,
  GetApplicationAPI,
  GetGeofences,
  RejectApplicationAPI,
  UpdateGeofences,
} from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { setGeoIsLoading } from "../../../../../redux/slices/mapSlice";
import {
  setActionVal,
  setApplicationdetails,
  setConfirmText,
} from "../../../../../redux/slices/userSlice";
import { IsEmpty } from "../../../../../helpers/utils";

var arr = [];

const ViewMarkerWithGeofence = (props) => {
  const dispatch = useDispatch();
  const geoIsLoading = useSelector((state) => state.map.geoIsLoading);

  const geofences = useSelector((state) => state.map.geofences);
  const latitude = useSelector((state) => state.map.latitude);
  const longitude = useSelector((state) => state.map.longitude);
  // console.log("Supervisor - Geo View");

  const applicationDetails = useSelector(
    (state) => state.user.applicationDetails
  );
  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);
  const rejectNote = useSelector((state) => state.user.rejectNote);
  const approveGroupId = useSelector((state) => state.user.approveGroupId);

  const btnRef = useRef();
  const [geofenceArr, setGeofenceArr] = useState([]);
  const [updatedArr, setUpdatedArr] = useState([]);
  const [showGeofence, setShowGeofence] = useState(true);
  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [map, setMap] = useState(false);
  const [position, setPosition] = useState({
    longitude: 74.325816,
    latitude: 31.484722,
  });
  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  useEffect(() => {
    // console.log("appppppppp", applicationDetails);
    setPosition({
      latitude: applicationDetails?.lat || 31.484722,
      longitude: applicationDetails?.lng || 74.325816,
    });
  }, [applicationDetails]);

  useEffect(() => {
    if (
      actionVal?.action === "1" &&
      actionVal?.type === "rejectReqModal" &&
      !IsEmpty(rejectNote)
    ) {
      rejectReqAction(actionVal.id);
      dispatch(setActionVal(null));
    }
    if (actionVal?.action === "1" && actionVal?.type === "approveReqModal") {
      approveReqAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal, rejectNote]);

  const approveReq = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to approve this request?",
        type: "approveReqModal",
        id,
      })
    );
    delBtn.click();
  };

  const rejectReq = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to reject this request?",
        type: "rejectReqModal",
        id,
      })
    );
    delBtn.click();
  };

  const approveReqAction = async (id) => {
    await ApproveApplicationAPI(dispatch, {
      applicationId: id,
      groupId: approveGroupId,
    });
    dispatch(setApplicationdetails(""));
    await GetApplicationAPI(dispatch);
  };
  const rejectReqAction = async (id) => {
    await RejectApplicationAPI(dispatch, { applicationId: id, rejectNote });
    dispatch(setApplicationdetails(""));
    await GetApplicationAPI(dispatch);
  };

  useEffect(() => {
    if (Array.isArray(geofences)) {
      setTimeout(() => {
        let struct = {
          type: "FeatureCollection",
          features: [...geofences],
        };
        setData(struct);
      }, 300);
    }
    return;
  }, [geofences]);

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <MapGL
          initialState={{ data }}
          mapStyle={mapStyle}
          style={{ width: "100%", height: "560px" }}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={latitude || 31.4676}
          longitude={longitude || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          {showGeofence ? (
            <Draw
              pointControl={false}
              data={data}
              lineStringControl={false}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              polygonControl={false}
              trashControl={false}
            />
          ) : null}
          {applicationDetails ? (
            <Marker
              longitude={applicationDetails?.lng || 74.325816}
              latitude={applicationDetails?.lat || 31.484722}
              // onClick={onMarkerClick}
            >
              <div style={{ zIndex: 12345 }}>
                <img
                  src={require("../../../../../assets/pins/mappin.png")}
                  alt=""
                  style={{ width: "34px" }}
                />
              </div>
            </Marker>
          ) : null}
          {/* <div className="fullscreen">
            <FullscreenControl />
          </div> */}
        </MapGL>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        title={"House Location"}
        name={"viewMarkerWithGeofence"}
        icon={"fa fa-map-location-dot"}
      >
        <div className="row">
          <div className="col-6">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To View"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#regRequests"}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setApplicationdetails(""));
                }}
              ></i>
            </a>
          </div>
          <div className="col-6">
            <div className="float-end">
              <div className="form-check form-switch">
                <label
                  style={{
                    fontSize: "12px",
                    color: "black",
                  }}
                  className="form-check-label"
                  for="inlineCheckbox2"
                >
                  Toggle Geofence
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  checked={showGeofence}
                  value={showGeofence}
                  onChange={(e) => setShowGeofence(!showGeofence)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="" onMouseEnter={() => setMap(true)}>
          {map ? (
            <>
              {getMap()}
              <br />
              <center>
                <div className="text-center">
                  {geoIsLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <div
                      style={{
                        display: applicationDetails.hideBtn ? "none" : "",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-sm btn-success"
                        title="Approve"
                        onClick={(e) => {
                          e.preventDefault();
                          approveReq(applicationDetails.id);
                        }}
                        style={{ width: "120px" }}
                      >
                        <i
                          style={{ color: "white", fontSize: "14px" }}
                          className="fa-solid fa-check"
                        ></i>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        Approve
                      </button>
                      <button
                        title="Reject"
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          rejectReq(applicationDetails.id);
                        }}
                        style={{ width: "120px", marginLeft: "16px" }}
                      >
                        <i
                          style={{ color: "white", fontSize: "14px" }}
                          className="fa-solid fa-xmark"
                        ></i>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        Reject
                      </button>
                    </div>
                  )}
                </div>
              </center>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <p> Hover to see map. </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ViewMarkerWithGeofence;
