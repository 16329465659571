import { Howl, Howler } from "howler";

var sound1 = new Howl({
  src: ["/beeps/beeplong.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
  onend: function () {
    console.log("Just Beeped 1 ! ");
  },
});

var sound2 = new Howl({
  src: ["/beeps/button-1.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
  onend: function () {
    console.log("Just Beeped 2 ! ");
  },
});

var sound3 = new Howl({
  src: ["/beeps/button-2.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
  onend: function () {
    console.log("Just Beeped 3 ! ");
  },
});

var sound4 = new Howl({
  src: ["/beeps/button-10.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
  onend: function () {
    console.log("Just Beeped 4 ! ");
  },
});

var sms = new Howl({
  src: ["/beeps/sms.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
});

var cmp = new Howl({
  src: ["/beeps/cmp.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
  onplay: function () {
    console.log("played");
  },
  onload: function (id) {
    console.log("loaded complaint sound", id);
  },
  onloaderror: function (id, err) {
    console.log("load error", id, err);
  },
});

var sound5 = new Howl({
  src: ["/beeps/beep-11.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
  onend: function () {
    // console.error("Just Beeped 5 ! ");
  },
  onstop: function () {
    // console.error("Just Stopped Beeped 5 !");
  },
});

var noti1 = new Howl({
  src: ["/beeps/noti1.mp3"],
  autoplay: false,
  loop: false,
  volume: 1,
  onend: function () {
    // console.log("Just Beeped 5 ! ");
  },
  onstop: function () {
    // console.log("Just Stopped Beeped 5 !");
  },
});

export const alertBeep = async () => sound1.play();
export const Beep2 = async () => sound2.play();
export const Beep3 = async () => sound3.play();
export const Beep4 = async () => sound4.play();
export const Beep5 = async () => sound5.play();
export const Noti1 = async () => noti1.play();
export const CmpSound = async () => cmp.play();
export const CmpSoundStop = async () => cmp.stop();
export const Sms = async () => sms.play();
export const alertBeepStop = async () => {
  sound1.stop();
};
export const Beep5Stop = async () => {
  sound5.stop();
};
