import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangePasswordAPI,
  ChangeUserPassword,
  GetAllDevice,
  UpdateDeviceAPI,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import { v4 as uuidv4 } from "uuid";
import { setIsLoading } from "../../../../../redux/slices/userSlice";

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [userId, setUserId] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setUserId(user?.id);
    }
  }, [user]);

  const changePass = async () => {
    if (IsEmpty(oldPassword)) {
      errorToast("Old Password is required");
      return;
    }
    if (IsEmpty(password)) {
      errorToast("New Password is required");
      return;
    }
    if (IsEmpty(confirmPassword)) {
      errorToast("Confirm Password is required");
      return;
    }

    dispatch(setIsLoading(true));

    await ChangeUserPassword(dispatch, {
      oldPassword,
      newPassword: password,
    })
      .then(async () => {
        setUserId("");
        dispatch(setIsLoading(false));
        setPassword("");
        setConfirmPassword("");
        setOldPassword("");
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <>
      <Modal title={"Change Password"} name={"changePass"} icon={"fa fa-lock"}>
        <form>
          <>
            <div className="row">
              <div className="mb-3 col-4">
                <MyPasswordInput
                  name={"Old Password"}
                  val={oldPassword}
                  placeholder={""}
                  setVal={setOldPassword}
                />
              </div>
              <div className="mb-3 col-4">
                <MyPasswordInput
                  name={"New Password"}
                  val={password}
                  placeholder={""}
                  setVal={setPassword}
                />
              </div>

              <div className="mb-3 col-4">
                <MyPasswordInput
                  name={"Confirm Password"}
                  val={confirmPassword}
                  placeholder={""}
                  setVal={setConfirmPassword}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-50"
                    onClick={(e) => {
                      e.preventDefault();
                      changePass();
                    }}
                  >
                    Change Password
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default ChangePassword;
