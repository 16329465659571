/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteServiceContact,
  DeleteUser,
  GetUsers,
  SearchStaff,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { URL } from "../../../../../config";
import {
  setActionVal,
  setConfirmText,
  setEditUserData,
  setImageDetail,
  setIsLoading,
  setPrevModal,
} from "../../../../../redux/slices/userSlice";

const ListUser = (props) => {
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [userType, setUserType] = useState("");
  const [contact, setContact] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);

  const user = useSelector((state) => state.user.user);
  const searchedSupervisors = useSelector(
    (state) => state.user.searchedSupervisors
  );
  const searchedOperators = useSelector(
    (state) => state.user.searchedOperators
  );
  const searchedDirectorSecurity = useSelector(
    (state) => state.user.searchedDirectorSecurity
  );
  const searchedResponders = useSelector(
    (state) => state.user.searchedResponders
  );
  const searchedServiceContacts = useSelector(
    (state) => state.user.searchedServiceContacts
  );
  const searchedSocietyAdmins = useSelector(
    (state) => state.user.searchedSocietyAdmins
  );

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  useEffect(() => {
    GetUsers(dispatch);
  }, []);

  const searchUsers = async () => {
    dispatch(setIsLoading(true));
    await SearchStaff(dispatch, {
      name,
      contact,
      userType,
      username,
      email,
    });
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "user") {
      deleteStaffAction(actionVal.id);
      dispatch(setActionVal(null));
    }
    if (actionVal?.action === "1" && actionVal?.type === "service") {
      deleteServiceContactAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const deleteStaff = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this User?",
        type: "user",
        id,
      })
    );
    delBtn.click();
  };

  const deleteStaffAction = async (id) => {
    await DeleteUser(dispatch, { userId: id });
    await searchUsers();
  };

  const deleteServiceContact = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Service Contact?",
        type: "service",
        id,
      })
    );
    delBtn.click();
  };

  const deleteServiceContactAction = async (id) => {
    await DeleteServiceContact(dispatch, { userId: id });
    await searchUsers();
  };

  const getList = () => {
    const allUser = [
      ...searchedResponders,
      ...searchedOperators,
      ...searchedSupervisors,
      ...searchedServiceContacts,
      ...searchedSocietyAdmins,
      ...searchedDirectorSecurity,
    ];
    let count = 0;
    return allUser?.map((val, index) => {
      count++;
      return (
        <tr
          key={index}
          className={
            val.roleId === 12
              ? ""
              : val.userActivation
              ? "table-success"
              : val.userAssignment
              ? "table-secondary"
              : "table-danger"
          }
        >
          <th scope="row">{count}</th>
          <td style={{ width: "50px" }}>
            {val.avatarFileId ? (
              <a
                className="nav-link"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#showImage"}
                title="Avatar ..."
                onClick={() => {
                  dispatch(setImageDetail(`${URL}upload/${val.avatarFileId}`));
                  dispatch(setPrevModal("listUser"));
                  // setImageLink(`${URL}upload/${msgObj.fileData?.id}`);
                  // alert("to do");
                }}
              >
                <img
                  className="nav-link"
                  alt=""
                  src={`${URL}upload/${val.avatarFileId}`}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    border: "2px solid white",
                    // padding: "2px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              </a>
            ) : null}
          </td>
          <td>{`${val.firstname} ${val.lastname}`}</td>
          {/* <td>{`${val.email}`}</td> */}
          <td>{`${val.contact}`}</td>
          <td>{`${val.username || ""}`}</td>
          <td>
            {val.roleId === 2
              ? "Supervisor"
              : val.roleId === 3
              ? "Operator"
              : val.roleId === 4
              ? "Responder"
              : val.roleId === 12
              ? "Service Contact"
              : val.roleId === 15
              ? "Director Security"
              : val.roleId === 13
              ? "Security Manager"
              : "Admin"}
          </td>
          <td>{val.group?.name || ""}</td>

          <td>
            <a
              className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
              href="#"
            >
              <i
                style={{
                  fontSize: "20px",
                  color: "blue",
                  opacity: user.roleId === 1 ? 1 : 0.4,
                }}
                className="fa fa-light fa-pencil"
                onClick={() => {
                  dispatch(setEditUserData(val));
                }}
                title="Edit"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target="#addUser"
              ></i>
              <i
                style={{
                  fontSize: "20px",
                  color: "red",
                  marginLeft: "10px",
                  opacity: user.roleId === 1 ? 1 : 0.4,
                }}
                className="fa fa-light fa-trash"
                onClick={() => {
                  if (val.roleId === 12) {
                    deleteServiceContact(val.id);
                  } else {
                    deleteStaff(val.id);
                  }
                }}
                title="Delete"
              ></i>
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Staff List"}
        name={"listUser"}
        icon={"fa fa-users-viewfinder"}
      >
        <div className="row">
          <div className="col-9">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a>
          </div>
          <div className="col-1">
            <div className="float-end">
              <div
                className=""
                style={{
                  width: "80px",
                  backgroundColor: "#d1e7dd",
                  padding: "2px 13px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Online
              </div>
            </div>
          </div>
          <div className="col-1">
            <div className="float-end">
              <div
                style={{
                  width: "80px",
                  backgroundColor: "#F7D7DA",
                  padding: "2px 13px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Offline
              </div>
            </div>
          </div>
          <div className="col-1">
            <div className="float-end">
              <div
                style={{
                  width: "80px",
                  backgroundColor: "#FCF2CD",
                  padding: "2px 13px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Busy
              </div>
            </div>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={username}
                      placeholder={"Avatar"}
                      onChange={(e) => setUsername(e.target.value)}
                      disabled={true}
                    />
                    <label className="col-form-label">Avatar</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      placeholder={"Name"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Name</label>
                  </th>
                  {/* <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={email}
                      placeholder={"Email"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="col-form-label">Email</label>
                  </th> */}
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={contact}
                      placeholder={"Cell Phone #"}
                      onChange={(e) => setContact(e.target.value)}
                    />
                    <label className="col-form-label">Cell Phone #</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={username}
                      placeholder={"Username"}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <label className="col-form-label">Username</label>
                  </th>
                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={userType}
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value="15">Director Security</option>
                      <option value="13">Security Manager</option>
                      <option value="2">Supervisor</option>
                      <option value="3">Operator</option>
                      <option value="4">Responder</option>
                      <option value="12">Service Contact</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">User Type</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      // value={username}
                      placeholder={"Group"}
                      // onChange={(e) => (e.target.value)}
                      disabled={true}
                    />
                    <label className="col-form-label">Group</label>
                  </th>

                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          searchUsers();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            width: "40px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody>{getList()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListUser;
