import React from "react";

const Msg = (props) => {
  const formatTime = (t) => {
    let date = new Date(t);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  return (
    <>
      <div
        style={{
          background: "#FFFFFF",
          color: "black",
          display: "block",
          // flexDirection: "row",
          marginTop: "5px",
          padding: "6px",
          borderRadius: "8px",
          marginLeft: "5px",
          marginRight: "50px",
          float: props.direction || "left",
          fontSize: "14px",
          boxShadow: "1px 2px 9px grey",
        }}
      >
        <div
          style={{
            color: props.text.senderRole === "Responder" ? "brown" : "orange",
            fontSize: "12px",
          }}
        >
          {props.text.senderRole}
        </div>
        <div>
          <div>{props.text.msg || ""}</div>
          <div
            style={{
              // marginLeft: "8px",
              marginTop: "6px",
              fontSize: "11px",
              color: "grey",
              // backgroundColor: "red",
              // alignSelf: "flex-end",
            }}
          >
            {formatTime(props.text.createdAt)}
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  );
};

export default Msg;
