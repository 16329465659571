/**
 * Standard Format of the Response for All API Calls
 */
const response = (code, msg, data = null) => {
  return {
    result: {
      status: code,
      message: msg,
      data: data,
    },
  };
};

/**
 * Method to Check Data Emptiness in Boolean
 */
const CheckIfEmpty = (data) => {
  if (
    data != null &&
    data !== 0 &&
    data !== "" &&
    data !== {} &&
    typeof data !== "undefined"
  ) {
    return true;
  } else {
    return false;
  }
};

export const IsEmpty = (data) => {
  if (
    data != null &&
    data !== 0 &&
    data !== "" &&
    data !== {} &&
    typeof data !== "undefined"
  ) {
    return false;
  }

  return true;
};

export const GetEnv = (envvar) => {
  if (IsEmpty(process.env[envvar])) {
    throw new Error(`Env ${envvar} not defined`);
  }

  return process.env[envvar];
};
