/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useSelector, useDispatch } from "react-redux";
import SideBarBtn from "../../../components/SideBarBtn";
import { setActiveAlertView } from "../../../redux/slices/alertSlice";
import { setMapStyle } from "../../../redux/slices/mapSlice";
import { setTheme } from "../../../redux/slices/themeSlice";

const SideBar = (props) => {
  const dispatch = useDispatch();
  const activeAlertView = useSelector((state) => state.alert.activeAlertView);

  const theme = useSelector((state) => state.theme.theme);
  const applicationBadge = useSelector((state) => state.user.applicationBadge);
  const requestApplications = useSelector(
    (state) => state.user.requestApplications
  );
  const user = useSelector((state) => state.user.user);

  return (
    <>
      <a
        className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#addUser"
      >
        <SideBarBtn
          icon={"fa-user-plus"}
          title="Add User"
          opacity={user.roleId === 1 ? 1 : 0.4}
        />
      </a>
      <a
        className="nav-link"
        href="#"
        data-bs-toggle="offcanvas"
        data-bs-target="#staffOnDutyOffCanvas"
        aria-controls="offcanvasScrolling"
      >
        <SideBarBtn icon={"fa-user-check"} title="Active Staff" />
      </a>
      <a
        className="nav-link"
        href="#"
        // onClick={() => {
        //   dispatch(setActiveAlertView(!activeAlertView));
        // }}
        data-bs-toggle="offcanvas"
        data-bs-target="#activeAlerts"
        aria-controls="offcanvasScrolling"
      >
        <SideBarBtn
          icon={"fa-solid fa-triangle-exclamation"}
          title="Active Alerts"
        />
      </a>
      <a
        className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#createAnnouncements"
      >
        <SideBarBtn
          opacity={user.roleId === 1 ? 1 : 0.4}
          icon={"fa-bullhorn"}
          title="Create Announcement for resident"
          plusIcon={true}
        />
      </a>
      <a href="#" data-bs-toggle="modal" data-bs-target="#viewGeofence">
        <SideBarBtn icon={"fa-globe"} title="View Geofence" />
      </a>
      <a
        className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
        href="#"
        aria-disabled="true"
        data-bs-toggle="modal"
        data-bs-target="#manageMap"
      >
        <SideBarBtn
          icon={"fa-map-location-dot"}
          title="Map Layer"
          opacity={user.roleId === 1 ? 1 : 0.4}
        />
      </a>
      <a href="#" data-bs-toggle="modal" data-bs-target="#listDevices">
        <SideBarBtn
          icon={"fa-mobile-screen"}
          title="Device List"
          additions={true}
        />
      </a>
      <a
        className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#regRequests"
      >
        <SideBarBtn
          opacity={user.roleId === 1 ? 1 : 0.4}
          icon={"fa-envelope"}
          title="Registeration Requests"
          applicationBadge={requestApplications.length}
        />
      </a>
      <a
        href="#"
        aria-disabled="true"
        data-bs-toggle="modal"
        data-bs-target="#alertHistory"
        // data-bs-toggle="modal"
        // data-bs-target="#activeStaff"
      >
        <SideBarBtn icon={"fa-clock-rotate-left"} title="Alert History" />
      </a>
      {theme !== "satellite" ? (
        <a
          // className="nav-link"
          href="#"
          aria-disabled="true"
          onClick={() => {
            dispatch(setTheme("satellite"));
            dispatch(
              setMapStyle("mapbox://styles/mapbox/satellite-streets-v12")
            );
          }}
        >
          <SideBarBtn icon={"fa-satellite"} title="Satellite View" />
        </a>
      ) : null}

      {theme ? (
        <a
          // className="nav-link"
          href="#"
          aria-disabled="true"
          onClick={() => {
            if (theme === "light") {
              dispatch(setTheme("dark"));
              dispatch(
                setMapStyle("mapbox://styles/mapbox/navigation-night-v1")
              );
            } else {
              dispatch(setTheme("light"));
              dispatch(setMapStyle("mapbox://styles/mapbox/streets-v9"));
            }
          }}
        >
          <SideBarBtn
            icon={`${theme === "light" ? "fa-moon" : "fa-sun"}`}
            title={`${theme === "light" ? "Dark" : "Light"} Mode`}
          />
        </a>
      ) : null}

      {user.roleId === 1 ? (
        <a
          href="#"
          aria-disabled="true"
          data-bs-toggle="modal"
          data-bs-target="#configDB"
        >
          <SideBarBtn icon={"fa-gears"} title="Config" />
        </a>
      ) : null}
    </>
  );
};

export default SideBar;
