import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeleteConfirm from "../../../components/DeleteConfirm";
import Mqtt from "../../../Mqtt/Mqtt";
import NetInfo from "../../../NetInfo/NetInfox";
import TabChange from "../../../TabChange/TabChange";
import MapGl from "./MapGl/MapGl";
import MapItems from "./MapItems/MapItems";
import MqttStatus from "./MapItems/MqttStatus";
import ActiveAlerts from "./Modals/ActivityLogs/ActiveAlerts";
import ActiveStaff from "./Modals/ActivityLogs/ActiveStaff";
import AlertDetails from "./Modals/ActivityLogs/AlertDetails";
import AlertHistory from "./Modals/ActivityLogs/AlertHistory";
import EventPlayback from "./Modals/ActivityLogs/EventPlayback";
import LocateDevice from "./Modals/ActivityLogs/LocateDevice";
import Playback from "./Modals/ActivityLogs/Playback";
import SatffAttandance from "./Modals/ActivityLogs/StaffAttandance";
import CreateAnn from "./Modals/Announcement/CreateAnn";
import CreateAnnForRes from "./Modals/Announcement/CreateAnnForRes";
import ListAnn from "./Modals/Announcement/ListAnn";
import RegisterationRequest from "./Modals/Applications/RegisterationRequest";
import RejectedRequest from "./Modals/Applications/RejectedRequest";
import ConfigDB from "./Modals/Config/Config";
import AddDevice from "./Modals/Devices/AddDevice";
import ListDevices from "./Modals/Devices/ListDevices";
import AddGeofence from "./Modals/Geofence/AddGeofence";
import ManageMap from "./Modals/Geofence/ManageMap";
import ViewGeofence from "./Modals/Geofence/ViewGeofence";
import ViewLocation from "./Modals/Geofence/ViewLocation";
import ViewMarkerWithGeofence from "./Modals/Geofence/ViewMarkerWithGeofence";
import AddGroup from "./Modals/Group/AddGroup";
import ListGroup from "./Modals/Group/ListGroup";
import ShowImage from "./Modals/ShowImage/ShowImage";
import AddBulkResident from "./Modals/Users/AddBulkResident";
import AddUser from "./Modals/Users/AddUser";
import ChangePassword from "./Modals/Users/ChangePassword";
import ListResidents from "./Modals/Users/ListResidents";
import ListUser from "./Modals/Users/ListUsers";
import UpdateProfile from "./Modals/Users/UpdateProfile";

const MapWall = (props) => {
  const user = useSelector((state) => state.user.user);
  return (
    <>
      <div style={{ width: "100%" }}>
        <MapItems />
        <MapGl />

        {user.roleId === 1 || user.roleId === 15 || user.roleId === 13 ? (
          <>
            <SatffAttandance />
            <CreateAnn />
            <ListResidents />
          </>
        ) : null}

        {/* Users */}
        {user.roleId === 1 ? (
          <>
            <AddUser />
            <AddBulkResident />
            <AddGeofence />
            <ViewMarkerWithGeofence />
            <AddDevice />
            <AddGroup />
            <RegisterationRequest />
            <RejectedRequest />
            <LocateDevice />
          </>
        ) : null}
        {user.roleId !== 7 ? <CreateAnnForRes /> : null}
        <ManageMap />

        <ViewLocation />
        <ListUser />
        <ConfigDB />
        <AlertHistory />
        <AlertDetails />

        <ChangePassword />
        <UpdateProfile />

        {/* Maps */}
        <ViewGeofence />

        {/* Announcements */}
        <ListAnn />
        <MqttStatus />

        <ListDevices />
        <ListGroup />
        <EventPlayback />
        <Playback />

        {/* Activity Log */}
        <ActiveStaff />
        <ActiveAlerts />

        {/* <Mqtt /> */}
        <DeleteConfirm />
        <NetInfo />
        <TabChange />
        <ShowImage />
      </div>
    </>
  );
};

export default MapWall;
