/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserLogout } from "../../actions";
import { errorToast } from "../../helpers/toasts";
import { disconnectMqtt } from "../../Mqtt/Mqtt";

const TopBar = (props) => {
  const dispatch = useDispatch();
  const applicationBadge = useSelector((state) => state.user.applicationBadge);
  const internetStatus = useSelector((state) => state.user.internetStatus);
  const requestApplications = useSelector(
    (state) => state.user.requestApplications
  );
  const user = useSelector((state) => state.user.user);
  const service = useSelector((state) => state.user.service);

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      {/* <div>sadasdas</div> */}

      <nav
        className="container-fluid navbar navbar-expand-lg navbar-dark bg-dark"
        style={{ borderBottom: "1px solid grey" }}
      >
        <a
          className="navbar-brand"
          href="#"
          style={{
            color: "#438AA2",
            marginLeft: "10px",
            width: "20%",
            overflow: "hidden",
            fontWeight: "bold",
          }}
        >
          {service?.name || "E.A.R.S"}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className="navbar-toggler-icon"
            style={{ color: "#438AA2" }}
          ></span>
        </button>

        <div
          className="collapse navbar-collapse mx-auto"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mx-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-history"
                ></i>
                <span> &nbsp;</span>
                Activity Logs
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#staffOnDutyOffCanvas"
                  aria-controls="offcanvasScrolling"
                  // data-bs-toggle="modal"
                  // data-bs-target="#activeStaff"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-user-check"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Staff On Duty
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#activeAlerts"
                  aria-controls="offcanvasScrolling"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa-solid fa-triangle-exclamation"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Active Alerts
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#staffAttandance"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-user-clock"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Staff Attendance
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#alertHistory"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-clock-rotate-left"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Alerts History
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#eventPlayback"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-timeline"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Event Playback
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#locateDevice"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-magnifying-glass-location"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Device History
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-envelope"
                ></i>
                {requestApplications.length ? (
                  <i
                    style={{
                      fontSize: "11px",
                      position: "relative",
                      top: -10,
                      left: -5,
                      color: "red",
                      zIndex: 1,
                    }}
                    className="fa fa-light fa-circle"
                    // onClick={() => alert("TODO")}
                  ></i>
                ) : null}
                <span>&nbsp;</span>
                Application
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#regRequests"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-envelope"
                  ></i>
                  {requestApplications.length ? (
                    <i
                      style={{
                        fontSize: "10px",
                        position: "relative",
                        top: -8,
                        left: -10,
                        color: "red",
                        zIndex: 1,
                      }}
                      className="fa fa-light fa-circle"
                      // onClick={() => alert("TODO")}
                    ></i>
                  ) : null}
                  {requestApplications.length ? null : (
                    <>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </>
                  )}
                  Registration Requests
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#rejectRequests"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-rectangle-xmark"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Rejected Requests
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-mobile"
                ></i>
                <span>&nbsp;</span>
                Devices
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addDevice"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-mobile-screen"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add
                </a>
                <a
                  className={`dropdown-item `}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#listDevices"
                >
                  <>
                    <i
                      style={{
                        fontSize: "16px",
                        bottom: -4,
                        position: "relative",
                      }}
                      className="fa fa-light fa-car"
                      title="Tracker"
                      // onClick={() => alert("TODO")}
                    ></i>
                    <i
                      style={{
                        fontSize: "10px",
                        position: "relative",
                        top: -8,
                        left: -14,
                        // color: "red",
                        zIndex: 1,
                      }}
                      className="fa fa-light fa-wifi"
                      title="Tracker"
                      // onClick={() => alert("TODO")}
                    ></i>
                  </>
                  <span>&nbsp;</span>
                  List
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa-solid fa-people-roof"
                ></i>
                <span>&nbsp;</span>
                Groups
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addGroup"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa-solid fa-people-roof"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add
                </a>
                <a
                  className={`dropdown-item `}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#listGroups"
                >
                  <>
                    <i
                      style={{
                        fontSize: "16px",
                      }}
                      className="fa-solid fa-people-roof"
                      title="Tracker"
                      // onClick={() => alert("TODO")}
                    ></i>
                  </>
                  <span>&nbsp;</span>
                  List
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-file"
                ></i>
                <span>&nbsp;</span>
                Reports
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item disabled" href="#">
                  To Do
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-bullhorn"
                ></i>
                <span>&nbsp;</span>
                Announcements
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user.roleId === 13 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#createAnnouncements"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-plus"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Create for Residents
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId !== 7 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#createAnnouncementForRes"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-plus"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Create For Responders
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#listAnnouncements"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-list"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  List
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-map"
                ></i>
                <span>&nbsp;</span>
                Maps
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addGeofence"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-draw-polygon"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add Geofence
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#viewGeofence"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-globe"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  View/Edit Geofence
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#manageMap"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-map-location-dot"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add/Edit Map Layer
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-users"
                ></i>
                <span> &nbsp;</span>
                Users
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#Add"
                  data-bs-toggle="modal"
                  data-bs-target="#addUser"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-user-plus"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add System Users
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ||
                    user.roleId === 13 ||
                    user.roleId === 15
                      ? ""
                      : "disabled"
                  }`}
                  href="#list"
                  data-bs-toggle="modal"
                  data-bs-target="#listUser"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-users-viewfinder"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Search Staff List
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ||
                    user.roleId === 13 ||
                    user.roleId === 15
                      ? ""
                      : "disabled"
                  }`}
                  href="#list"
                  data-bs-toggle="modal"
                  data-bs-target="#listResidents"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-users-line"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Search Residents
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ? "" : "disabled"
                  }`}
                  href="#list"
                  data-bs-toggle="modal"
                  data-bs-target="#bulkUpload"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-upload"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Upload Resident List
                </a>
                {/* <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  Something else here
                </a> */}
              </div>
            </li>
          </ul>
        </div>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li
              className="nav-item dropdown"
              // style={{ backgroundColor: "yellow" }}
            >
              <a
                className="nav-link mx-5 dropdown-toggle "
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{
                  color: "#438AA2",
                }}
              >
                <i
                  style={{ fontSize: "24px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-user-circle"
                ></i>
                {internetStatus ? (
                  <i
                    title="Online"
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      top: -13,
                      left: -2,
                      color: "lightgreen",
                      zIndex: 1,
                    }}
                    className="fa fa-light fa-circle"
                    // onClick={() => alert("TODO")}
                  ></i>
                ) : (
                  <i
                    title="Offline"
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      top: -13,
                      left: -2,
                      color: "red",
                      zIndex: 1,
                    }}
                    className="fa fa-light fa-circle"
                    // onClick={() => alert("TODO")}
                  ></i>
                )}
                <span>&nbsp;</span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown2"
              >
                <a className="dropdown-item disabled" href="#">
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-user-circle"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  {`${user.firstname} ${user.lastname}`}
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#updateProfile"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-pen-to-square"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Update Profile
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#changePass"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-lock"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Change Password
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    await UserLogout(dispatch, user)
                      .then((res) => {
                        disconnectMqtt();
                      })
                      .catch((e) => {
                        errorToast(e.response?.data?.err);
                      });
                  }}
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-right-from-bracket"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default TopBar;
