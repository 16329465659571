import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserInfo, UpdateUsers, UserLogout } from "../../actions";
import MyPasswordInput from "../../components/MyPasswordInput";
import MyTextInput from "../../components/MyTextInput";
import { errorToast } from "../../helpers/toasts";
import { IsEmpty } from "../../helpers/utils";
import { setIsLoading, setUser } from "../../redux/slices/userSlice";

const FirstLogin = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [societyName, setSocietyName] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [userId, setUserId] = useState("");
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPasswaod, setConfirmPasswaod] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (user) {
      setFirstname(user.firstname);
      setLastname(user.lastname);
      setContact(user.contact.substr(3, user?.contact?.length));
      setUsername(user.username);
      setEmail(user.email);
      setRoleId(user.roleId);
      setUserId(user.id);
    }
  }, [user]);

  const dispatch = useDispatch();

  const goto = async () => {
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(societyName) && user.roleId === 1) {
      errorToast("Society Name is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(username)) {
      errorToast("Username is required");
      return;
    }

    if (IsEmpty(password)) {
      errorToast("Password is required");
      return;
    }
    if (IsEmpty(confirmPasswaod)) {
      errorToast("Confirm Password is required");
      return;
    }

    if (confirmPasswaod !== password) {
      errorToast("Password doesn't matched");
      return;
    }

    dispatch(setIsLoading(true));
    await UpdateUserInfo(
      dispatch,
      {
        userId,
        roleId,
        firstname,
        lastname,
        contact: "+92" + contact,
        email,
        username,
        password,
        isFirstLogin: false,
        groupId: user.groupId,
        societyName: user.roleId === 1 ? societyName : "",
      },
      user
    )
      .then((res) => {
        dispatch(setIsLoading(false));
        Empty();
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  const logout = async () => {
    await UserLogout(dispatch);
  };

  const Empty = () => {
    setContact("");
    setEmail("");
    setFirstname("");
    setLastname("");
    setUsername("");
    setUserId("");
    setRoleId("");
    setPassword("");
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords) {
        // console.log("posss", position.coords);
        // props.getCenter([
        //   {
        //     latitude: Number(position.coords.latitude),
        //     longitude: Number(position.coords.longitude),
        //   },
        // ]);
      }
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2"></div>
          <div className="card col-8" style={{ marginTop: "60px" }}>
            <div className="card-body">
              <div className="row">
                <div className="col-11">
                  <h3>Please Update your information</h3>
                </div>
                <div className="col-1">
                  <div className="float-end">
                    <a href="#" className="nav-link">
                      <i
                        style={{
                          fontSize: "26px",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          logout();
                        }}
                        title="Log Out"
                        className="fa-solid fa-right-from-bracket"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              {user.roleId === 1 ? (
                <div className="row">
                  <div className="col-4">
                    <div className="input-form">
                      <label className="col-form-label"> Society Name </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Society Name"
                        value={societyName}
                        onChange={(e) => setSocietyName(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            goto();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Firstname </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Firstname"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Lastname </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lastname"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="input-form">
                    <MyTextInput
                      name={"Cell Phone #"}
                      val={contact}
                      placeholder={"3xxxxxxxxxx"}
                      setVal={setContact}
                      max={10}
                      pre={true}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Username </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Email </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="input-form">
                    <MyPasswordInput
                      name={"Password"}
                      val={password}
                      placeholder={""}
                      setVal={setPassword}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-form">
                    <MyPasswordInput
                      name={"Confirm Password"}
                      val={confirmPasswaod}
                      placeholder={""}
                      setVal={setConfirmPasswaod}
                    />
                  </div>
                </div>
              </div>
              <div>
                <br />
                <br />
                <div className="text-center">
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : (
                    <button
                      style={{ flex: 1, width: "140px" }}
                      title="Update"
                      className="btn btn-primary"
                      onClick={() => {
                        // setIsLoading(true);
                        goto();
                      }}
                    >
                      {"Update"}
                    </button>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(props.user, null, 2)}</pre> */}
    </>
  );
};

export default FirstLogin;
