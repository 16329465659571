import React, { useEffect, useState } from "react";

const SideBarBtn = (props) => {
  return (
    <>
      <div
        style={{
          //   width: "100%",
          border: "2px solid #45767E",
          paddingTop: "8px",
          paddingBottom: "8px",
          //   height: "50px",
          //   paddingTop: "10px",
          //   paddingBottom: "5px",
          marginTop: "4px",
          opacity: props.opacity || 1,
          //   padding: "12px",
          //   margin: "20px",
        }}
      >
        <div
          className=""
          style={{ color: "#45767E", fontSize: "12px" }}
          title={props.title}
        >
          <span>
            <i
              style={{
                fontSize: "18px",
                zIndex: 1,
                position: props.additions ? "relative" : "",
                left: props.additions ? 8 : 0,
              }}
              className={`fa fa-light ${props.icon}`}
            ></i>
            {props.plusIcon ? (
              <i
                style={{
                  fontSize: "14px",
                  position: "relative",
                  top: -8,
                  // left: -10,
                  // color: "red",
                  // zIndex: 11111,
                }}
                className="fa fa-light fa-plus"
                // onClick={() => alert("TODO")}
              ></i>
            ) : null}
            {props.additions ? (
              <>
                <i
                  style={{
                    fontSize: "18px",
                    bottom: -2,
                    left: 6,
                    position: "relative",
                  }}
                  className="fa fa-light fa-car"
                  // onClick={() => alert("TODO")}
                ></i>
                <i
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    top: -12,
                    left: -10,
                    // color: "red",
                    // zIndex: 11111,
                  }}
                  className="fa fa-light fa-wifi"
                  // onClick={() => alert("TODO")}
                ></i>
              </>
            ) : null}
            {props.applicationBadge ? (
              <i
                style={{
                  fontSize: "10px",
                  position: "relative",
                  top: -8,
                  // left: -10,
                  color: "red",
                  // zIndex: 11111,
                }}
                className="fa fa-light fa-circle"
                // onClick={() => alert("TODO")}
              ></i>
            ) : null}
            {/* <span> &nbsp; </span>
            <span> &nbsp; </span> */}
          </span>
          {/* {props.name || "None"} */}
        </div>
      </div>
    </>
  );
};

export default SideBarBtn;
