/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAnnouncement,
  GetAnnouncements,
  GetCenter,
  SearchAnnouncements,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { setFocusedMarker } from "../../../../../redux/slices/mapSlice";
import {
  setActionVal,
  setConfirmText,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { Resizable } from "react-resizable";
import { ResizableBox } from "react-resizable";
import Draggable from "react-draggable";
import "react-resizable/css/styles.css";

const ActiveAlerts = (props) => {
  const [cusAlert, setCusAlert] = useState(true);
  const [resAlert, setResAlert] = useState(true);
  const [allAlerts, setAllAlerts] = useState([]);
  const [width, setWidth] = useState(340);
  const [height, setHeight] = useState(400);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const type2Alerts = useSelector((state) => state.alert.type2Alerts);
  const activeAlertView = useSelector((state) => state.alert.activeAlertView);
  const cusAlerts = useSelector((state) => state.alert.cusAlerts);

  useEffect(() => {
    const u = [];
    if (cusAlert) {
      u.push(...cusAlerts);
    }
    if (resAlert) {
      u.push(...type2Alerts);
    }
    setAllAlerts(u);
  }, [type2Alerts, cusAlert, cusAlerts, resAlert]);

  const getList = () => {
    let count = 0;
    return allAlerts?.map((val, index) => {
      count++;
      return (
        <tr
          key={index}
          title={"Click to focus"}
          onClick={() => {
            dispatch(setFocusedMarker(val.id));
            if (val.device) {
              GetCenter(dispatch, [
                {
                  latitude: Number(val.lat),
                  longitude: Number(val.lng),
                },
              ]);
            }
            setTimeout(() => {
              dispatch(setFocusedMarker(null));
            }, 30 * 1000);
          }}
        >
          <th scope="row">{count}</th>
          <td style={{ fontSize: "14px" }}>{`${val.customer?.firstname || ""} ${
            val.customer?.lastname || ""
          }`}</td>
          <td style={{ fontSize: "12px" }}>{`${val.contact}`}</td>
          <td style={{ fontSize: "12px", textAlign: "center" }}>
            {val.address || ""}
          </td>
          <td
            title={val.alertType === 2 ? "Responder Alert" : "Customer Alert"}
            style={{ fontSize: "16px", textAlign: "center" }}
          >
            {val.alertType ? (
              <i className="fa-solid fa-registered"></i>
            ) : (
              <i className="fa-solid fa-copyright"></i>
            )}
          </td>
        </tr>
      );
    });
  };

  const onResize = (event, { element, size, handle }) => {
    // console.log("adsdgasfhdhasfdhgfahgd", size);
    setWidth(size.width);
    setHeight(size.height);
    // setState({width: size.width, height: size.height});
  };

  return (
    <>
      {/* <Draggable
        cancel=".noDrag"
        position={{
          x: Number(localStorage.getItem("alertX")),
          y: Number(localStorage.getItem("alertY")),
        }}
        handle=".handle"
        bounds="#root"
        // onStart={() => }
        onStop={(e, position) => {
          // setOpacity(0.85);
          const { x, y } = position;
          console.log("Position alert", x, y);
          localStorage.setItem("alertX", x.toString());
          localStorage.setItem("alertY", y.toString());
        }}
      > */}
      <Resizable
        width={width}
        height={height}
        minConstraints={[300, 360]}
        maxConstraints={[450, 500]}
        onResize={onResize}
      >
        <div
          // className="handle"
          className="offcanvas offcanvas-bottom"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabindex="-1"
          id="activeAlerts"
          aria-labelledby="activeAlerts"
          style={{
            position: "absolute",
            // display: activeAlertView ? "flex" : "none",
            zIndex: 10,
            bottom: 7,
            left: 90,
            width: width + "px",
            height: height + "px",
            // marginLeft: "90px",
            border: "3px solid #438AA2",
          }}
        >
          <div
            className="offcanvas-header"
            style={{
              borderBottom: "1px solid lightgrey",
              backgroundColor: "#121624",
              color: "#438AA2",
            }}
          >
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Active Alerts
            </h5>
            <a
              href="#"
              style={{ color: "#438AA2" }}
              type="button"
              className="nav-link"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i
                type="button"
                // className="btn-close"

                style={{ fontSize: "22px", color: "#d63a3a" }}
                className={"fa fa-close noDrag"}
              ></i>
            </a>
          </div>
          <div className="offcanvas-body noDrag">
            <div className="row">
              <div className="col-12">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    checked={resAlert}
                    value={resAlert}
                    onChange={(e) => setResAlert(!resAlert)}
                  />
                  <label
                    style={{ fontSize: "14px" }}
                    className="form-check-label"
                    for="inlineCheckbox1"
                  >
                    Responder Alerts
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    checked={cusAlert}
                    value={cusAlert}
                    onChange={(e) => setCusAlert(!cusAlert)}
                  />
                  <label
                    style={{ fontSize: "14px" }}
                    className="form-check-label"
                    for="inlineCheckbox2"
                  >
                    Customer Alerts
                  </label>
                </div>
              </div>
              <div className="col-12">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>
                        <label
                          style={{ fontSize: "13px" }}
                          className="col-form-label"
                        >
                          #
                        </label>
                      </th>
                      <th>
                        <label
                          style={{ fontSize: "13px" }}
                          className="col-form-label"
                        >
                          Name
                        </label>
                      </th>
                      <th>
                        <label
                          style={{ fontSize: "13px" }}
                          className="col-form-label"
                        >
                          Contact
                        </label>
                      </th>
                      <th>
                        <label
                          style={{ fontSize: "13px" }}
                          className="col-form-label"
                        >
                          Address
                        </label>
                      </th>
                      <th style={{ width: "60px" }}>
                        <label
                          style={{ fontSize: "13px" }}
                          className="col-form-label"
                        >
                          Alert
                        </label>
                      </th>
                    </tr>
                  </thead>

                  <tbody>{getList()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Resizable>
      {/* </Draggable> */}
    </>
  );
};

export default ActiveAlerts;
