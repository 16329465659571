import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetCenter } from "../../../../actions";
import IconContRound from "../../../../components/IconsContRound";

const LocationIcon = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 80,
          right: 74,
          zIndex: 100,
        }}
      >
        <a
          className="nav-link"
          href="#Location"
          role="button"
          title="Current Location"
          onClick={() => {
            // warnToast("Current Location");
            // alertBeep();
            navigator.geolocation.getCurrentPosition(function (position) {
              if (position.coords) {
                GetCenter(dispatch, [
                  {
                    latitude: Number(position.coords.latitude),
                    longitude: Number(position.coords.longitude),
                  },
                ]);
              }
            });
          }}
        >
          <IconContRound name="location" />
        </a>
      </div>
    </>
  );
};

export default LocationIcon;
