/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import MapGL, {
  Marker,
  Popup,
  FullscreenControl,
  NavigationControl,
} from "@urbica/react-map-gl";
import { useEffect } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const MarkerAndPopUp = (props) => {
  const [pop, setPop] = useState(false);

  useEffect(() => {
    // console.log("><><>>>>>>>>>>>>>>>>", pop);
  }, [pop]);

  //   console.log("><><><><><><><><<>>>>>>>>>>>>>>>>>>>>>>>>", props.data);

  return (
    <React.Fragment>
      <Marker
        longitude={props.data.longitude}
        latitude={props.data.latitude}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          // alert(JSON.stringify(props.data));
          setPop(true);
        }}
      >
        <div
          style={{ zIndex: 111112, cursor: "pointer" }}
          onClick={(e) => {
            // e.originalEvent.stopPropagation();
            // alert(pop);
            setPop(true);
          }}
        >
          <a href="#" className={`clickable_${props.data.id}`}>
            <img
              src={require(`../../../../../assets/pins/${
                props.data?.device?.iconName || "bike"
              }.png`)}
              alt=""
              style={{
                width: "22px",
                transform: `rotate(${
                  props.data?.course ? props.data?.course?.toString() : 0
                }deg)`,
                transformOrigin: "center",
              }}
            />
          </a>
          <Tooltip anchorSelect={`.clickable_${props.data.id}`} place="top">
            <div>{props.data.device.name}</div>
            <div>{`${props.data.user.firstname} ${props.data.user.lastname}`}</div>
            <div>
              {new Date(props.data.createdAt).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </Tooltip>
        </div>
      </Marker>

      {/* {pop ? (
        <Popup
          anchor="top"
          longitude={props.data.longitude}
          latitude={props.data.latitude}
          closeButton={false}
          closeOnClick={false}
          offset={[0, 20]}
          style={{ zIndex: 1, minWidth: "140px" }}
        >
          <div onClick={() => setPop(false)}>
            <span
              className="float-end"
              style={{ cursor: "pointer" }}
              onClick={() => setPop(false)}
            >
              <i
                className="fa fa-remove"
                style={{ fontSize: "16px", color: "red" }}
              ></i>
            </span>
            <div>Device: {props.data.device.name}</div>
            <div>
              User: {`${props.data.user.firstname} ${props.data.user.lastname}`}
            </div>
            <div>
              Time:{" "}
              {new Date(props.data.createdAt).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </div>
        </Popup>
      ) : null} */}
    </React.Fragment>
  );
};

export default MarkerAndPopUp;
