import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser, SearchCustomers, UploadCSV } from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";

const AddBulkResident = (props) => {
  const errRef = useRef();
  const [file, setFile] = useState(null);
  const [errList, setErrorList] = useState([]);
  const [sampleData, setSampleData] = useState([
    {
      firstname: "Muhammad",
      lastname: "Ali",
      contact: "+923139876543",
      network: "zong",
      email: "muhammadali@example.com",
      house: "House # 123",
      block: "Block B",
      street: "Street 01",
      town: "IEP",
      phase: "phase: 01",
      city: "Lahore",
    },
  ]);
  const [headers, setHeaders] = useState([
    {
      label: "Firstname*",
      key: "firstname",
    },
    {
      label: "Lastname",
      key: "lastname",
    },
    {
      label: "Cell Phone #*",
      key: "contact",
    },
    {
      label: "Network*",
      key: "network",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "House*",
      key: "house",
    },
    {
      label: "Block",
      key: "block",
    },
    {
      label: "Street",
      key: "street",
    },
    {
      label: "Town",
      key: "town",
    },
    {
      label: "Phase",
      key: "phase",
    },
    {
      label: "City*",
      key: "city",
    },
  ]);

  const errListMap = () => {
    return errList.map((val, index) => {
      return <div key={index}> {val} </div>;
    });
  };

  const addBulk = async () => {
    if (IsEmpty(file)) {
      errorToast("Please select a file.");
      return;
    }
    // console.log("uploading");
    await UploadCSV(file)
      .then((res) => {
        // console.log("res", res);
        if (res.messages.length === 0) {
          succesToast("Users Added");
        } else {
          errorToast("Uploading error");
          setErrorList(res.messages);
          errRef.current.click();
        }
      })
      .catch((e) => {
        // console.error("eee", e);
        errorToast(e.response.data.err);
      });
  };

  const csvLink = {
    filename: "sample.csv",
    headers: headers,
    data: sampleData,
  };

  return (
    <>
      <button
        style={{ display: "none" }}
        ref={errRef}
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#staticBackdrop"
        aria-controls="staticBackdrop"
      >
        Toggle bottom offcanvas
      </button>

      <div
        className="offcanvas offcanvas-bottom"
        data-bs-backdrop="static"
        tabindex="-1"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
        style={{ zIndex: 1212 }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasBottomLabel">
            <i
              className="fa-solid fa-triangle-exclamation"
              style={{ color: "red", fontSize: "24px", marginRight: "12px" }}
            ></i>
            Uploading CSV Errors
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body small">
          <div className="col-6">{errListMap()}</div>
        </div>
      </div>
      <Modal
        title={"Upload Resident List"}
        name={"bulkUpload"}
        icon={"fa fa-upload"}
      >
        <div className="row">
          <div className="col-6">
            <label className="form-label" style={{ fontWeight: "500" }}>
              Upload CSV:
            </label>
            <div className="input-group mb-3">
              <input
                type="file"
                className="form-control"
                id="inputGroupFile02"
                accept=".csv"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="inputGroupFileAddon04"
                onClick={() => {
                  addBulk();
                }}
              >
                <i
                  style={{ fontSize: "16px", marginLeft: "5px" }}
                  className="fa fa-upload"
                ></i>
                <span> &nbsp;</span>
                Upload
              </button>
            </div>
          </div>
          <div className="col-6"></div>
          <div className="col-6">
            <div className="col-form-label">
              Note{" "}
              <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                (for csv file):
              </span>
            </div>
            <ol>
              <li>Fields marker with asterisk (*) are required.</li>
              <li>First line will be skipped.</li>
            </ol>
          </div>
          <hr />
          <div className="col-12">
            <div className="text-center">
              <h5>Sample CSV </h5>
            </div>
          </div>
          <div className="col-12">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>
                    <label className="col-form-label">Firstname *</label>
                  </th>
                  <th>
                    <label className="col-form-label">Lastname</label>
                  </th>
                  <th>
                    <label className="col-form-label">Contact *</label>
                  </th>
                  <th>
                    <label className="col-form-label">Network *</label>
                  </th>
                  <th>
                    <label className="col-form-label">Email</label>
                  </th>
                  <th>
                    <label className="col-form-label">House *</label>
                  </th>
                  <th>
                    <label className="col-form-label">Block</label>
                  </th>
                  <th>
                    <label className="col-form-label">Street</label>
                  </th>
                  <th>
                    <label className="col-form-label">Town</label>
                  </th>
                  <th>
                    <label className="col-form-label">Phase</label>
                  </th>
                  <th>
                    <label className="col-form-label">City *</label>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Muhammad</td>
                  <td>Ali</td>
                  <td>+923139876543</td>
                  <td>zong</td>
                  <td>muhammadali@example.com</td>
                  <td>House # 123</td>
                  <td>Block B</td>
                  <td>Street 01</td>
                  <td>IEP</td>
                  <td>Phase: 01</td>
                  <td>Lahore</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12">
            <div className="float-end">
              <CSVLink className="btn btn-primary" {...csvLink}>
                Export Sample CSV
              </CSVLink>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddBulkResident;
