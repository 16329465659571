/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import * as geolib from "geolib";

import "react-datepicker/dist/react-datepicker.css";
import MapGL, {
  Marker,
  Popup,
  FullscreenControl,
  NavigationControl,
} from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";
import {
  DelGeofences,
  GetGeofences,
  GetPosition,
  GetPositionWhileAlert,
  UpdateGeofences,
} from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { setGeoIsLoading } from "../../../../../redux/slices/mapSlice";
import {
  setActionVal,
  setConfirmText,
} from "../../../../../redux/slices/userSlice";
import { IsEmpty } from "../../../../../helpers/utils";
import MarkerAndPopUp from "./MarkerAndPopUp";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import MarkerAndPopUpEvent from "./MarkerAndPopUpEvent";
import { setAlertDetailEvent } from "../../../../../redux/slices/alertSlice";

var arr = [];

const Playback = (props) => {
  const dispatch = useDispatch();
  const geoIsLoading = useSelector((state) => state.map.geoIsLoading);

  const geofences = useSelector((state) => state.map.geofences);
  const latitude = useSelector((state) => state.map.latitude);
  const longitude = useSelector((state) => state.map.longitude);
  // console.log("Supervisor - Geo View");
  const alertDetailEvent = useSelector((state) => state.alert.alertDetailEvent);

  const applicationDetails = useSelector(
    (state) => state.user.applicationDetails
  );
  const devices = useSelector((state) => state.user.devices);
  const responders = useSelector((state) => state.user.responders);

  const btnRef = useRef();
  const [geofenceArr, setGeofenceArr] = useState([]);
  //   const [res, setRes] = useState(null);
  const [val, setVal] = useState(0);
  //   const [deviceId, setDeviceId] = useState(null);
  //   const [startTime, setStartTime] = useState("");
  //   const [endTime, setEndTime] = useState("");
  const [updatedArr, setUpdatedArr] = useState([]);
  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [obj, setObj] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [map, setMap] = useState(false);
  const [mapLat, setMapLat] = useState(null);
  const [mapLng, setMapLng] = useState(null);
  const [showPath, setShowPath] = useState(true);
  const [showMarker, setShowMarker] = useState(true);
  const [list, setList] = useState([]);
  const [direction, setDirection] = useState([]);
  const [resMarker, setResMarker] = useState({
    longitude: 74.325816,
    latitude: 31.484722,
    icon: "bike",
  });
  const [position, setPosition] = useState({
    longitude: 74.325816,
    latitude: 31.484722,
  });
  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  useEffect(() => {
    setMapLat(latitude);
    setMapLng(mapLng);
  }, [latitude, longitude]);

  useEffect(() => {
    if (alertDetailEvent) {
      // console.log("sadsadasdasd >>>>>>>>>>>>>>>>>>>", alertDetailEvent);
      search(
        alertDetailEvent?.responderInvolved,
        alertDetailEvent?.createdAt,
        alertDetailEvent?.panicResolvedOprDate
      );
    }
  }, [alertDetailEvent]);

  const search = async (res, startTime, endTime) => {
    if (IsEmpty(res)) {
      errorToast("No responder was Assigned to this alert.");
      return;
    }
    if (IsEmpty(startTime)) {
      errorToast("Start time is required");
      return;
    }
    if (IsEmpty(endTime)) {
      errorToast("Alert Un-resolved");
      return;
    }

    let endTimeUTC = endTime ? new Date(endTime).toUTCString() : null;
    let startTimeUTC = startTime ? new Date(startTime).toUTCString() : null;

    dispatch(setGeoIsLoading(true));
    await GetPositionWhileAlert(dispatch, {
      userId: res,
      startTime: startTime,
      endTime: endTime,
      distance: 10,
    })
      .then((res) => {
        if (res.data.data.length === 0) {
          errorToast("No Record found.");
        }

        const data = res.data.data;

        setDirection(res.data.directionMarkers);
        setList(data);

        setResMarker(data[0]);

        let coordinates = [];
        for (let i = 0; i < data.length; i++) {
          coordinates.push([data[i].longitude, data[i].latitude]);
        }

        const centerData = geolib.getCenter(coordinates);
        setMapLat(centerData.latitude);
        setMapLng(centerData.longitude);

        // const features = {
        //   id: "79df15ba9d39ca19fa6a7943accd29eb",
        //   type: "Feature",
        //   properties: {},
        //   geometry: {
        //     type: "LineString",
        //     coordinates: coordinates,
        //   },
        // };

        // let struct = {
        //   type: "FeatureCollection",
        //   features: [features],
        // };

        // setObj(struct);

        if (res.data.length) {
          setTimeout(() => {
            setMap(true);
          }, 1000);
        }

        dispatch(setGeoIsLoading(false));
      })
      .catch((e) => {
        // console.log(e);
        dispatch(setGeoIsLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  const getResOptions = () => {
    if (responders.length > 0) {
      return responders.map((val, index) => {
        return (
          <option value={val.id}>{`${val.firstname} ${val.lastname}`}</option>
        );
      });
    }
  };

  const markerAndPopUps = () => {
    if (direction.length > 0) {
      return direction.map((val, index) => {
        // console.log("asdasdasdasdasdasdasdasdsadasdasd", val);
        return <MarkerAndPopUpEvent data={val} />;
      });
    }
  };

  const getDeviceOptions = () => {
    if (devices.length > 0) {
      return devices.map((val, index) => {
        return <option value={val.id}>{`${val?.name}`}</option>;
      });
    }
  };

  const locationMarker = () => {
    return (
      <Marker
        longitude={Number(alertDetailEvent?.lng)}
        latitude={Number(alertDetailEvent?.lat)}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          // alert(JSON.stringify(props.data));
        }}
      >
        <div
          style={{ zIndex: 1112, cursor: "pointer" }}
          onClick={(e) => {
            // e.originalEvent.stopPropagation();
            // alert(pop);
          }}
        >
          <a href="#" className={`panicDetails`}>
            <img
              src={require(`../../../../../assets/pins/13.png`)}
              alt=""
              style={{
                width: "28px",
              }}
            />
          </a>
          <Tooltip anchorSelect={`.panicDetails`} place="top">
            <div>{`${alertDetailEvent?.customer?.firstname} ${alertDetailEvent?.customer?.lastname}`}</div>
            <div>{`${alertDetailEvent?.address}`}</div>
            <div>
              {new Date(alertDetailEvent?.createdAt).toLocaleDateString(
                "en-us",
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </div>
          </Tooltip>
        </div>
      </Marker>
    );
  };

  const responderMarker = () => {
    return (
      <Marker
        longitude={Number(resMarker?.longitude)}
        latitude={Number(resMarker?.latitude)}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          // alert(JSON.stringify(props.data));
        }}
      >
        <div
          style={{ zIndex: 111112, cursor: "pointer" }}
          onClick={(e) => {
            // e.originalEvent.stopPropagation();
            // alert(pop);
          }}
        >
          <a href="#" className={`resDetails`}>
            <img
              src={require(`../../../../../assets/pins/${
                list[0]?.device?.iconName || "bike"
              }.png`)}
              alt=""
              style={{
                width: "20px",
                transform: `rotate(${
                  resMarker?.course ? resMarker?.course?.toString() : 0
                }deg)`,
                transformOrigin: "center",
              }}
            />
          </a>
          <Tooltip anchorSelect={`.resDetails`} place="top">
            <div>{`${resMarker?.device?.name || "Null"}`}</div>
            <div>{`${resMarker?.user?.firstname} ${resMarker?.user?.lastname}`}</div>
            <div>
              {new Date(resMarker?.createdAt).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </Tooltip>
        </div>
      </Marker>
    );
  };

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <MapGL
          initialState={{ data }}
          mapStyle={mapStyle}
          style={{ width: "100%", height: "580px" }}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={mapLat || 31.4676}
          longitude={mapLng || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          {list.length ? responderMarker() : null}
          {alertDetailEvent ? locationMarker() : null}
          {/* {showMarker ? markerAndPopUps() : null} */}
          {/* {showPath ? (
            <Draw
              pointControl={false}
              data={obj}
              lineStringControl={false}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              polygonControl={false}
              trashControl={false}
            />
          ) : null} */}
        </MapGL>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        title={"Playback"}
        name={"playback"}
        icon={"fa fa-magnifying-glass-location"}
      >
        <div className="row">
          <div className="col-4">
            <a
              href="#"
              className="nav-link"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setAlertDetailEvent(null));
                setList([]);
                setMap(false);
              }}
            >
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go Back"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#eventPlayback"}
              ></i>
            </a>
          </div>
          {/* <div className="col-1">
            <span>
              <i className="fa-solid fa-play"></i>
              <i className="fa-solid fa-play"></i>
              <i className="fa-solid fa-play"></i>
            </span>
          </div> */}
          <div className="col-12">{getMap()}</div>
          <div className="col-12">
            <input
              type="range"
              className="slider2"
              style={{ zIndex: 1, boxShadow: "1px 2px 9px grey" }}
              max={list?.length - 1 || 1}
              min={0}
              value={val}
              onChange={(e) => {
                // console.log("asdasdasdasdas", list[e.target.value]);
                setResMarker(list[e.target.value]);
                setVal(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Playback;
