import React from "react";

const Modal = (props) => {
  return (
    <>
      <div
        className="modal fade"
        id={props.name || "test"}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#121624" }}
            >
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ color: "#438AA2" }}
              >
                <i style={{ fontSize: "22px" }} className={props.icon}></i>
                <span> &nbsp; </span>
                {props.title || "Demo Modal"}
              </h5>

              <i
                type="button"
                // className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ fontSize: "24px", color: "#438AA2" }}
                className={"fa fa-close"}
              ></i>
            </div>
            <div className="modal-body">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
