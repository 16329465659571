import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logs: [],
  attandance: [],
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
    setAttandance: (state, action) => {
      state.attandance = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLogs, setAttandance } = logsSlice.actions;

export default logsSlice.reducer;
