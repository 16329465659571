import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteDeviceAPI,
  GetAllDevice,
  SearchDevices,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import {
  setActionVal,
  setApplicationdetails,
  setConfirmText,
  setEditDeviceData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";

const RejectedRequest = (props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [contact, setContact] = useState("");
  const [imei, setImei] = useState("");
  const [des, setDes] = useState("");
  const [type, setType] = useState("");

  const rejectedApplications = useSelector(
    (state) => state.user.rejectedApplications
  );

  const getList = () => {
    let count = 0;
    return rejectedApplications?.map((val, index) => {
      count++;
      return (
        <tr key={index}>
          <th scope="row">{count}</th>
          <td>{`${val.firstname} ${val.lastname}`}</td>
          <td>{`${val.email}`}</td>
          <td>{`${val.contact}`}</td>
          <td>{`${val?.house || ""} ${val?.block || ""} ${val?.street || ""} ${
            val?.phase || ""
          } ${val?.city || ""}`}</td>
          <td>{`${val?.rejectedNote || ""}`}</td>
          <td>
            <a href="#" className="nav-link" title="View On Map">
              <i
                style={{ fontSize: "24px" }}
                className="fa-solid fa-map-location-dot"
                data-bs-toggle="modal"
                data-bs-target={"#viewMarkerWithGeofence"}
                onClick={(e) => {
                  //   alert("to do");
                  e.preventDefault();
                  dispatch(setApplicationdetails({ ...val, hideBtn: true }));
                }}
              ></i>
            </a>
          </td>
          {/* <td>
            <a className="nav-link" href="#">
              <i
                style={{ fontSize: "24px", color: "green" }}
                className="fa fa-light fa-circle-check"
                title="Approve"
                onClick={(e) => {
                  //   alert("to do");
                  e.preventDefault();
                  approveReq(val.id);
                }}
              ></i>
              <i
                style={{ fontSize: "24px", color: "red", marginLeft: "10px" }}
                className="fa fa-light fa-circle-xmark"
                title="Reject"
                onClick={(e) => {
                  e.preventDefault();
                  rejectReq(val.id);
                }}
              ></i>
            </a>
          </td> */}
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Rejected Requests"}
        name={"rejectRequests"}
        icon={"fa fa-rectangle-xmark"}
      >
        <div className="row">
          {/* <div className="col-12">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add Device"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addDevice"}
              ></i>
            </a>
          </div> */}
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    {/* <input
                      type={"text"}
                      className="form-control form-control-sm"
                      placeholder={"Name"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    /> */}
                    <label className="col-form-label">Name</label>
                  </th>
                  <th>
                    {/* <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={email}
                      placeholder={"Email"}
                      onChange={(e) => setEmail(e.target.value)}
                    /> */}
                    <label className="col-form-label">Email</label>
                  </th>
                  <th>
                    {/* <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={contact}
                      placeholder={"Cell Phone #"}
                      onChange={(e) => setContact(e.target.value)}
                    /> */}
                    <label className="col-form-label">Cell Phone #</label>
                  </th>
                  <th>
                    {/* <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={username}
                      placeholder={"Username"}
                      onChange={(e) => setUsername(e.target.value)}
                    /> */}
                    <label className="col-form-label">Address</label>
                  </th>
                  <th style={{ width: "80px" }}>
                    <label className="col-form-label">Reason</label>
                  </th>
                  <th style={{ width: "80px" }}>
                    <label className="col-form-label">View</label>
                  </th>
                </tr>
              </thead>

              <tbody>{getList()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RejectedRequest;
