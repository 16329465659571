/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAnnouncement,
  GetAnnouncements,
  GetCenter,
  SearchAnnouncements,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { setFocusedMarker } from "../../../../../redux/slices/mapSlice";
import {
  setActionVal,
  setConfirmText,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

const ActiveStaff = (props) => {
  const [opr, setOpr] = useState(true);
  const [res, setRes] = useState(true);
  const [sup, setSup] = useState(true);
  const [soc, setSoc] = useState(true);
  const [allUsers, setAllUsers] = useState([]);

  const [width, setWidth] = useState(340);
  const [height, setHeight] = useState(400);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const supervisors = useSelector((state) => state.user.supervisors);
  const operators = useSelector((state) => state.user.operators);
  const responders = useSelector((state) => state.user.responders);
  const societyAdmins = useSelector((state) => state.user.societyAdmins);

  useEffect(() => {
    const u = [];
    if (res) {
      u.push(...responders);
    }
    if (opr) {
      u.push(...operators);
    }
    if (sup) {
      u.push(...supervisors);
    }
    // if (soc) {
    //   u.push(...societyAdmins);
    // }
    setAllUsers(u);
  }, [res, opr, sup, responders, operators, supervisors]);

  const getList = () => {
    let count = 0;
    return allUsers?.map((val, index) => {
      if (val.userActivation) {
        count++;
        return (
          <tr
            key={index}
            onClick={() => {
              // console.log("vallll", val);
              dispatch(setFocusedMarker(val.device?.id));
              if (val.device) {
                GetCenter(dispatch, [
                  {
                    latitude: Number(val.device?.latitude),
                    longitude: Number(val.device?.longitude),
                  },
                ]);
              }
              setTimeout(() => {
                dispatch(setFocusedMarker(null));
              }, 30 * 1000);
            }}
          >
            <th scope="row">{count}</th>
            <td
              style={{ fontSize: "14px" }}
            >{`${val.firstname} ${val.lastname}`}</td>
            <td style={{ fontSize: "14px" }}>{`${val.contact}`}</td>
            <td style={{ fontSize: "14px" }}>
              {val.roleId === 2
                ? "Supervisor"
                : val.roleId === 3
                ? "Operator"
                : val.roleId === 4
                ? "Responder"
                : val.roleId === 12
                ? "Service Contact"
                : "Society Admin"}
            </td>
            <td style={{ fontSize: "14px", textAlign: "center" }}>
              {val.userAssignment ? (
                <i
                  style={{ fontSize: "16px", color: "orange" }}
                  className="fa fa-light fa-circle"
                  title="Buzy"
                ></i>
              ) : val.userActivation ? (
                <i
                  style={{ fontSize: "16px", color: "green" }}
                  className="fa fa-light fa-circle"
                  title="Active"
                ></i>
              ) : (
                ""
              )}
            </td>
          </tr>
        );
      }
    });
  };

  const onResize = (event, { element, size, handle }) => {
    // console.log("adsdgasfhdhasfdhgfahgd", size);
    setWidth(size.width);
    setHeight(size.height);
    // setState({width: size.width, height: size.height});
  };

  return (
    <>
      <Resizable
        width={width}
        height={height}
        minConstraints={[300, 360]}
        maxConstraints={[450, 500]}
        onResize={onResize}
      >
        <div
          className="offcanvas offcanvas-bottom"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabindex="-1"
          id="staffOnDutyOffCanvas"
          aria-labelledby="staffOnDutyOffCanvas"
          style={{
            position: "absolute",
            // display: activeAlertView ? "flex" : "none",
            zIndex: 10,
            bottom: 7,
            left: 90,
            width: width + "px",
            height: height + "px",
            // marginLeft: "90px",
            border: "3px solid #438AA2",
          }}
        >
          <div
            className="offcanvas-header"
            style={{
              borderBottom: "1px solid lightgrey",
              backgroundColor: "#121624",
              color: "#438AA2",
            }}
          >
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Active Staff
            </h5>
            <a
              href="#"
              style={{ color: "#438AA2" }}
              type="button"
              className="nav-link"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i
                type="button"
                // className="btn-close"

                style={{ fontSize: "22px", color: "#d63a3a" }}
                className={"fa fa-close noDrag"}
              ></i>
            </a>
          </div>
          <div className="offcanvas-body">
            <div className="row">
              <div className="col-12">
                {user.roleId !== 3 ? (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      checked={opr}
                      value={opr}
                      onChange={(e) => setOpr(!opr)}
                    />
                    <label
                      style={{ fontSize: "14px" }}
                      className="form-check-label"
                      for="inlineCheckbox1"
                    >
                      Operators
                    </label>
                  </div>
                ) : null}
                {user.roleId !== 3 && user.roleId !== 2 ? (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox2"
                      checked={sup}
                      value={sup}
                      onChange={(e) => setSup(!sup)}
                    />
                    <label
                      style={{ fontSize: "14px" }}
                      className="form-check-label"
                      for="inlineCheckbox2"
                    >
                      Supervisors
                    </label>
                  </div>
                ) : null}
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox3"
                    checked={res}
                    value={res}
                    onChange={(e) => setRes(!res)}
                  />
                  <label
                    style={{ fontSize: "14px" }}
                    className="form-check-label"
                    for="inlineCheckbox3"
                  >
                    Responders
                  </label>
                </div>
                {/* <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox4"
                  checked={soc}
                  value={soc}
                  onChange={(e) => setSoc(!soc)}
                />
                <label
                  style={{ fontSize: "14px" }}
                  className="form-check-label"
                  for="inlineCheckbox4"
                >
                  Security Officer
                </label>
              </div> */}
              </div>
              <div className="col-12">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label"
                        >
                          #
                        </label>
                      </th>
                      <th>
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label"
                        >
                          Name
                        </label>
                      </th>
                      <th>
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label"
                        >
                          Cell Phone #
                        </label>
                      </th>
                      <th>
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label"
                        >
                          Type
                        </label>
                      </th>
                      <th style={{ width: "60px" }}>
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label"
                        >
                          Status
                        </label>
                      </th>
                    </tr>
                  </thead>

                  <tbody>{getList()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Resizable>
    </>
  );
};

export default ActiveStaff;
