import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { VerifyEmailAPI } from "../../actions";
import MyPasswordInput from "../../components/MyPasswordInput";
import { errorToast } from "../../helpers/toasts";
import { IsEmpty } from "../../helpers/utils";
import { setIsLoading } from "../../redux/slices/userSlice";

const VerifyEmail = (props) => {
  useEffect(() => {
    setTimeout(() => {
      goto();
    }, 1000);
  }, []);
  const params = useParams();
  const [emailText, setEmailText] = useState("Verifying your email");
  const [emailTextSub, setEmailTextSub] = useState("Please Wait...");

  const isLoading = useSelector((state) => state.user.isLoading);
  const dispatch = useDispatch();

  const goto = async () => {
    // console.log(params.resetLink);
    if (IsEmpty(params.resetLink)) {
      errorToast("Reset Link is required");
      return;
    }

    dispatch(setIsLoading(true));
    await VerifyEmailAPI(dispatch, {
      resetId: params.resetLink,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        setEmailText("Email Verified");
        setEmailTextSub("Success");
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
        setEmailText("Error");
        setEmailTextSub(
          e.response?.data?.err || "Something went wrong try again"
        );
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12"></div>
          <div
            className="card col-lg-4 col-sm-12"
            style={{ marginTop: "60px" }}
          >
            <div className="card-body">
              <center>
                {/* <img
                  src={require("./logo2.png")}
                  width="120"
                  height="120"
                  alt=""
                  title={"Emergency Alert & Response System"}
                /> */}
                <h3>{emailText}</h3>
                <p>{emailTextSub}</p>
              </center>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(props.user, null, 2)}</pre> */}
    </>
  );
};

export default VerifyEmail;
