import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: null,
  type2Alerts: [],
  cusAlerts: [],
  searchedType2Alerts: [],
  searchedCusAlerts: [],
  panicId: null,
  isCloseLoading: false,
  isAlertLoading: false,
  isAssignLoading: false,
  isResolveLoading: false,
  isRelocateLoading: false,
  activeAlertView: false,
  alertDetail: null,
  alertDetailEvent: null,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setActiveAlertView: (state, action) => {
      state.activeAlertView = action.payload;
    },
    setPanicId: (state, action) => {
      state.panicId = action.payload;
    },
    setType2Alerts: (state, action) => {
      state.type2Alerts = action.payload;
    },
    setCusAlerts: (state, action) => {
      state.cusAlerts = action.payload;
    },
    setSearchedType2Alerts: (state, action) => {
      state.searchedType2Alerts = action.payload;
    },
    setSearchedCusAlerts: (state, action) => {
      state.searchedCusAlerts = action.payload;
    },
    setIsCloseLoading: (state, action) => {
      state.isCloseLoading = action.payload;
    },
    setIsResolveLoading: (state, action) => {
      state.isResolveLoading = action.payload;
    },
    setIsAssignLoading: (state, action) => {
      state.isAssignLoading = action.payload;
    },
    setIsAlertLoading: (state, action) => {
      state.isAlertLoading = action.payload;
    },
    setIsRelocateLoading: (state, action) => {
      state.isRelocateLoading = action.payload;
    },
    setAlertDetail: (state, action) => {
      state.alertDetail = action.payload;
    },
    setAlertDetailEvent: (state, action) => {
      state.alertDetailEvent = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setList,
  setPanicId,
  setType2Alerts,
  setIsAlertLoading,
  setIsCloseLoading,
  setIsResolveLoading,
  setCusAlerts,
  setIsAssignLoading,
  setIsRelocateLoading,
  setSearchedCusAlerts,
  setSearchedType2Alerts,
  setActiveAlertView,
  setAlertDetail,
  setAlertDetailEvent,
} = alertSlice.actions;

export default alertSlice.reducer;
