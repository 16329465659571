/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import Map, { Layer, Source } from "react-map-gl";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { orderByDistance } from "geolib";
import { useDispatch, useSelector } from "react-redux";
import {
  ClosePanic_UpdatePanics,
  GetActivePanics,
  GetAllDevice,
  OperatorAcceptPanic_UpdatePanics,
  OperatorAssignPanicToRes_UpdatePanics,
  OperatorRelocatePanic,
  OperatorResolvePanic_UpdatePanics,
  SetChatbox,
  SetOpenedChatbox,
  SupervisorAcceptPanic_UpdatePanics,
  SupervisorAssignPanicToOpr_UpdatePanics,
  SupervisorAssignPanicToRes_UpdatePanics,
  SupervisorResolvePanic_UpdatePanics,
} from "../../../../actions";
import MyTextInput from "../../../../components/MyTextInput";
import { errorToast, succesToast } from "../../../../helpers/toasts";
import { IsEmpty } from "../../../../helpers/utils";
import { alertBeepStop, Beep5Stop } from "../../../../Howler/Beeper";
import {
  setIsAssignLoading,
  setIsCloseLoading,
  setIsRelocateLoading,
  setIsResolveLoading,
} from "../../../../redux/slices/alertSlice";
import { setLatLng, setZoom } from "../../../../redux/slices/mapSlice";
import ResSpot from "./Marker/ResSpot";
import Select from "react-select";
import Spot from "./Marker/Spot";
import StaticMarker from "./Marker/StaticMarker";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import ReactTimeAgo from "react-time-ago";
import {
  setAlertChatBadges,
  setChatboxVisible,
  setChatType,
  setFreeChatBadges,
  setOpenedChatbox,
} from "../../../../redux/slices/chatSlice";
import { URL } from "../../../../config";
import { setImageDetail } from "../../../../redux/slices/userSlice";
JavascriptTimeAgo.addLocale(en);
JavascriptTimeAgo.addLocale(ru);
const moment = require("moment");

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapGl = (props) => {
  const closeRef = useRef();
  const selectRef = useRef();

  const [imageLink, setImageLink] = useState(null);

  const [opr, setOpr] = useState("");
  const [res, setRes] = useState("");
  const [resOption, setResOption] = useState("");
  const [panicId, setPanicId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [resloveRemarks, setResloveRemarks] = useState("");

  const mapType = useSelector((state) => state.map.mapStyle);
  const mapZoom = useSelector((state) => state.map.mapZoom);
  const latitude = useSelector((state) => state.map.latitude);
  const longitude = useSelector((state) => state.map.longitude);
  const focusedMarker = useSelector((state) => state.map.focusedMarker);
  const staticMarker = useSelector((state) => state.map.staticMarker);
  const showStaticMarkerLayer = useSelector(
    (state) => state.map.showStaticMarkerLayer
  );

  const freeChatBadges = useSelector((state) => state.chat.freeChatBadges);
  const alertChatBadges = useSelector((state) => state.chat.alertChatBadges);
  const chatBoxVisible = useSelector((state) => state.chat.chatBoxVisible);

  const type2Alerts = useSelector((state) => state.alert.type2Alerts);
  const cusAlerts = useSelector((state) => state.alert.cusAlerts);
  const isCloseLoading = useSelector((state) => state.alert.isCloseLoading);
  const isResolveLoading = useSelector((state) => state.alert.isResolveLoading);
  const isAssignLoading = useSelector((state) => state.alert.isAssignLoading);
  const isRelocateLoading = useSelector(
    (state) => state.alert.isRelocateLoading
  );

  const user = useSelector((state) => state.user.user);
  const devices = useSelector((state) => state.user.devices);
  const list = useSelector((state) => state.user.responders);
  const operators = useSelector((state) => state.user.operators);
  const supervisors = useSelector((state) => state.user.supervisors);

  const dispatch = useDispatch();

  const [viewport, setViewport] = useState({
    latitude: latitude || 31,
    longitude: longitude || 74,
    width: "100%",
    height: "100vh",
    zoom: mapZoom,
  });

  const mapRef = useRef();
  const KEY =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  // var mapType = "mapbox://styles/mapbox/streets-v9";

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: latitude,
      longitude: longitude,
      zoom: mapZoom,
    });
  }, [mapZoom, longitude, latitude]);

  useEffect(() => {
    GetAllDevice(dispatch);
    GetActivePanics(dispatch);
  }, []);

  // useEffect(() => {
  //   if (list.length) {
  //     getResOptions();
  //   }
  // }, [list]);

  const getResOptions = (panicData) => {
    // console.log("listtttttttttttt", list);
    const resLatLngList = list
      .map((item, index) => {
        return {
          ...item,
          latitude: Number(item?.lat),
          longitude: Number(item?.lng),
        };
      })
      .filter((item) => item.lat);

    const dataWithDistance = orderByDistance(
      { latitude: Number(panicData?.lat), longitude: Number(panicData?.lng) },
      resLatLngList
    );

    const data = dataWithDistance
      .map((item, index) => {
        if (item.userActivation && !item.userAssignment) {
          return {
            label: (
              <div className="row">
                <div className="col-12">
                  <img
                    src={require(`../../../../assets/pins/${
                      item?.iconName || "car"
                    }.png`)}
                    style={{ height: "30px", marginRight: "10px" }}
                    alt=""
                  />
                  <span>{`${item.firstname} ${item.lastname}`}</span>
                </div>
              </div>
            ),
            value: item.id,
          };
        }
      })
      .filter((notUndefined) => notUndefined !== undefined);

    // data.push();
    data.splice(0, 0, {
      label: "Select a responder",
      value: "",
    });
    // console.log(
    //   "sdahkdaskhdkjsahdkhaskjdhkjsahd",
    //   resLatLngList,
    //   dataWithDistance,
    //   data
    // );
    return data;
  };

  const closePanic = async () => {
    if (IsEmpty(panicId)) {
      errorToast("Alert Id is required");
      return;
    }
    if (IsEmpty(remarks)) {
      errorToast("Closing Remarks are required");
      return;
    }
    dispatch(setIsCloseLoading(true));
    await ClosePanic_UpdatePanics(dispatch, {
      id: panicId,
      remarks,
    })
      .then(() => {
        dispatch(setIsCloseLoading(false));
        setPanicId("");
        setRemarks("");
        if (closeRef?.current) {
          closeRef.current.click();
        }
        SetOpenedChatbox(dispatch, {
          id: null,
          name: null,
        });
        SetChatbox(dispatch, false);
      })
      .catch((e) => {
        dispatch(setIsCloseLoading(false));
      });

    dispatch(setIsCloseLoading(false));
  };

  const filterResponders = (id) => {
    let data = list.filter((item) => item.id === id);
    if (data.length > 0) {
      return data[0].firstname + " " + data[0].lastname;
    } else {
      return null;
    }
  };

  const filterOperators = (id) => {
    let data = operators.filter((item) => item.id == id);
    if (data.length > 0) {
      return data[0];
    } else {
      return null;
    }
  };

  const filterSupervisors = (id) => {
    let data = supervisors.filter((item) => item.id == id);
    if (data.length > 0) {
      return data[0];
    } else {
      return null;
    }
  };

  const getSupervisorFunctions = (data, item) => {
    if (data) {
      return null;
    } else {
      if (item.statusId == 1 && item.operatorInvolved == null) {
        return (
          <>
            <br />
            <select
              className="form-control form-control-sm"
              value={opr}
              onChange={(e) => setOpr(e.target.value)}
            >
              <option value=""> Select An Operator </option>
              {getOperatorsList()}
            </select>
            <br />
            <div className="d-grid gap-2">
              <a
                href="#"
                className={`btn btn-sm btn-success ${
                  isAssignLoading ? "disabled" : ""
                }`}
                onClick={async (e) => {
                  alertBeepStop();
                  e.preventDefault();
                  if (opr === "" || opr === undefined || opr === null) {
                    errorToast(
                      "Please select a operator from the dropdown List."
                    );
                  } else {
                    dispatch(setIsAssignLoading(true));
                    await SupervisorAssignPanicToOpr_UpdatePanics(
                      dispatch,

                      {
                        id: item.id,
                        contact: item.contact,
                        operatorInvolved: opr,
                      }
                    )
                      .then(() => {
                        dispatch(setIsAssignLoading(false));
                      })
                      .catch((e) => {
                        dispatch(setIsAssignLoading(false));
                        errorToast(e.response?.data?.err);
                      });
                    setTimeout(() => {
                      setOpr("");
                    }, 2000);
                  }
                }}
              >
                {/* <span>
                  <i className="fa fa-check"></i>
                  &nbsp;
                </span> */}
                {isAssignLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Assign to Operator"
                )}
              </a>
            </div>
            <br />
            <div className="d-grid gap-2">
              <a
                href="#"
                className={`btn btn-sm btn-success ${
                  isAssignLoading ? "disabled" : ""
                }`}
                onClick={async (e) => {
                  e.preventDefault();
                  await SupervisorAcceptPanic_UpdatePanics(dispatch, {
                    id: item.id,
                  })
                    .then(() => {
                      alertBeepStop();
                      dispatch(setIsAssignLoading(false));
                    })
                    .catch((e) => {
                      dispatch(setIsAssignLoading(false));
                      errorToast(e.response?.data?.err);
                    });
                }}
              >
                <span>
                  <i className="fa fa-check"></i>
                  &nbsp;
                </span>
                {isAssignLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Self Confirm"
                )}
              </a>
            </div>
          </>
        );
      } else if (item.statusId == 2 && item.operatorInvolved == null) {
        return (
          <>
            {!item.isGSM && item.alertType !== 2 ? (
              <div>
                <br />
                <div className="d-grid gap-2">
                  <a
                    href="#"
                    title="Relocate alert"
                    className={`btn btn-sm btn-success ${
                      isRelocateLoading ? "disabled" : ""
                    }`}
                    onClick={async (e) => {
                      e.preventDefault();

                      dispatch(setIsRelocateLoading(true));

                      await OperatorRelocatePanic(dispatch, item.id)
                        .then(() => {
                          succesToast("Alert relocated.");
                          dispatch(setIsRelocateLoading(false));
                        })
                        .catch((e) => {
                          dispatch(setIsRelocateLoading(false));
                          errorToast(e.response?.data?.err);
                        });
                    }}
                  >
                    <span>
                      <i className="fa-regular fa-map"></i>
                      &nbsp;&nbsp;&nbsp;
                    </span>
                    {isRelocateLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "Relocate Alert"
                    )}
                  </a>
                </div>
              </div>
            ) : null}

            <br />
            <Select
              value={res}
              onChange={(obj) => setRes(obj)}
              options={getResOptions(item)}
            />
            <br />
            <div className="d-grid gap-2">
              <a
                href="#"
                className={`btn btn-sm btn-success ${
                  isAssignLoading ? "disabled" : ""
                }`}
                onClick={async (e) => {
                  e.preventDefault();
                  if (res === "" || res === undefined || res === null) {
                    errorToast(
                      "Please select a responder from the dropdown List."
                    );
                  } else {
                    dispatch(setIsAssignLoading(true));
                    await SupervisorAssignPanicToRes_UpdatePanics(dispatch, {
                      id: item.id,
                      contact: item.contact,
                      responderInvolved: res.value,
                    })
                      .then(() => {
                        dispatch(setIsAssignLoading(false));
                      })
                      .catch((e) => {
                        dispatch(setIsAssignLoading(false));
                        errorToast(e.response?.data?.err);
                      });
                    setTimeout(() => {
                      setRes("");
                    }, 2000);
                  }
                }}
              >
                <span>
                  <i className="fa fa-check"></i>
                  &nbsp;
                </span>
                {isAssignLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Assign to Responder"
                )}
              </a>
            </div>
          </>
        );
      } else if (
        (item.statusId == 4 || item.statusId == 5) &&
        item.responderInvolved != 0 &&
        item.responderInvolved != null
      ) {
        return (
          <>
            <br />
            <div className="d-grid gap-2">
              <input
                type={"text"}
                className="form-control form-control-sm"
                value={resloveRemarks}
                placeholder={"Resolve Remarks"}
                onChange={(e) => setResloveRemarks(e.target.value)}
              />
            </div>

            <br />
            <div className="d-grid gap-2">
              <a
                href="#"
                className={`btn btn-sm btn-success ${
                  isResolveLoading ? "disabled" : ""
                }`}
                onClick={async (e) => {
                  e.preventDefault();
                  alertBeepStop();
                  dispatch(setIsResolveLoading(true));
                  await SupervisorResolvePanic_UpdatePanics(dispatch, {
                    id: item.id,
                    remarks: resloveRemarks,
                  })
                    .then(() => {
                      dispatch(setIsResolveLoading(false));
                      setResloveRemarks(null);
                      SetOpenedChatbox(dispatch, {
                        id: null,
                        name: null,
                      });
                      SetChatbox(dispatch, false);
                      succesToast("Alert Resolved");
                    })
                    .catch((e) => {
                      dispatch(setIsResolveLoading(false));
                      errorToast(e.response?.data?.err);
                    });
                }}
              >
                <span>
                  <i className="fa fa-check"></i>
                  &nbsp;
                </span>
                {isResolveLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Resolve"
                )}
              </a>
            </div>
          </>
        );
      } else {
        return null;
      }
    }
  };

  const getOperatorFunctions = (data, item) => {
    if (!data) {
      return null;
    } else {
      if (item.statusId === 1 && item.operatorInvolved === user.id) {
        return (
          <div className="d-grid">
            <br />
            <a
              href="#"
              className={`btn btn-success btn-sm ${
                isResolveLoading ? "disabled" : ""
              }`}
              onClick={async (e) => {
                e.preventDefault();
                alertBeepStop();
                dispatch(setIsResolveLoading(true));
                await OperatorAcceptPanic_UpdatePanics(dispatch, {
                  id: item.id,
                });
              }}
            >
              {isResolveLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>
                  <span>
                    <i className="fa fa-check"></i>
                    &nbsp; &nbsp;
                  </span>
                  Accept
                </>
              )}
            </a>
          </div>
        );
      } else if (
        (item.statusId == 2 || item.statusId == 10) &&
        item.operatorInvolved != 0 &&
        item.operatorInvolved != null
      ) {
        return (
          <>
            {!item?.isGSM && item?.alertType !== 2 ? (
              <div>
                <br />
                <div className="d-grid gap-2">
                  <a
                    href="#"
                    title="Relocate alert"
                    className={`btn btn-sm btn-success ${
                      isRelocateLoading ? "disabled" : ""
                    }`}
                    onClick={async (e) => {
                      e.preventDefault();

                      dispatch(setIsRelocateLoading(true));

                      await OperatorRelocatePanic(dispatch, item.id)
                        .then(() => {
                          succesToast("Alert relocated.");
                          dispatch(setIsRelocateLoading(false));
                        })
                        .catch((e) => {
                          dispatch(setIsRelocateLoading(false));
                          errorToast(e.response?.data?.err);
                        });
                    }}
                  >
                    <span>
                      <i className="fa-regular fa-map"></i>
                      &nbsp;&nbsp;&nbsp;
                    </span>
                    {isRelocateLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "Relocate Alert"
                    )}
                  </a>
                </div>
              </div>
            ) : null}

            <br />
            {/* <select
                ref={selectRef}
                style={{
                  zindex: 1212121212,
                }}
                name={"resres"}
                className="form-select form-select-sm"
                value={res}
                onClick={() => {
                  if (selectRef?.current) {
                    selectRef.current.click();
                  }
                }}
                onChange={(e) => setRes(e.target.value)}
              >
                <option value="">Select Responder </option>
                {getRespondersList()}
              </select> */}
            <Select
              value={res}
              onChange={(obj) => setRes(obj)}
              options={getResOptions(item)}
            />
            <br />
            <div className="d-grid gap-2">
              <a
                href="#"
                className={`btn btn-sm btn-success ${
                  isAssignLoading ? "disabled" : ""
                }`}
                onClick={async (e) => {
                  e.preventDefault();
                  if (res === "" || res === undefined || res === null) {
                    errorToast(
                      "Please select a responder from the dropdown List."
                    );
                  } else {
                    dispatch(setIsAssignLoading(true));
                    await OperatorAssignPanicToRes_UpdatePanics(
                      dispatch,
                      item.id,
                      {
                        contact: item.contact,
                        responderInvolved: res?.value,
                      }
                    )
                      .then(() => {
                        dispatch(setIsAssignLoading(false));
                      })
                      .catch((e) => {
                        dispatch(setIsAssignLoading(false));
                        errorToast(e.response?.data?.err);
                      });
                    setTimeout(() => {
                      setRes("");
                    }, 2000);
                  }
                }}
              >
                <span>
                  <i className="fa fa-check"></i>
                  &nbsp;
                </span>
                {isAssignLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Assign to Responder"
                )}
              </a>
            </div>
          </>
        );
      } else if (
        item.statusId === 4 &&
        item.responderInvolved !== 0 &&
        item.responderInvolved !== null
      ) {
        return (
          <>
            <br />
            <div className="d-grid gap-2">
              <input
                type={"text"}
                className="form-control form-control-sm"
                value={resloveRemarks}
                placeholder={"Resolve Remarks"}
                onChange={(e) => setResloveRemarks(e.target.value)}
              />
            </div>

            <br />
            <div className="d-grid gap-2">
              <a
                href="#"
                className={`btn btn-sm btn-success ${
                  isResolveLoading ? "disabled" : ""
                }`}
                onClick={async (e) => {
                  e.preventDefault();
                  alertBeepStop();
                  dispatch(setIsResolveLoading(true));
                  await OperatorResolvePanic_UpdatePanics(dispatch, item.id, {
                    remarks: resloveRemarks,
                  })
                    .then(() => {
                      dispatch(setIsResolveLoading(false));
                      setResloveRemarks(null);
                      SetOpenedChatbox(dispatch, {
                        id: null,
                        name: null,
                      });
                      SetChatbox(dispatch, false);
                      succesToast("Alert Resolved");
                    })
                    .catch((e) => {
                      dispatch(setIsResolveLoading(false));
                      errorToast(e.response?.data?.err);
                    });
                }}
              >
                <span>
                  <i className="fa fa-check"></i>
                  &nbsp;
                </span>
                {isResolveLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Resolve"
                )}
              </a>
            </div>
          </>
        );
      } else if (item.statusId === 5) {
        <>
          <br />
          <div className="d-grid gap-2">
            <a
              href="#"
              className={`btn btn-sm btn-success ${
                isResolveLoading ? "disabled" : ""
              }`}
              onClick={async (e) => {
                e.preventDefault();
                dispatch(setIsResolveLoading(true));
                await OperatorResolvePanic_UpdatePanics(dispatch, item.id)
                  .then(() => {
                    dispatch(setIsResolveLoading(false));
                    succesToast("Alert Resolved");
                  })
                  .catch((e) => {
                    dispatch(setIsResolveLoading(false));
                    errorToast(e.response?.data?.err);
                  });
              }}
            >
              <span>
                <i className="fa fa-check"></i>
                &nbsp;
              </span>
              {isResolveLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              ) : (
                "Resolve"
              )}
            </a>
          </div>
        </>;
      } else {
        return null;
      }
    }
  };

  const getAttachments = (data) => {
    if (data) {
      return (
        <div>
          <div style={{ flexDirection: "row" }}>
            {data.map((item) => {
              if (
                item.mimeType.indexOf("image") != -1 &&
                item.storageType == "AlertAttachment"
              ) {
                return (
                  <div className="col-6">
                    <a
                      href="#"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      data-bs-toggle="modal"
                      data-bs-target={"#showImage"}
                      title="Image View"
                      onClick={() => {
                        dispatch(setImageDetail(URL + "upload/" + item.id));
                      }}
                      // style={{ float: "left" }}
                    >
                      <img
                        alt=""
                        src={URL + "upload/" + item.id}
                        style={{
                          width: 80,
                          // padding: "px",
                          border: "2px solid grey",
                          margin: "0 5px",
                        }}
                      />
                      <p>{item.originalName}</p>
                    </a>
                  </div>
                );
              }
            })}
          </div>

          <div>
            {data.map((item) => {
              if (
                item.mimeType.indexOf("audio") != -1 &&
                item.storageType == "AlertAttachment"
              )
                return (
                  <div className="col-12">
                    <p style={{ float: "right" }}>
                      <audio
                        className="send"
                        controls
                        src={URL + "upload/" + item.id}
                      ></audio>
                    </p>
                    <div style={{ clear: "both" }}></div>
                  </div>
                );
            })}
          </div>
        </div>
      );
    }
  };

  const getSpots = (
    data,
    features,
    pin = "./../../../pin/8.png",
    type = ""
  ) => {
    let icon = pin;
    if (type === "responder") {
      return data.map((p) => {
        return (
          <ResSpot
            focused={focusedMarker === p.id ? true : false}
            status={p.status}
            key={p.id}
            pin={require(`./../../../../assets/pins/${p.iconName}.png`)}
            lat={Number(p.latitude)}
            lng={Number(p.longitude)}
            course={Number(p.course)}
            features={features}
            battery={Number(p.battery) * 100}
            content={
              <div
                className="text-wrap"
                style={{ lineHeight: "12px", zIndex: 15 }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    paddingTop: "5px",
                  }}
                >
                  Responder
                </div>
                <hr />
                <div>
                  <ul className="" style={{ padding: "0px", margin: "0px" }}>
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                    >
                      Name:
                      <span className="">{`${p?.user?.firstname || ""} ${
                        p?.user?.lastname || ""
                      }`}</span>
                    </li>
                    <li
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Responder #:
                      <span className="">{`${p?.user?.contact || ""}`}</span>
                    </li>
                    <li
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Device #:
                      <span className="">{`${p?.contact || ""}`}</span>
                    </li>
                    <li
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Device:
                      <span className="">{p.name || ""}</span>
                    </li>
                    <li
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Battery:
                      <span className="">
                        {Number(p.battery) * 100 > 90 ? (
                          <i
                            style={{
                              fontSize: "18px",
                              color: "green",
                              rotate: "270deg",
                            }}
                            className="fa-solid fa-battery-full"
                          ></i>
                        ) : Number(p.battery) * 100 > 75 ? (
                          <i
                            style={{
                              fontSize: "18px",
                              color: "green",
                              rotate: "270deg",
                            }}
                            className="fa-solid fa-battery-three-quarters"
                          ></i>
                        ) : Number(p.battery) * 100 > 50 ? (
                          <i
                            style={{
                              fontSize: "18px",
                              color: "green",
                              rotate: "270deg",
                            }}
                            className="fa-solid fa-battery-half"
                          ></i>
                        ) : Number(p.battery) * 100 < 15 ? (
                          <i
                            style={{
                              fontSize: "18px",
                              color: "red",
                              rotate: "270deg",
                            }}
                            className="fa-solid fa-battery-empty"
                          ></i>
                        ) : Number(p.battery) * 100 < 25 ? (
                          <i
                            style={{
                              fontSize: "18px",
                              color: "orange",
                              rotate: "270deg",
                            }}
                            className="fa-solid fa-battery-quarter"
                          ></i>
                        ) : Number(p.battery) * 100 < 50 ? (
                          <i
                            style={{
                              fontSize: "18px",
                              color: "orange",
                              rotate: "270deg",
                            }}
                            className="fa-solid fa-battery-half"
                          ></i>
                        ) : null}
                        {(Number(p.battery) * 100)
                          ?.toString()
                          ?.substring(0, 4) + " %" || ""}
                      </span>
                    </li>
                    <li
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Updated:
                      <span className="">
                        {p.timeAgo || (
                          <ReactTimeAgo date={new Date(p.lastUpdate)} />
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
                <div style={{ margin: "5px", textAlign: "end" }}>
                  {p?.user?.userActivation ? (
                    <a
                      href="#"
                      className="nav-link"
                      onClick={() => {
                        // alert("JSON.stringify(p.user)");
                        dispatch(setChatboxVisible(true));
                        dispatch(setOpenedChatbox(p?.user));
                        dispatch(setChatType("free"));
                        if (freeChatBadges.includes(p?.user?.id)) {
                          var badges = [...freeChatBadges];
                          var index = badges.indexOf(p.user?.id);
                          if (index !== -1) {
                            badges.splice(index, 1);
                            dispatch(setFreeChatBadges(badges));
                          }
                        }
                      }}
                    >
                      <i
                        style={{
                          marginTop: "10px",
                          float: "left",
                          fontSize: "28px",
                          color: "#438AA2",
                        }}
                        title="Chatbox"
                        className={"fa fa-comments"}
                      ></i>
                    </a>
                  ) : null}
                  <a
                    href="#"
                    className="nav-link"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#showImage"}
                    title="Avatar ..."
                    onClick={() => {
                      dispatch(
                        setImageDetail(
                          p.user?.avatarFileId
                            ? `${URL}upload/${p.user?.avatarFileId}`
                            : require("./../../../../assets/pins/man.png")
                        )
                      );
                      // setImageLink(`${URL}upload/${msgObj.fileData?.id}`);
                      // alert("to do");
                    }}
                  >
                    <img
                      src={
                        p.user?.avatarFileId
                          ? `${URL}upload/${p.user?.avatarFileId}`
                          : require("./../../../../assets/pins/man.png")
                      }
                      alt="user"
                      style={{ width: "44px", opacity: 1, cursor: "pointer" }}
                    />
                  </a>
                </div>
              </div>
            }
          />
        );
      });
    } else if (type === "type2") {
      return data.map((p) => {
        // console.log("askjdlksalkjdlkasjlkdjaslkdjas", p);
        if (p.isClose == true) {
          return null;
        } else {
          return (
            <Spot
              shade={
                p.statusId === 1
                  ? "red"
                  : p.statusId === 2
                  ? "blue"
                  : p.statusId === 3
                  ? "lightgreen"
                  : "green"
              }
              popWidth={p?.storagefiles?.length > 0 ? 320 : 220}
              focused={focusedMarker === p.id ? true : false}
              buzz={p.statusId === 1 ? true : false}
              key={p.id}
              pin={icon}
              lat={Number(p.lat)}
              lng={Number(p.lng)}
              features={{ ...features, timer: new Date(p.createdAt) }}
              content={
                <>
                  <div style={{ lineHeight: "12px", zIndex: 15 }}>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        paddingTop: "5px",
                        // backgroundColor: "",
                      }}
                    >
                      Responder Alert
                    </div>
                    <hr />
                    <div>
                      <ul
                        className=""
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <li
                          className="list-group-item d-flex justify-content-between align-items-center"
                          style={{
                            height: "20px",
                            fontWeight: "bold",
                            textAlign: "end",
                          }}
                        >
                          Name:
                          <span className="">{`${p.customer?.firstname} ${p.customer?.lastname}`}</span>
                        </li>
                        <li
                          className="list-group-item d-flex justify-content-between align-items-center"
                          style={{
                            height: "20px",
                            fontWeight: "bold",
                            textAlign: "end",
                          }}
                        >
                          Responder #:
                          <span className="">{p.contact}</span>
                        </li>
                        <li
                          style={{
                            height: "20px",
                            fontWeight: "bold",
                            textAlign: "end",
                          }}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Nature:
                          <span className="">
                            {p.natureId == 1
                              ? "Emergency"
                              : p.natureId == 2
                              ? "Medical"
                              : "Fire"}
                          </span>
                        </li>
                        {p.desc ? (
                          <li
                            style={{
                              height: "20px",
                              fontWeight: "bold",
                              textAlign: "end",
                            }}
                            className="list-group-item d-flex justify-content-between align-items-center"
                          >
                            Description:
                            <span className="">{p.desc || ""}</span>
                          </li>
                        ) : null}

                        {user.roleId === 1 ||
                        user.roleId === 2 ||
                        user.roleId === 7 ||
                        user.roleId === 13 ||
                        user.roleId === 15 ? (
                          <li
                            style={{
                              height: "20px",
                              fontWeight: "bold",
                              textAlign: "end",
                            }}
                            className="list-group-item d-flex justify-content-between align-items-center"
                          >
                            Operator:
                            <span className="">
                              {filterOperators(p?.operatorInvolved)
                                ?.firstname || ""}
                            </span>
                          </li>
                        ) : null}

                        {user.roleId !== 1 &&
                        user.roleId !== 13 &&
                        user.roleId !== 15 &&
                        user.roleId !== 7 ? (
                          <>
                            <div>
                              {getSupervisorFunctions(p?.operatorInvolved, p)}
                            </div>
                            <div>
                              {getOperatorFunctions(p?.operatorInvolved, p)}
                            </div>
                          </>
                        ) : null}
                        <br />
                        {user.roleId !== 1 &&
                        user.roleId !== 13 &&
                        user.roleId !== 15 &&
                        user.roleId !== 7 ? (
                          <div className="d-grid gap-2">
                            {p.statusId ? (
                              <a
                                href="#"
                                className={`btn btn-sm btn-danger ${
                                  isCloseLoading ? "disabled" : ""
                                }`}
                                data-bs-toggle="modal"
                                data-bs-target="#remarksModal"
                                onClick={async (e) => {
                                  alertBeepStop();
                                  e.preventDefault();
                                  setPanicId(p.id);
                                }}
                              >
                                <span>
                                  <i className="fa fa-xmark"></i>
                                  &nbsp; &nbsp;
                                </span>
                                {isCloseLoading ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </>
                                ) : (
                                  "Close"
                                )}
                              </a>
                            ) : null}

                            <div className="float-end">
                              {p.statusId >= 2 &&
                              p.operatorInvolved === user.id ? (
                                <a
                                  href="#"
                                  className="nav-link"
                                  onClick={() => {
                                    dispatch(setChatboxVisible(true));
                                    dispatch(setOpenedChatbox(p));
                                    dispatch(setChatType("alert"));
                                    if (alertChatBadges.includes(p.id)) {
                                      var badges = [...alertChatBadges];
                                      var index = badges.indexOf(p.id);
                                      if (index !== -1) {
                                        badges.splice(index, 1);
                                        dispatch(setAlertChatBadges(badges));
                                      }
                                    }
                                  }}
                                >
                                  <i
                                    style={{
                                      marginTop: "10px",
                                      float: "left",
                                      fontSize: "28px",
                                      color: "#438AA2",
                                    }}
                                    title="Chatbox"
                                    className={"fa fa-comments"}
                                  ></i>
                                </a>
                              ) : null}
                              <a
                                href="#"
                                className="nav-link float-end"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                data-bs-toggle="modal"
                                data-bs-target={"#showImage"}
                                title="Avatar ..."
                                onClick={() => {
                                  dispatch(
                                    setImageDetail(
                                      p.customer?.avatarFileId
                                        ? `${URL}upload/${p.customer?.avatarFileId}`
                                        : require("./../../../../assets/pins/man.png")
                                    )
                                  );
                                  // setImageLink(`${URL}upload/${msgObj.fileData?.id}`);
                                  // alert("to do");
                                }}
                              >
                                <img
                                  src={
                                    p.customer?.avatarFileId
                                      ? `${URL}upload/${p.customer?.avatarFileId}`
                                      : require("./../../../../assets/pins/man.png")
                                  }
                                  alt="user"
                                  style={{
                                    width: "44px",
                                    opacity: 1,
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            </div>
                            {p?.storagefiles?.length > 0
                              ? getAttachments(p?.storagefiles)
                              : null}
                          </div>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </>
              }
            />
          );
        }
      });
    } else {
      return data.map((p) => {
        if (p.isClose) {
          return null;
        } else {
          return (
            <Spot
              pData={p}
              key={p.id}
              pin={
                p.isRelocated
                  ? require("../../../../assets/pins/currentloc.png")
                  : pin
              }
              buzz={p.statusId === 1 ? true : false}
              buzzResolve={p.statusId === 4 ? true : false}
              buzzFallback={p.statusId === 10 ? true : false}
              lat={Number(p.lat)}
              lng={Number(p.lng)}
              shade={
                p.statusId === 1
                  ? "red"
                  : p.statusId === 2
                  ? "blue"
                  : p.statusId === 3
                  ? "lightgreen"
                  : "green"
              }
              features={{ ...features, timer: new Date(p.createdAt) }}
              content={
                <div style={{ lineHeight: "14px", zIndex: 15 }}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Alert {p.isGSM ? "Through SMS" : null}
                  </div>
                  <hr />
                  <ul className="" style={{ padding: "0px", margin: "0px" }}>
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                    >
                      Name:
                      <span className="">{`${p.customer?.firstname} ${p.customer?.lastname}`}</span>
                    </li>
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                    >
                      Address:
                      <span className="">
                        {p.isRelocated ? "Current Location" : p.address}
                      </span>
                    </li>
                    <li
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Nature:
                      <span className="">
                        {p.natureId === 1
                          ? "Emergency"
                          : p.natureId === 2
                          ? "Medical"
                          : "Fire"}
                      </span>
                    </li>
                    <li
                      style={{
                        height: "20px",
                        fontWeight: "bold",
                        textAlign: "end",
                      }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Responder:
                      <span className="">
                        {p.responderInvolved
                          ? filterResponders(p.responderInvolved)
                          : "Null"}
                        &nbsp;
                      </span>
                    </li>

                    {user.roleId === 1 || user.roleId === 7 ? (
                      <li
                        style={{
                          height: "20px",
                          fontWeight: "bold",
                          textAlign: "end",
                        }}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        Supervisor:
                        <span className="">
                          {p.supervisorInvolved
                            ? filterSupervisors(p?.supervisorInvolved)
                                ?.firstname
                            : "Null"}
                          &nbsp;
                        </span>
                      </li>
                    ) : null}
                    {user.roleId === 1 ||
                    user.roleId === 2 ||
                    user.roleId === 7 ? (
                      <li
                        style={{
                          height: "20px",
                          fontWeight: "bold",
                          textAlign: "end",
                        }}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        Operator:
                        <span className="">
                          {p.operatorInvolved
                            ? filterOperators(p?.operatorInvolved)?.firstname
                            : "Null"}
                          &nbsp;
                        </span>
                      </li>
                    ) : null}

                    {p.statusId === 3 ? (
                      <>
                        <br />
                        <li
                          style={{
                            height: "20px",
                            fontWeight: "bold",
                            textAlign: "end",
                            color: "orange",
                          }}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Note: Waiting for responder to accept.
                        </li>
                      </>
                    ) : null}
                    {p.statusId === 10 ? (
                      <>
                        <br />
                        <li
                          style={{
                            height: "1px",
                            fontWeight: "bold",
                            textAlign: "end",
                            color: "red",
                          }}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          Note: Fallback from Responder.
                        </li>
                      </>
                    ) : null}

                    {user.roleId !== 1 &&
                    user.roleId !== 13 &&
                    user.roleId !== 15 &&
                    user.roleId !== 7 ? (
                      <>
                        <div>
                          {user.roleId === 2
                            ? getSupervisorFunctions(p.operatorInvolved, p)
                            : null}
                        </div>
                        <div>
                          {user.roleId === 3
                            ? getOperatorFunctions(p.operatorInvolved, p)
                            : null}
                        </div>
                        <br />
                        <div className="d-grid gap-2">
                          {p.statusId ? (
                            <a
                              href="#"
                              className={`btn btn-sm btn-danger ${
                                isCloseLoading ? "disabled" : ""
                              }`}
                              data-bs-toggle="modal"
                              data-bs-target="#remarksModal"
                              onClick={async (e) => {
                                alertBeepStop();
                                e.preventDefault();
                                setPanicId(p.id);
                              }}
                            >
                              <span>
                                <i className="fa fa-xmark"></i>
                                &nbsp; &nbsp;
                              </span>
                              {isCloseLoading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                "Close"
                              )}
                            </a>
                          ) : null}
                          {p.statusId == 8 ? (
                            <button
                              className="btn btn-xs btn-warning btn-block"
                              onClick={async (e) => {
                                e.preventDefault();
                                // await solvePanic(p.id, {
                                //   userId: user.id,
                                // }); // Bilal
                                // init();
                                // Beep2();
                              }}
                            >
                              Pop The Panic Out
                            </button>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </ul>

                  {p.customerRemarks ? (
                    <p>
                      <b> Customer Remarks: </b>
                      <div> {p.customerRemarks} </div>
                    </p>
                  ) : null}
                  <div>{p.isCancelled == 1 ? <h3> Cancelled </h3> : null}</div>
                  {p.statusId >= 2 && p.operatorInvolved === user.id ? (
                    <a
                      href="#"
                      className="nav-link"
                      onClick={() => {
                        dispatch(setChatboxVisible(true));
                        dispatch(setOpenedChatbox(p));
                        dispatch(setChatType("alert"));
                        if (alertChatBadges.includes(p.id)) {
                          var badges = [...alertChatBadges];
                          var index = badges.indexOf(p.id);
                          if (index !== -1) {
                            badges.splice(index, 1);
                            dispatch(setAlertChatBadges(badges));
                          }
                        }
                      }}
                    >
                      <i
                        style={{
                          marginTop: "10px",
                          float: "left",
                          fontSize: "28px",
                          color: "#438AA2",
                        }}
                        title="Chatbox"
                        className={"fa fa-comments"}
                      ></i>
                    </a>
                  ) : null}
                </div>
              }
            />
          );
        }
      });
    }
  };

  const mapStaticMarkers = () => {
    // console.log("statisc markers", staticMarker);
    if (staticMarker?.length > 0) {
      return staticMarker?.map((val, index) => {
        return (
          <React.Fragment key={index}>
            <StaticMarker item={val} />
          </React.Fragment>
        );
      });
    }
  };

  const getOperatorsList = () => {
    return operators
      .map((item, index) => {
        return item.userActivation ? (
          <React.Fragment key={index}>
            <option value={item.id}>
              {item.firstname + " " + item.lastname}
            </option>
          </React.Fragment>
        ) : null;
      })
      .filter((notUndefined) => notUndefined !== undefined);
  };

  const getRespondersList = () => {
    return list.map((item, index) => {
      if (item.userActivation && !item.userAssignment) {
        return {
          label: (
            <div className="row">
              <div className="col-12">
                <img
                  src={require("../../../../assets/pins/guard.png")}
                  style={{ width: "32px", marginRight: "10px" }}
                  alt=""
                />
                <span>Muhammad Sarmad Saeed</span>
              </div>
            </div>
          ),
          value: item.id,
        };
      }
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id={"remarksModal"}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#121624" }}
            >
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "22px" }}
                  className={"fa fa-microphone"}
                ></i>
                <span> &nbsp; </span>
                Closing Remarks
              </h5>

              <i
                ref={closeRef}
                type="button"
                // className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ fontSize: "24px", color: "#438AA2" }}
                className={"fa fa-close"}
              ></i>
            </div>
            <div
              className="modal-body"
              style={{ padding: "0px", margin: "0px" }}
            >
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <MyTextInput
                    name={"Closing Remarks"}
                    val={remarks}
                    placeholder={""}
                    setVal={setRemarks}
                  />
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <div className="text-center">
                  <a
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      await closePanic();
                      setResloveRemarks(null);
                    }}
                    className={`btn btn-primary ${
                      isCloseLoading ? "disabled" : ""
                    }`}
                  >
                    {isCloseLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "Submit"
                    )}
                  </a>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {mapType ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            height: "91vh",
            border: "4px solid #438AA2",
            // margin: "2px",
          }}
        >
          <Map
            // {...viewport}
            ref={mapRef}
            initialViewState={viewport}
            mapboxAccessToken={KEY}
            mapStyle={mapType}
            {...viewport}
            onMove={(evt) => {
              setViewport(evt.viewState);
              if (
                parseInt(evt.viewState.zoom) > 0 &&
                parseInt(evt.viewState.zoom) <= 18
              ) {
                dispatch(setZoom(parseInt(evt.viewState.zoom)));
              }
            }}

            // onLoad={(e) => {
            //   setPaintProperty("building", "fill-color", "red");
            // }}
          >
            {getSpots(
              devices,
              {
                shade: "lightgreen",
                spot: "darkgreen",
                showSpot: true,
                buzz: false,
              },
              require("./../../../../assets/pins/bike.png"),
              "responder"
            )}
            {getSpots(
              cusAlerts,
              {
                shade: "red",
                spot: "green",
                showSpot: false,
                buzz: true,
                showTimer: true,
              },
              require("./../../../../assets/pins/13.png")
            )}
            {getSpots(
              type2Alerts,
              {
                shade: "red",
                spot: "green",
                showSpot: false,
                buzz: true,
                showTimer: true,
              },
              require("./../../../../assets/pins/guard.png"),
              "type2"
            )}
            {showStaticMarkerLayer ? mapStaticMarkers() : null}
          </Map>
        </div>
      ) : null}
    </>
  );
};

export default MapGl;
