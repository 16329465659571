import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slices/alertSlice";
import chatSlice from "./slices/chatSlice";
import logsSlice from "./slices/logsSlice";
import mapSlice from "./slices/mapSlice";
import reportsSlice from "./slices/reportsSlice";
import themeSlice from "./slices/themeSlice";
import userSlice from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    theme: themeSlice,
    user: userSlice,
    map: mapSlice,
    chat: chatSlice,
    alert: alertSlice,
    logs: logsSlice,
    report: reportsSlice,
  },
});
