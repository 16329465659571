import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SendLinkAPI,
  SendResetLink,
  UpdateUserInfo,
  UpdateUsers,
  UserLogout,
} from "../../actions";
import MyTextInput from "../../components/MyTextInput";
import { errorToast } from "../../helpers/toasts";
import { IsEmpty } from "../../helpers/utils";
import { setIsLoading, setUser } from "../../redux/slices/userSlice";

const SendLink = (props) => {
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const goto = async () => {
    if (IsEmpty(email) && IsEmpty(contact)) {
      errorToast("Either email or Contact is required");
      return;
    }

    dispatch(setIsLoading(true));
    await SendResetLink(dispatch, {
      email,
      contact: contact && "+92" + contact,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        Empty();
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  const Empty = () => {
    setEmail("");
    setContact("");
  };

  useEffect(() => {
    if (user) {
      errorToast("User already logged in.");
      navigate("/");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords) {
        // console.log("posss", position.coords);
        // props.getCenter([
        //   {
        //     latitude: Number(position.coords.latitude),
        //     longitude: Number(position.coords.longitude),
        //   },
        // ]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-4"></div>
          <div className="card col-4" style={{ marginTop: "60px" }}>
            <div className="card-body">
              {/* <center> */}
              {/* <img
                  src={require("./logo2.png")}
                  width="120"
                  height="120"
                  alt=""
                  title={"Emergency Alert & Response System"}
                /> */}
              <h4>Enter Email or Cell Phone</h4>
              <div>
                A password reset link will be sent to the provided information.
              </div>
              {/* </center> */}
              <hr />
              <div>
                <MyTextInput name={"Email"} val={email} setVal={setEmail} />
              </div>
              <center>
                <span
                  style={{
                    textDecoration: "underline",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  or
                </span>
              </center>
              <div className="input-form">
                <MyTextInput
                  name={"Cell Phone #"}
                  val={contact}
                  placeholder={"3xxxxxxxxxx"}
                  setVal={setContact}
                  max={10}
                  pre={true}
                />
              </div>

              <div>
                <br />
                <br />
                <button
                  style={{ flex: 1, width: "100%" }}
                  title="Login"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsLoading(true);
                    goto();
                  }}
                >
                  {isLoading ? "Please Wait..." : "Send Link"}
                </button>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(props.user, null, 2)}</pre> */}
    </>
  );
};

export default SendLink;
