import { Buffer } from "buffer";

let apiUrl = `https://${window.location.host}${process.env.REACT_APP_API_PATH}/`;
let baseUrl = `https://${window.location.host}${
  process.env.REACT_COMPLAINT_PORTAL_PATH || "/complaint"
}/`;
let mqttUrl = `wss://${window.location.host}${process.env.REACT_APP_MQTT_PATH}/`;

if (window.location.host.indexOf("localhost") === 0) {
  // apiUrl = "http://localhost:3004/";
  apiUrl = "https://dev.ears.pk/api/";
  mqttUrl = "wss://dev.ears.pk/mqtt/";
}

console.log("CONFIG BASE_URL", apiUrl);
console.log("CONFIG MQTT_URL", mqttUrl);

export const URL = apiUrl;
export const MQTT_URL = mqttUrl;
export const BASE_URL = baseUrl;

export const JWT_PUBKEY = Buffer.from(
  process.env.REACT_APP_JWT_PUBKEY,
  "base64"
);
