/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import IconContRound from "../../../../components/IconsContRound";
import {
  setAlertChatBadges,
  setChatboxVisible,
  setChatType,
  setFreeChatBadges,
  setOpenedChatbox,
} from "../../../../redux/slices/chatSlice";
import FreeMsg from "./Chatbox/FreeMsg";
import FreeMsgRight from "./Chatbox/FreeMsgRight";
import Msg from "./Chatbox/Msg";
import MsgRight from "./Chatbox/MsgRight";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { Howl, Howler } from "howler";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  sendMsgAlertChat,
  sendMsgFreeChat,
  sendReadMsgAckAlertChat,
  sendReadMsgAckFreeChat,
} from "../../../../Mqtt/Mqtt";
import { URL } from "../../../../config";
import {
  AddNewAlertChatMsg,
  AddNewFreeChatMsg,
  FetchAlertChat,
  FetchFreeChat,
  UpdateAlertChatMsg,
  UpdateFreeChatMsg,
} from "../../../../actions";
import { errorToast } from "../../../../helpers/toasts";
import { setMarkerLocation } from "../../../../redux/slices/mapSlice";
import { setImageDetail } from "../../../../redux/slices/userSlice";

const ChatboxOffCanvas = (props) => {
  const openedChatbox = useSelector((state) => state.chat.openedChatbox);
  const chatBoxVisible = useSelector((state) => state.chat.chatBoxVisible);
  const freeChatMsgs = useSelector((state) => state.chat.freeChatMsgs);
  const freeChatBadges = useSelector((state) => state.chat.freeChatBadges);
  const alertChatBadges = useSelector((state) => state.chat.alertChatBadges);
  const alertChatMsgs = useSelector((state) => state.chat.alertChatMsgs);
  const chatType = useSelector((state) => state.chat.chatType);

  const type2Alerts = useSelector((state) => state.alert.type2Alerts);
  const cusAlerts = useSelector((state) => state.alert.cusAlerts);

  const responders = useSelector((state) => state.user.responders);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const [opacity, setOpacity] = useState(8.5);
  const [chatMessage, setChatMessage] = useState("");
  const [chatAck, setChatAck] = useState(null);
  const [msgs, setMsgs] = useState([]);
  const [panic, setPanic] = useState(null);
  const [panicIdHook, setPanicIdHook] = useState(null);
  const [assignedPanics, setAssignedPanics] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [prev, setPrev] = useState(0);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const dropper = useRef(null);
  const [dTime, setdTime] = useState(null);
  const [inter, setInter] = useState(null);
  const [dataa, setDataa] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [doUpload, setDoUpload] = useState(null);

  let chatti = useRef(null);
  let loop = useRef(null);
  const fileUpload = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [freeChatMsgs, openedChatbox, alertChatMsgs]);

  useEffect(() => {
    if (openedChatbox.id) {
      sendReadAckFreeChat();
      sendReadAckAlertChat();
    }
  }, [openedChatbox]);

  useEffect(() => {
    FetchFreeChat(dispatch);
    FetchAlertChat(dispatch);
  }, []);

  const sendReadAckFreeChat = async () => {
    freeChatMsgs.forEach(async (msg) => {
      const newMsg = { ...msg };
      if (
        msg.msgType === "r" &&
        msg.fromId === openedChatbox?.id &&
        msg.ackSent !== true &&
        msg.oprReadState !== 2
      ) {
        // console.log("sending ACKKKKKKK  >>>>", msg, openedChatbox.id);
        sendReadMsgAckFreeChat(msg, user);

        newMsg.ackSent = true;
        await UpdateFreeChatMsg(dispatch, newMsg, freeChatMsgs);
      }
    });
  };

  const sendReadAckAlertChat = async () => {
    alertChatMsgs.forEach(async (msg) => {
      // console.log("sending ACKKKKKKK  >>>> 1111", msg, openedChatbox.id);
      const newMsg = { ...msg };

      if (
        msg.msgType === "r" &&
        msg.panicId === openedChatbox?.id &&
        msg.ackSent !== true &&
        msg.oprReadState !== 2
      ) {
        sendReadMsgAckAlertChat(msg, user);

        newMsg.ackSent = true;
        await UpdateAlertChatMsg(dispatch, newMsg, alertChatMsgs);
      }
    });
  };

  const formatTime = (t) => {
    let date = new Date(t);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const uploadAudio = async (d) => {
    var FormData = require("form-data");
    let date = new Date();
    const fileName =
      "Ears_" + Math.floor(date.getTime() + date.getSeconds() / 2);
    var data = new FormData();
    data.append("file", d.blob, fileName);

    var config = {
      method: "post",
      url: URL + "upload",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        // ...data.getHeaders(),
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        // console.error(JSON.stringify(response.data));
        setDoUpload(false);
        const audioObj = { ...response.data };
        audioObj.duration = dTime;
        if (chatType === "free") {
          sendFreeChatMsg("New Audio Msg.", "audio", audioObj);
        } else {
          sendAlertChatMsg("New Audio Msg.", "audio", audioObj);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const duration = async () => {
    let num = 1;
    let interval = setInterval(() => {
      setdTime(convertHMS(num));
      num++;
    }, 1000);
    setInter(interval);
  };

  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds; // Return is HH : MM : SS
  }

  const freeChatMsgsFun = (user) => {
    return freeChatMsgs.map((msgObj, index) => {
      if (user.id == msgObj.toId || user.id == msgObj.fromId) {
        return (
          <React.Fragment key={msgObj.uuid}>
            {msgObj.msgType === "s" ? (
              msgObj.file ? (
                msgObj.file === "img" ? (
                  <>
                    <div
                      style={{
                        background: "#D9FDD3",
                        color: "#111B21",
                        display: "block",
                        // flexDirection: "row",
                        marginTop: "5px",
                        padding: "10px",
                        borderRadius: "8px",
                        marginLeft: "50px",
                        marginRight: "5px",
                        float: "right",
                        fontSize: "14px",
                        boxShadow: "1px 2px 9px grey",
                      }}
                    >
                      <a
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        data-bs-toggle="modal"
                        data-bs-target={"#showImage"}
                        title="Image ..."
                        onClick={() => {
                          dispatch(
                            setImageDetail(
                              `${URL}upload/${msgObj.fileData?.id}`
                            )
                          );
                        }}
                      >
                        <img
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          alt=""
                          style={{
                            width: 140,
                            height: 200,
                            padding: "2px",
                            margin: "0 10px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "8px",
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                        <div
                          style={{
                            // marginLeft: "8px",
                            marginTop: "2px",
                            alignSelf: "flex-end",
                            // fontSize: "12px",
                            // color: "grey",
                          }}
                        >
                          {msgObj.msgStage === 1 ? (
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-circle-exclamation"
                                title="Please check Internet connection"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "red",
                                }}
                              ></i>
                            </div>
                          ) : msgObj.msgStage === 2 ? (
                            <>
                              <div style={{ paddingLeft: "4px" }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    paddingLeft: "5px !important",
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                ></i>
                              </div>
                            </>
                          ) : msgObj.msgStage > 2 ? (
                            msgObj.resReadState === 1 ? (
                              <img
                                src={require("../../../../assets/pins/doubleTick.png")}
                                alt=""
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/tickgreen.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : null
                          ) : null}
                        </div>
                      </div>

                      <div style={{ clear: "both" }}></div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : msgObj.file === "audio" ? (
                  <>
                    <div
                      style={{
                        marginTop: "5px",
                        width: "300px",
                        marginRight: "5px",
                        // height: "46px",
                        backgroundColor: "#d9fdd3",
                        borderRadius: "10px",
                        boxShadow: "1px 1px 9px grey",
                        float: "right",
                      }}
                    >
                      <div
                        style={{
                          paddingBottom: -6,
                          borderRadius: 25,
                        }}
                      >
                        <audio
                          controls
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          className="send"
                        ></audio>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                          marginRight: 10,
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "8px",
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                        <div
                          style={{
                            // marginLeft: "8px",
                            marginTop: "2px",
                            alignSelf: "flex-end",
                            // fontSize: "12px",
                            // color: "grey",
                          }}
                        >
                          {msgObj.msgStage === 1 ? (
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-circle-exclamation"
                                title="Please check Internet connection"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "red",
                                }}
                              ></i>
                            </div>
                          ) : msgObj.msgStage === 2 ? (
                            <>
                              <div style={{ paddingLeft: "4px" }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    paddingLeft: "5px !important",
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                ></i>
                              </div>
                            </>
                          ) : msgObj.msgStage > 2 ? (
                            msgObj.resReadState === 1 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/tickgreen.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : null
              ) : (
                <FreeMsgRight direction="right" text={msgObj} />
              )
            ) : msgObj.msgType === "r" ? (
              msgObj.file ? (
                msgObj.file === "img" ? (
                  <>
                    <div
                      style={{
                        background: "#FFFFFF",
                        color: "black",
                        display: "block",
                        // flexDirection: "row",
                        marginTop: "5px",
                        padding: "5px",
                        borderRadius: "8px",
                        marginLeft: "5px",
                        marginRight: "50px",
                        fontSize: "14px",
                        boxShadow: "1px 2px 9px grey",
                        float: "left",
                      }}
                    >
                      <div
                        style={{
                          color:
                            msgObj.senderRole === "Responder"
                              ? "brown"
                              : "orange",
                          fontSize: "12px",
                        }}
                      >
                        {msgObj.senderRole}
                      </div>
                      <a
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        data-bs-toggle="modal"
                        data-bs-target={"#showImage"}
                        title="Image ..."
                        onClick={() => {
                          dispatch(
                            setImageDetail(
                              `${URL}upload/${msgObj.fileData?.id}`
                            )
                          );
                        }}
                      >
                        <img
                          alt=""
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          style={{
                            width: 140,
                            height: 200,
                            padding: "2px",
                            margin: "5px, 0",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          textAlign: "right",
                          alignSelf: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "8px",
                            marginTop: "5px",
                            fontSize: "11px",
                            color: "grey",
                            textAlign: "right",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </>
                ) : msgObj.file === "location" ? (
                  <>
                    <div
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        width: "120px",
                        // height: "46px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "1px 1px 9px grey",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: 6,
                          color:
                            msgObj.senderRole === "Responder"
                              ? "brown"
                              : "orange",
                          fontSize: "12px",
                        }}
                      >
                        {msgObj.senderRole}
                      </div>
                      <div style={{ padding: "0px 10px" }}>
                        <i
                          style={{ fontSize: "22px", color: "grey" }}
                          className="fa-solid fa-map"
                        ></i>{" "}
                        Location
                      </div>
                      <div
                        className="d-grid gap-2"
                        style={{ padding: "0px 10px", marginTop: "6px" }}
                      >
                        <a href="#" className={`btn btn-sm btn-success`}>
                          View
                        </a>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 12,
                        }}
                      >
                        <div
                          style={{
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : msgObj.file === "audio" ? (
                  <>
                    <div
                      style={{
                        marginTop: "5px",
                        width: "300px",
                        // height: "46px",
                        backgroundColor: "white",
                        marginLeft: "5px",
                        borderRadius: "10px",
                        boxShadow: "1px 1px 9px grey",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: 6,
                          color:
                            msgObj.senderRole === "Responder"
                              ? "brown"
                              : "orange",
                          fontSize: "12px",
                        }}
                      >
                        {msgObj.senderRole}
                      </div>
                      <div
                        style={{
                          paddingBottom: -6,
                          borderRadius: 25,
                        }}
                      >
                        <audio
                          controls
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          className="receive"
                        ></audio>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 12,
                        }}
                      >
                        <div
                          style={{
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : null
              ) : (
                <FreeMsg direction="left" text={msgObj} />
              )
            ) : null}
          </React.Fragment>
        );
      }
    });
  };

  const alertChatMsgsList = (user) => {
    return alertChatMsgs.map((msgObj, index) => {
      if (openedChatbox.id == msgObj.panicId) {
        return (
          <React.Fragment key={msgObj.uuid}>
            {msgObj.msgType === "s" ? (
              msgObj.file ? (
                msgObj.file === "img" ? (
                  <>
                    <div
                      style={{
                        background: "#D9FDD3",
                        color: "#111B21",
                        display: "block",
                        // flexDirection: "row",
                        marginTop: "5px",
                        padding: "10px",
                        borderRadius: "8px",
                        marginLeft: "50px",
                        marginRight: "5px",
                        float: "right",
                        fontSize: "14px",
                        boxShadow: "1px 2px 9px grey",
                      }}
                    >
                      <a
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        data-bs-toggle="modal"
                        data-bs-target={"#showImage"}
                        title="Image ..."
                        onClick={() => {
                          dispatch(
                            setImageDetail(
                              `${URL}upload/${msgObj.fileData?.id}`
                            )
                          );
                        }}
                      >
                        <img
                          alt=""
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          style={{
                            width: 140,
                            height: 200,
                            padding: "2px",
                            margin: "0 10px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "8px",
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                        <div
                          style={{
                            // marginLeft: "8px",
                            marginTop: "2px",
                            alignSelf: "flex-end",
                            // fontSize: "12px",
                            // color: "grey",
                          }}
                        >
                          {msgObj.msgStage === 1 ? (
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-circle-exclamation"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "red",
                                }}
                              ></i>
                            </div>
                          ) : msgObj.msgStage === 2 ? (
                            <>
                              <div style={{ paddingLeft: "4px" }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    paddingLeft: "5px !important",
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                ></i>
                              </div>
                            </>
                          ) : msgObj.msgStage > 2 ? (
                            msgObj.resReadState === 1 &&
                            msgObj.cusReadState === 1 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 &&
                              msgObj.cusReadState === 1 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 1 &&
                              msgObj.cusReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 &&
                              msgObj.cusReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/tickgreen.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : (
                              <>
                                <div style={{ paddingLeft: "4px" }}>
                                  <i
                                    className="fa fa-check"
                                    style={{
                                      paddingLeft: "5px !important",
                                      fontSize: "14px",
                                      color: "grey",
                                    }}
                                  ></i>
                                </div>
                              </>
                            )
                          ) : null}

                          {/* {msgObj.msgStage === 1 ? (
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-circle-exclamation"
                                title="Please check Internet connection"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "red",
                                }}
                              ></i>
                            </div>
                          ) : msgObj.msgStage === 2 ? (
                            <>
                              <div style={{ paddingLeft: "4px" }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    paddingLeft: "5px !important",
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                ></i>
                              </div>
                            </>
                          ) : msgObj.msgStage > 2 ? (
                            msgObj.resReadState === 1 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/tickgreen.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : null
                          ) : null} */}
                        </div>
                      </div>

                      <div style={{ clear: "both" }}></div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : msgObj.file === "location" ? (
                  <>
                    <div
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        width: "120px",
                        float: "right",
                        // height: "46px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "1px 1px 9px grey",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: 6,
                          color:
                            msgObj.senderRole === "Responder"
                              ? "brown"
                              : "orange",
                          fontSize: "12px",
                        }}
                      >
                        {msgObj.senderRole}
                      </div>
                      <div style={{ padding: "0px 10px" }}>
                        <i
                          style={{ fontSize: "22px", color: "grey" }}
                          className="fa-solid fa-map"
                        ></i>{" "}
                        Location
                      </div>
                      <div
                        className="d-grid gap-2"
                        style={{ padding: "0px 10px", marginTop: "6px" }}
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#viewMarkerOnMap"
                          className={`btn btn-sm btn-success`}
                          onClick={() => {
                            dispatch(setMarkerLocation(msgObj.fileData));
                          }}
                        >
                          View
                        </a>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 12,
                        }}
                      >
                        <div
                          style={{
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : msgObj.file === "audio" ? (
                  <>
                    <div
                      style={{
                        marginTop: "5px",
                        marginRight: "5px",
                        width: "300px",
                        // height: "46px",
                        backgroundColor: "#d9fdd3",
                        borderRadius: "10px",
                        boxShadow: "1px 1px 9px grey",
                        float: "right",
                      }}
                    >
                      <div
                        style={{
                          paddingBottom: -6,
                          borderRadius: 25,
                        }}
                      >
                        <audio
                          controls
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          className="send"
                        ></audio>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                          marginRight: 10,
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "8px",
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                        <div
                          style={{
                            // marginLeft: "8px",
                            marginTop: "2px",
                            alignSelf: "flex-end",
                            // fontSize: "12px",
                            // color: "grey",
                          }}
                        >
                          {msgObj.msgStage === 1 ? (
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-circle-exclamation"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "red",
                                }}
                              ></i>
                            </div>
                          ) : msgObj.msgStage === 2 ? (
                            <>
                              <div style={{ paddingLeft: "4px" }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    paddingLeft: "5px !important",
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                ></i>
                              </div>
                            </>
                          ) : msgObj.msgStage > 2 ? (
                            msgObj.resReadState === 1 &&
                            msgObj.cusReadState === 1 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 &&
                              msgObj.cusReadState === 1 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 1 &&
                              msgObj.cusReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 &&
                              msgObj.cusReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/tickgreen.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : (
                              <>
                                <div style={{ paddingLeft: "4px" }}>
                                  <i
                                    className="fa fa-check"
                                    style={{
                                      paddingLeft: "5px !important",
                                      fontSize: "14px",
                                      color: "grey",
                                    }}
                                  ></i>
                                </div>
                              </>
                            )
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : null
              ) : (
                <MsgRight direction="right" text={msgObj} />
              )
            ) : msgObj.msgType === "r" ? (
              msgObj.file ? (
                msgObj.file === "img" ? (
                  <>
                    <div
                      style={{
                        background: "#FFFFFF",
                        color: "black",
                        display: "block",
                        // flexDirection: "row",
                        marginTop: "5px",
                        padding: "5px",
                        borderRadius: "8px",
                        marginRight: "50px",
                        marginLeft: "5px",
                        fontSize: "14px",
                        boxShadow: "1px 2px 9px grey",
                        float: "left",
                      }}
                    >
                      <div
                        style={{
                          color:
                            msgObj.senderRole === "Responder"
                              ? "brown"
                              : "orange",
                          fontSize: "12px",
                        }}
                      >
                        {msgObj.senderRole}
                      </div>
                      <a
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        data-bs-toggle="modal"
                        data-bs-target={"#showImage"}
                        title="Image ..."
                        onClick={() => {
                          dispatch(
                            setImageDetail(
                              `${URL}upload/${msgObj.fileData?.id}`
                            )
                          );
                        }}
                      >
                        <img
                          alt=""
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          style={{
                            width: 140,
                            height: 200,
                            padding: "2px",
                            margin: "5px, 0",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          textAlign: "right",
                          alignSelf: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "8px",
                            marginTop: "5px",
                            fontSize: "11px",
                            color: "grey",
                            textAlign: "right",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </>
                ) : msgObj.file === "location" ? (
                  <>
                    <div
                      style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        width: "120px",
                        // height: "46px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "1px 1px 9px grey",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: 6,
                          color:
                            msgObj.senderRole === "Responder"
                              ? "brown"
                              : "orange",
                          fontSize: "12px",
                        }}
                      >
                        {msgObj.senderRole}
                      </div>
                      <div style={{ padding: "0px 10px" }}>
                        <i
                          style={{ fontSize: "22px", color: "grey" }}
                          className="fa-solid fa-map"
                        ></i>{" "}
                        Location
                      </div>
                      <div
                        className="d-grid gap-2"
                        style={{ padding: "0px 10px", marginTop: "6px" }}
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#viewMarkerOnMap"
                          className={`btn btn-sm btn-success`}
                          onClick={() => {
                            dispatch(setMarkerLocation(msgObj.fileData));
                          }}
                        >
                          View
                        </a>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 12,
                        }}
                      >
                        <div
                          style={{
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : msgObj.file === "audio" ? (
                  <>
                    <div
                      style={{
                        marginTop: "5px",
                        width: "300px",
                        marginLeft: "5px",
                        // height: "46px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "1px 1px 9px grey",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: 6,
                          color:
                            msgObj.senderRole === "Responder"
                              ? "brown"
                              : "orange",
                          fontSize: "12px",
                        }}
                      >
                        {msgObj.senderRole}
                      </div>
                      <div
                        style={{
                          paddingBottom: -6,
                          borderRadius: 25,
                        }}
                      >
                        <audio
                          controls
                          src={`${URL}upload/${msgObj.fileData?.id}`}
                          className="receive"
                        ></audio>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 12,
                        }}
                      >
                        <div
                          style={{
                            marginTop: "1px",
                            fontSize: "11px",
                            color: "grey",
                            alignSelf: "flex-end",
                          }}
                        >
                          {formatTime(msgObj.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </>
                ) : null
              ) : (
                <Msg direction="left" text={msgObj} />
              )
            ) : null}
          </React.Fragment>
        );
      }
    });
  };

  const getType2AlertList = () => {
    return type2Alerts.map((val, index) => {
      if (
        val.statusId >= 2 &&
        (val.operatorInvolved === user.id || val.supervisorInvolved === user.id)
      ) {
        return (
          <a
            href="#"
            className="nav-link"
            onClick={() => {
              dispatch(setOpenedChatbox(val));
              dispatch(setChatType("alert"));
              if (alertChatBadges.includes(val.id)) {
                var badges = [...alertChatBadges];
                var index = badges.indexOf(val.id);
                if (index !== -1) {
                  badges.splice(index, 1);
                  dispatch(setAlertChatBadges(badges));
                }
              }
            }}
          >
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              style={{ backgroundColor: "#EFEAE2" }}
            >
              <div style={{ fontSize: "16px", backgroundColor: "#EFEAE2" }}>
                <img
                  src={require("./../../../../assets/pins/alert.png")}
                  alt=""
                  style={{
                    width: "42px",
                    padding: "2px",
                    marginRight: "6px",
                  }}
                />
                <span>{`Alert (${val.id})`}</span>
              </div>
              <span>
                {alertChatBadges.includes(val.id) ? (
                  <i
                    type="button"
                    style={{
                      fontSize: "12px",
                      marginRight: "10px",
                      color: "red",
                    }}
                    className={"fa fa-circle"}
                  ></i>
                ) : null}

                <i
                  style={{
                    fontSize: "14px",
                    //   marginRight: "10px",
                    color: "#438AA2",
                  }}
                  className={"fa fa-chevron-right"}
                ></i>
              </span>
            </li>
          </a>
        );
      } else {
        return null;
      }
    });
  };
  const getCusAlertList = () => {
    return cusAlerts.map((val, index) => {
      if (
        val.statusId >= 2 &&
        (val.operatorInvolved === user.id || val.supervisorInvolved === user.id)
      ) {
        return (
          <a
            href="#"
            className="nav-link"
            onClick={() => {
              dispatch(setOpenedChatbox(val));
              dispatch(setChatType("alert"));
              if (alertChatBadges.includes(val.id)) {
                var badges = [...alertChatBadges];
                var index = badges.indexOf(val.id);
                if (index !== -1) {
                  badges.splice(index, 1);
                  dispatch(setAlertChatBadges(badges));
                }
              }
            }}
          >
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              style={{ backgroundColor: "#EFEAE2" }}
            >
              <div style={{ fontSize: "16px", backgroundColor: "#EFEAE2" }}>
                <img
                  src={require("./../../../../assets/pins/alert.png")}
                  alt=""
                  style={{
                    float: "left",
                    width: "42px",
                    padding: "2px",
                    marginRight: "6px",
                  }}
                />

                <span> {val.address} </span>
              </div>
              <span>
                {alertChatBadges.includes(val.id) ? (
                  <i
                    type="button"
                    style={{
                      fontSize: "12px",
                      marginRight: "10px",
                      color: "red",
                    }}
                    className={"fa fa-circle"}
                  ></i>
                ) : null}

                <i
                  style={{
                    fontSize: "14px",
                    //   marginRight: "10px",
                    color: "#438AA2",
                  }}
                  className={"fa fa-chevron-right"}
                ></i>
              </span>
            </li>
          </a>
        );
      } else {
        return null;
      }
    });
  };

  const getRespondersList = () => {
    return responders.map((val, index) => {
      if (val.userActivation) {
        return (
          <a
            href="#"
            className="nav-link"
            onClick={() => {
              dispatch(setOpenedChatbox(val));
              dispatch(setChatType("free"));
              if (freeChatBadges.includes(val.id)) {
                var badges = [...freeChatBadges];
                var index = badges.indexOf(val.id);
                if (index !== -1) {
                  badges.splice(index, 1);
                  dispatch(setFreeChatBadges(badges));
                }
              }
            }}
          >
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              style={{ backgroundColor: "#EFEAE2" }}
            >
              <span style={{ fontSize: "16px", backgroundColor: "#EFEAE2" }}>
                <img
                  // style={{
                  //   width: "42px",
                  //   padding: "2px",
                  //   marginRight: "6px",
                  // }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    border: "2px solid white",
                    // padding: "2px",
                    marginRight: "10px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  src={
                    val?.avatarFileId
                      ? `${URL}upload/${val?.avatarFileId}`
                      : require("./../../../../assets/pins/man.png")
                  }
                  alt="user"
                  // style={{ width: "44px", opacity: 1 }}
                />
                {/* <img
                  src={require("./../../../../assets/pins/man.png")}
                  alt=""
                  style={{
                    width: "42px",
                    padding: "2px",
                    marginRight: "6px",
                  }}
                /> */}
                {`${val.firstname} ${val.lastname}`}
              </span>
              <span>
                {freeChatBadges.includes(val.id) ? (
                  <i
                    type="button"
                    style={{
                      fontSize: "12px",
                      marginRight: "10px",
                      color: "red",
                    }}
                    className={"fa fa-circle"}
                  ></i>
                ) : null}

                <i
                  style={{
                    fontSize: "14px",
                    //   marginRight: "10px",
                    color: "#438AA2",
                  }}
                  className={"fa fa-chevron-right"}
                ></i>
              </span>
            </li>
          </a>
        );
      }
    });
  };

  const onStop = (audioData) => {
    // console.error("audioData >>> ", audioData);
    if (inter) {
      clearInterval(inter);
    }
    setDataa(audioData);
    if (doUpload) {
      uploadAudio(audioData);
    }
  };

  const sendFreeChatMsg = async (chatMsg, file = null, fileData = null) => {
    if (chatMsg === "" || chatMsg === null || chatMsg === undefined) {
      return;
    }
    if (
      openedChatbox === "" ||
      openedChatbox === null ||
      openedChatbox === undefined
    ) {
      return;
    }
    const msg = {
      fromId: user.id,
      toId: openedChatbox.id,
      msg: chatMsg,
      uuid: uuidv4(),
      msgStage: 1,
      cusReadState: 0,
      resReadState: 0,
      serviceId: 1,
      msgType: "s",
      file,
      chatType: "free",
      roleId: user.roleId,
      fileData,
      senderRole: "Operator",
      createdAt: Date.now(),
    };
    // console.log("uuuu", msg);
    await AddNewFreeChatMsg(dispatch, msg, freeChatMsgs);
    // sendMsgFreeChat(msg);
    sendMsgFreeChat(msg);
    setChatMessage("");
    scrollToBottom();
  };

  const sendAlertChatMsg = async (chatMsg, file = null, fileData = null) => {
    if (chatMsg === "" || chatMsg === null || chatMsg === undefined) {
      return;
    }
    if (
      openedChatbox === "" ||
      openedChatbox === null ||
      openedChatbox === undefined
    ) {
      return;
    }
    const msg = {
      fromId: user.id,
      // toId: openedChatbox.id,
      msg: chatMsg,
      uuid: uuidv4(),
      msgStage: 1,
      cusReadState: 0,
      resReadState: 0,
      serviceId: 1,
      msgType: "s",
      file,
      chatType: "alert",
      roleId: user.roleId,
      fileData,
      senderRole: "Operator",
      createdAt: Date.now(),
      panicId: openedChatbox.id,
    };
    await AddNewAlertChatMsg(dispatch, msg, alertChatMsgs);
    sendMsgAlertChat(msg);
    setChatMessage("");
    scrollToBottom();
  };

  const scrollToBottom = () => {
    if (chatti.current) {
      chatti.current.scrollIntoView(false);
    }
  };

  const uploadFile = async (file) => {
    setFile(file);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file);
    data.append("storageType", "AlertChat");

    var config = {
      method: "post",
      url: URL + "upload",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (chatType === "free") {
          sendFreeChatMsg("New Image File.", "img", response.data);
        } else {
          sendAlertChatMsg("New Image File.", "img", response.data);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
    // console.error("msg >>>>> ", msgs);
  };

  return (
    <>
      <div
        className="modal fade"
        id={"recView"}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#121624" }}
            >
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "22px" }}
                  className={"fa fa-microphone"}
                ></i>
                <span> &nbsp; </span>
                Recording
                <span> &nbsp; </span>
                <span> &nbsp; </span>
                {dTime ? dTime : "00:00"}
              </h5>

              <i
                type="button"
                // className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ fontSize: "24px", color: "#438AA2" }}
                className={"fa fa-close"}
                onClick={() => {
                  setDataa(RecordState.STOP);
                  setDoUpload(false);
                  Howler.stop();
                }}
              ></i>
            </div>
            <div
              className="modal-body"
              style={{ padding: "0px", margin: "0px" }}
            >
              <div>
                <AudioReactRecorder
                  state={dataa}
                  onStop={(data) => onStop(data, true)}
                  // canvasWidth={"0"}
                  // canvasHeight={"0"}
                />

                {/* <button onClick={() => setDataa(RecordState.START)}>Start</button> */}

                <div className="text-center">
                  <button
                    onClick={() => {
                      setDataa(RecordState.STOP);
                      setDoUpload(true);
                    }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-primary"
                  >
                    Send
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: 80,
          right: 155,
          zIndex: 1,
          opacity: user.roleId === 7 ? 0.7 : 1,
        }}
      >
        <a
          className={`nav-link ${user.roleId === 7 ? "disabled" : ""} `}
          href="#"
          role="button"
          title="Open Chatbox"
          onClick={() => {
            dispatch(setChatboxVisible(!chatBoxVisible));
            dispatch(setOpenedChatbox({ id: null, name: null }));
          }}
        >
          {alertChatBadges.length > 0 || freeChatBadges.length > 0 ? (
            <i
              style={{
                position: "absolute",
                left: 24,
                //   top: 5,
                fontSize: "12px",
                color: "red",
                zIndex: 2,
                //   marginRight: "12px",
              }}
              className={"fa fa-circle"}
            ></i>
          ) : null}
          <IconContRound name={"comments"} />
        </a>
      </div>
      {chatBoxVisible ? (
        <Draggable
          cancel=".noDrag"
          position={{
            x: Number(localStorage.getItem("chatX")),
            y: Number(localStorage.getItem("chatY")),
          }}
          handle=".handle"
          bounds="#root"
          onStart={() => setOpacity(0.3)}
          onStop={(e, position) => {
            setOpacity(0.85);
            const { x, y } = position;
            // console.log("Position", x, y);
            localStorage.setItem("chatX", x.toString());
            localStorage.setItem("chatY", y.toString());
          }}
        >
          <div
            style={{
              zIndex: 111,
              backgroundColor: "#EFEAE2",
              width: "360px",
              height: "60vh",
              position: "absolute",
              bottom: 7,
              right: 30,
              border: "3px solid #438AA2",
              opacity: opacity,
            }}
          >
            <div
              className="offcanvas-header handle"
              style={{
                backgroundColor: "#121624",
                // borderTop: "3px solid #438AA2",
                // borderRight: "3px solid #438AA2",
                // borderLeft: "3px solid #438AA2",
                height: "48px",
                paddingLeft: "15px",
                paddingRight: "15px",
                cursor: "move",
              }}
            >
              <span>
                <h6
                  className="offcanvas-title"
                  id="offcanvasScrollingLabel"
                  style={{ color: "#438AA2" }}
                >
                  {openedChatbox?.id ? (
                    <a href="#" className="nav-link noDrag">
                      <i
                        style={{
                          fontSize: "18px",
                          color: "#438AA2",
                          marginRight: "12px",
                        }}
                        title="Go Back"
                        onClick={() =>
                          dispatch(setOpenedChatbox({ id: null, name: null }))
                        }
                        className={"fa fa-arrow-left"}
                      ></i>
                      {chatType === "free"
                        ? `${openedChatbox?.firstname} ${openedChatbox?.lastname}`
                        : `Alert (${openedChatbox?.id})`}
                    </a>
                  ) : (
                    <>
                      <i
                        style={{
                          fontSize: "18px",
                          color: "#438AA2",
                          marginRight: "12px",
                        }}
                        title="Chatbox"
                        className={"fa fa-comments"}
                      ></i>
                      {"Chat Box"}
                    </>
                  )}
                </h6>
              </span>
              <div>
                {openedChatbox.id ? (
                  <>
                    <i
                      type="button"
                      title="Voice Message"
                      data-bs-toggle="modal"
                      data-bs-target="#recView"
                      // className="btn-close"

                      onClick={() => {
                        navigator.permissions
                          .query({ name: "microphone" })
                          .then(function (permissionStatus) {
                            console.log(permissionStatus.state); // granted, denied, prompt
                            if (permissionStatus.state == "denied") {
                              errorToast("Microphone Permission not granted.");
                            } else {
                              setDataa(RecordState.START);
                              duration();
                            }
                          });
                      }}
                      style={{
                        fontSize: "22px",
                        color: "#438AA2",
                        marginRight: "10px",
                      }}
                      className={"fa fa-microphone noDrag"}
                    ></i>
                    <i
                      type="button"
                      title="Attach file"
                      onClick={() => {
                        fileUpload.current.click();
                      }}
                      style={{
                        fontSize: "22px",
                        color: "#438AA2",
                        marginRight: "10px",
                      }}
                      className={"fa fa-paperclip noDrag"}
                    ></i>
                  </>
                ) : null}
                <i
                  type="button"
                  // className="btn-close"
                  onClick={() => {
                    dispatch(setChatboxVisible(!chatBoxVisible));
                    dispatch(setOpenedChatbox({ id: null, name: null }));
                  }}
                  style={{ fontSize: "22px", color: "#d63a3a" }}
                  className={"fa fa-close noDrag"}
                ></i>
              </div>
            </div>
            <div
              className="offcanvas-body"
              style={{
                backgroundColor: "#EFEAE2",
                padding: "0px",
                margin: "0px",
              }}
            >
              {openedChatbox?.id ? (
                <>
                  <div>
                    <div
                      style={{
                        // paddingLeft: "10Px",
                        // paddingRight: "10px",
                        overflowY: "auto",
                        minHeight: "46vh",
                        maxHeight: "46vh",
                        backgroundColor: "#EFEAE2",
                        paddingBottom: "10px",
                      }}
                    >
                      {chatType === "free"
                        ? freeChatMsgsFun(openedChatbox)
                        : alertChatMsgsList(openedChatbox)}
                      <div ref={chatti}></div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: 0,
                      backgroundColor: "#F0F2F5",
                    }}
                  >
                    <input
                      autoFocus={true}
                      style={{
                        width: "82%",
                        flex: 1,
                        height: "5vh",
                        border: 0,
                        outline: 0,
                        background: "white",
                        color: "#369",
                        padding: "10px",
                        fontSize: 18,
                        // margin: 0,
                        margin: "8px",
                        borderRadius: "5px",
                      }}
                      value={chatMessage}
                      type="text"
                      placeholder="Type Here ... "
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (openedChatbox.id) {
                            if (chatType === "free") {
                              sendFreeChatMsg(chatMessage);
                            } else {
                              sendAlertChatMsg(chatMessage);
                            }
                          } else {
                            errorToast("Please select a sender.");
                          }
                        }
                      }}
                      onChange={(e) => {
                        setChatMessage(e.target.value);
                      }}
                    />
                    <div
                      style={{
                        width: "12%",
                        marginTop: "10px",
                      }}
                      className="float-end"
                    >
                      <a
                        href="#"
                        title="Send Message"
                        className="nav-link"
                        onClick={() => {
                          if (openedChatbox.id) {
                            if (chatType === "free") {
                              sendFreeChatMsg(chatMessage);
                            } else {
                              sendAlertChatMsg(chatMessage);
                            }
                          } else {
                            errorToast("Please select a sender.");
                          }
                        }}
                      >
                        <span>
                          <i
                            style={{ fontSize: "30px" }}
                            className="fa-solid fa-paper-plane"
                          ></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <ul
                  className="list-group list-group-flush"
                  style={{
                    padding: "0px",
                    margin: "0px",
                    backgroundColor: "#EFEAE2",
                  }}
                >
                  {getCusAlertList()}
                  {getType2AlertList()}
                  {getRespondersList()}
                  <br />
                </ul>
              )}
            </div>
          </div>
        </Draggable>
      ) : null}
      <input
        ref={fileUpload}
        type="file"
        name="file"
        style={{ display: "none" }}
        onChange={(e) => {
          uploadFile(e.target.files[0]);
          // setRoomName(e.target.files[0]);
          // console.error(e.target.files[0]);
        }}
      />
    </>
  );
};

export default ChatboxOffCanvas;
