import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  BlockCustomer,
  DeleteUser,
  SearchCustomers,
  UnBlockCustomer,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import {
  setActionVal,
  setConfirmText,
  setEditUserData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ListResidents = (props) => {
  const [currentList, setCurrentList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const itemsPerPage = 10;

  const [name, setName] = useState("");
  const [userType, setUserType] = useState("");
  const [allUser, setAllUser] = useState([]);
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const customers = useSelector((state) => state.user.customers);
  const whiteList = useSelector((state) => state.user.whiteList);
  const user = useSelector((state) => state.user.user);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  useEffect(() => {
    setAllUser([...customers, ...whiteList]);
  }, [customers, whiteList]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentList(allUser.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allUser.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, allUser]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allUser.length;

    setItemOffset(newOffset);
  };

  useEffect(() => {
    // alert(userType);
  }, [userType]);

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "whitelist") {
      deleteWhitelistMemmerAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const deleteWhitelistMember = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Resident?",
        type: "whitelist",
        id,
      })
    );
    delBtn.click();
  };

  const deleteWhitelistMemmerAction = async (id) => {
    await DeleteUser(dispatch, { userId: id });
    await search();
  };

  const updateCustomerState = async (cus) => {
    if (cus.blocked) {
      await UnBlockCustomer({ userId: cus.id })
        .then((res) => {
          succesToast("Customer Unblocked.");
          search();
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    } else {
      await BlockCustomer({ userId: cus.id })
        .then((res) => {
          succesToast("Customer blocked.");
          search();
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    }
  };

  const search = async () => {
    let endTimeUTC = endTime ? new Date(endTime).toUTCString() : null;
    let startTimeUTC = startTime ? new Date(startTime).toUTCString() : null;

    dispatch(setIsLoading(true));
    await SearchCustomers(dispatch, {
      name,
      contact,
      email,
      address,
      userType,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
    });
    dispatch(setIsLoading(false));
  };

  const getList = () => {
    let count = 0;
    return currentList?.map((val, index) => {
      count++;
      return (
        <tr key={index}>
          <th scope="row">{count}</th>
          <td>{`${val.firstname} ${val.lastname}`}</td>
          {/* <td>{`${val.email}`}</td> */}
          <td>{`${val.contact}`}</td>
          <td>{`${val.house} ${val.block} ${val.street} ${val.phase} ${val.city}`}</td>
          <td>
            {val.registeredAt
              ? new Date(val?.registeredAt).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : null}
          </td>
          <td>
            {val.roleId === 5
              ? "Registered"
              : val.roleId === 11
              ? "Un-registered"
              : ""}
          </td>

          <td>{val.group?.name}</td>
          <td>
            {val.roleId === 11 ? (
              <>
                <a
                  className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
                  href="#"
                >
                  <i
                    style={{
                      fontSize: "20px",
                      opacity: user.roleId === 1 ? 1 : 0.5,
                    }}
                    className="fa fa-light fa-pencil"
                    onClick={() => {
                      dispatch(setEditUserData(val));
                    }}
                    title="Edit"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target="#addUser"
                  ></i>
                  <i
                    style={{
                      fontSize: "20px",
                      color: "red",
                      marginLeft: "10px",
                      opacity: user.roleId === 1 ? 1 : 0.5,
                    }}
                    className="fa fa-light fa-trash"
                    onClick={() => deleteWhitelistMember(val.id)}
                    title="Delete"
                  ></i>
                </a>
              </>
            ) : val.roleId === 5 ? (
              <a
                className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
                href="#"
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: val.blocked ? "green" : "red",
                    marginLeft: "10px",
                    opacity: user.roleId === 1 ? 1 : 0.4,
                  }}
                  className="fa fa-light fa-ban"
                  onClick={() => updateCustomerState(val)}
                  title={`${
                    val.blocked ? "Un-block Resident" : "Block Resident"
                  }`}
                ></i>
                <i
                  style={{
                    fontSize: "20px",
                    color: "red",
                    marginLeft: "10px",
                    opacity: user.roleId === 1 ? 1 : 0.5,
                  }}
                  className="fa fa-light fa-trash"
                  onClick={() => deleteWhitelistMember(val.id)}
                  title="Delete"
                ></i>
              </a>
            ) : null}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Residents List"}
        name={"listResidents"}
        icon={"fa fa-users-line"}
      >
        <div className="row">
          <div className="col-4">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a>
          </div>
          <div className="col-4">
            <div className="text-center">
              <h6>{`Total Records: ${allUser.length}`}</h6>
            </div>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      placeholder={"Name"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Name</label>
                  </th>
                  {/* <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={email}
                      placeholder={"Email"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="col-form-label">Email</label>
                  </th> */}
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={contact}
                      placeholder={"Cell Phone #"}
                      onChange={(e) => setContact(e.target.value)}
                    />
                    <label className="col-form-label">Cell Phone #</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={address}
                      placeholder={"address"}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="col-form-label">Address</label>
                  </th>
                  <th style={{ width: "300px" }}>
                    <div className="row">
                      <div className="col-6">
                        <DatePicker
                          placeholderText="Start Date Time"
                          className="form-control form-control-sm"
                          selected={startTime}
                          onChange={(date) => setStartTime(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd hh:mm:ss"
                        />
                      </div>
                      <div className="col-6" style={{ marginLeft: "0px" }}>
                        <DatePicker
                          placeholderText="End Date Time"
                          className="form-control form-control-sm"
                          selected={endTime}
                          onChange={(date) => setEndTime(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd hh:mm:ss"
                        />
                      </div>
                    </div>
                    <label className="col-form-label">Registered At</label>
                  </th>
                  <th style={{ width: "130px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={userType}
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value="Blocked">Blocked</option>
                      <option value="Registered">Registered</option>
                      <option value="Un-registered">Un-registered</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Status</label>
                  </th>

                  <th style={{ width: "120px" }}>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      // value={username}
                      placeholder={"Group"}
                      // onChange={(e) => (e.target.value)}
                      disabled={true}
                    />
                    <label className="col-form-label">Group</label>
                  </th>
                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        &nbsp;
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            width: "40px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody style={{ height: currentList.length ? "" : "60vh" }}>
                {getList()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div>
            <div className="float-end">
              <ReactPaginate
                nextLabel="&raquo;"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="&laquo;"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListResidents;
