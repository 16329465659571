import React, { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import { alertBeepStop } from "../../../../../Howler/Beeper";
import Pin from "./Pin";

const Spot = (props) => {
  const myPop = (
    <div
      className=""
      style={{
        backgroundColor: "#fff",
        minWidth: 200,
        padding: 10,
        position: "absolute",
        top: 50,
        left: 0,
        lineHeight: "5px",
      }}
    >
      <span style={{ zIndex: 1111010 }}>
        <span
          className="float-end"
          style={{ cursor: "pointer" }}
          onClick={() => setPop(false)}
        >
          <i
            className="fa fa-remove"
            style={{ fontSize: "18px", color: "red" }}
          ></i>
        </span>
      </span>
      <div>{props.content || null}</div>
    </div>
  );
  const [pop, setPop] = useState(false);

  return (
    <React.Fragment>
      <Marker
        latitude={Number(props.lat) || 31}
        longitude={Number(props.lng) || 74}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setPop(!pop);
        }}
      >
        <>
          <Pin
            pData={props.pData}
            setPop={setPop}
            pin={props.pin}
            shade={props.shade}
            buzz={props.buzz}
            buzzResolve={props.buzzResolve}
            buzzFallback={props.buzzFallback}
            focused={props.focused}
            features={props.features || {}}
          />
        </>
      </Marker>
      {pop && (
        <Popup
          anchor="top"
          longitude={Number(props.lng)}
          latitude={Number(props.lat)}
          closeButton={false}
          offset={[0, 20]}
          style={{ zIndex: 1, minWidth: props.popWidth }}
        >
          <div
            className=""
            style={{
              backgroundColor: "transparent",
              minWidth: 200,

              // padding: 10,
              // position: "absolute",
              // top: 50,
              // left: 0,
              lineHeight: "5px",
            }}
          >
            <span style={{ zIndex: 1 }}>
              <span
                className="float-end"
                style={{ cursor: "pointer" }}
                onClick={() => setPop(false)}
              >
                <i
                  className="fa fa-remove"
                  style={{ fontSize: "16px", color: "red" }}
                ></i>
              </span>
              <span
                className="float-end"
                style={{ cursor: "pointer" }}
                onClick={() => alertBeepStop()}
              >
                <i
                  class="fa-solid fa-volume-xmark"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginRight: "10px",
                  }}
                  title="Stop sound"
                ></i>
              </span>
            </span>
            <div>{props.content || null}</div>
          </div>
        </Popup>
      )}
    </React.Fragment>
  );
};

export default Spot;
