import React, { useState, useEffect, useRef } from "react";
import MapGL, {
  Marker,
  FullscreenControl,
  NavigationControl,
} from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "./style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";
import {
  DelGeofences,
  GetGeofences,
  UpdateGeofences,
} from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { setGeoIsLoading } from "../../../../../redux/slices/mapSlice";

var arr = [];

const ViewGeofence = (props) => {
  const dispatch = useDispatch();
  const geoIsLoading = useSelector((state) => state.map.geoIsLoading);

  const geofences = useSelector((state) => state.map.geofences);
  const latitude = useSelector((state) => state.map.latitude);
  const longitude = useSelector((state) => state.map.longitude);

  const user = useSelector((state) => state.user.user);
  // console.log("Supervisor - Geo View");

  const btnRef = useRef();
  const [geofenceArr, setGeofenceArr] = useState([]);
  const [updatedArr, setUpdatedArr] = useState([]);
  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [map, setMap] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  useEffect(() => {
    if (Array.isArray(geofences)) {
      setTimeout(() => {
        let struct = {
          type: "FeatureCollection",
          features: [...geofences],
        };
        setData(struct);
      }, 300);
    }
    return;
  }, [geofences]);

  useEffect(() => {
    GetGeofences(dispatch);
  }, []);

  const updateGeofence = async () => {
    if (arr.length === 0) {
      errorToast("Please update a Geofence.");
      return;
    }
    // console.log("data", geofences, arr);

    dispatch(setGeoIsLoading(true));

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      await UpdateGeofences(dispatch, element.id, element)
        .then(() => {
          arr = [];
          setGeofenceArr([]);
        })
        .catch((e) => {
          dispatch(setGeoIsLoading(false));
        });
    }
    await GetGeofences(dispatch);
    dispatch(setGeoIsLoading(false));
  };

  const deleteGeo = async (mapId) => {
    dispatch(setGeoIsLoading(true));
    await DelGeofences(dispatch, mapId)
      .then(async () => {
        arr = [];
        setGeofenceArr([]);
        await GetGeofences(dispatch);
        dispatch(setGeoIsLoading(false));
      })
      .catch((e) => {
        dispatch(setGeoIsLoading(false));
      });
  };

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <MapGL
          initialState={{ data }}
          mapStyle={mapStyle}
          style={{ width: "100%", height: "560px" }}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={latitude || 31.4676}
          longitude={longitude || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          <Draw
            pointControl={false}
            lineStringControl={false}
            combineFeaturesControl={false}
            uncombineFeaturesControl={false}
            polygonControl={false}
            data={data}
            trashControl={user.roleId === 1 ? true : false}
            onDrawSelectionChange={(data) => {
              // alert("Selected : " + data.features[0].id);
            }}
            onDrawDelete={async (geofence) => {
              deleteGeo(geofence.features[0].id);
              // var index = -1;
              // const arr = geofenceArr;
              // const data = arr.filter((val, ind) => {
              //   if (val.id === geofence.features[0].id) {
              //     index = ind;
              //     return true;
              //   }
              //   return false;
              // });
              // if (index !== -1) {
              //   arr.splice(index, 1);
              //   setGeofenceArr(arr);
              // }
            }}
            // onDrawCreate={async (d) => {
            //   console.log("geofence ", d);
            //   var limit = d.features.length;
            //   if (limit - 1 == 0) {
            //     limit = 0;
            //   } else {
            //     limit = limit - 1;
            //   }
            //   const arr = geofenceArr;
            //   arr.push({
            //     id: d.features[limit].id,
            //     coordinates: d.features[limit],
            //   });
            //   setGeofenceArr(arr);
            // }}
            onDrawUpdate={(d) => {
              var id = d.features[0].id;
              var limit = d.features.length;
              if (limit - 1 == 0) {
                limit = 0;
              } else {
                limit = limit - 1;
              }
              var index = null;
              var last = d.features[limit];

              const data = arr.filter((val, ind) => {
                // console.log("aaaaaa", val);
                if (val.id === id) {
                  index = ind;
                  return true;
                }
                return false;
              });
              // console.log(
              //   "askdksahdkjhsakjdhsad >>>>>>>>>>>>>>>>>>>",
              //   last,
              //   data
              // );

              // console.log("last", , );
              if (data.length === 0) {
                // console.log("pushing");
                arr.push({ id: id, coordinates: last });
              } else {
                // console.log("updating");
                arr[index] = { id: id, coordinates: last };
              }
              // console.log("updated >>>", arr);
              // setUpdatedArr(arr);
            }}
          />
          {/* <Draw data={data} onDrawSelectionChange={(data) => {}} /> */}
          <div className="fullscreen">
            <FullscreenControl />
            <NavigationControl showZoom position="bottom-right" />
          </div>
        </MapGL>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        title={"View Geofences"}
        name={"viewGeofence"}
        icon={"fa fa-globe"}
      >
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              title="Go To View"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#addGeofence"}
            ></i>
          </a>
        </div>
        <div className="" onMouseEnter={() => setMap(true)}>
          {map ? (
            <>
              {getMap()}
              <br />
              {user.roleId === 1 ? (
                <div className="text-center">
                  {geoIsLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        updateGeofence();
                      }}
                    >
                      Update Geofence
                    </button>
                  )}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <p> Hover to see map. </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ViewGeofence;
