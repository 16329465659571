import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  service: null,
  responders: [],
  searchedResponders: [],
  supervisors: [],
  operators: [],
  devices: [],
  groups: [],
  searchedGroups: [],
  searchedSupervisors: [],
  searchedOperators: [],
  searchedDevices: [],
  customers: [],
  cusFamily: [],
  searchedSocietyAdmins: [],
  societyAdmins: [],
  applicationBadge: false,
  applications: [],
  approvedApplications: [],
  rejectedApplications: [],
  requestApplications: [],
  whiteList: [],
  admins: [],
  searchedServiceContacts: [],
  serviceContacts: [],
  directorSecurity: [],
  searchedDirectorSecurity: [],
  announcements: [],
  isLoading: false,
  mqttConnected: false,
  isAnnLoading: false,
  editUserData: null,
  editDeviceData: null,
  actionVal: null,
  delBtn: null,
  confirmText: null,
  applicationDetails: null,
  rejectNote: null,
  config: null,
  refresh: false,
  internetStatus: false,
  editGroupData: null,
  approveGroupId: null,
  imageDetail: null,
  prevModal: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setResponders: (state, action) => {
      state.responders = action.payload;
    },
    setSearchedResponders: (state, action) => {
      state.searchedResponders = action.payload;
    },
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
    setSearchedOperators: (state, action) => {
      state.searchedOperators = action.payload;
    },
    setSupervisors: (state, action) => {
      state.supervisors = action.payload;
    },
    setSearchedSupervisors: (state, action) => {
      state.searchedSupervisors = action.payload;
    },
    setDirectorSecurity: (state, action) => {
      state.directorSecurity = action.payload;
    },
    setSearchedDirectorSecurity: (state, action) => {
      state.searchedDirectorSecurity = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setSearchedGroups: (state, action) => {
      state.searchedGroups = action.payload;
    },
    setSearchedDevices: (state, action) => {
      state.searchedDevices = action.payload;
    },
    setSocietyAdmins: (state, action) => {
      state.societyAdmins = action.payload;
    },
    setSearchedSocietyAdmins: (state, action) => {
      state.searchedSocietyAdmins = action.payload;
    },
    setApplications: (state, action) => {
      state.applications = action.payload;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setCusFamily: (state, action) => {
      state.cusFamily = action.payload;
    },
    setWhiteList: (state, action) => {
      state.whiteList = action.payload;
    },
    setAdmins: (state, action) => {
      state.admins = action.payload;
    },
    setServiceContacts: (state, action) => {
      state.serviceContacts = action.payload;
    },
    setSearchedServiceContacts: (state, action) => {
      state.searchedServiceContacts = action.payload;
    },
    setAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },

    setEditUserData: (state, action) => {
      state.editUserData = action.payload;
    },
    setEditDeviceData: (state, action) => {
      state.editDeviceData = action.payload;
    },
    setEditGroupData: (state, action) => {
      state.editGroupData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsAnnLoading: (state, action) => {
      state.isAnnLoading = action.payload;
    },
    setActionVal: (state, action) => {
      state.actionVal = action.payload;
    },
    setDelBtn: (state, action) => {
      state.delBtn = action.payload;
    },
    setConfirmText: (state, action) => {
      state.confirmText = action.payload;
    },
    setApplicationdetails: (state, action) => {
      state.applicationDetails = action.payload;
    },
    setRejectNote: (state, action) => {
      state.rejectNote = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setApplicationBadge: (state, action) => {
      state.applicationBadge = action.payload;
    },
    setApprovedApplications: (state, action) => {
      state.approvedApplications = action.payload;
    },
    setRejectedApplications: (state, action) => {
      state.rejectedApplications = action.payload;
    },
    setRequestApplications: (state, action) => {
      state.requestApplications = action.payload;
    },
    setService: (state, action) => {
      state.service = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    setInternetStatus: (state, action) => {
      state.internetStatus = action.payload;
    },
    setMqttConnected: (state, action) => {
      state.mqttConnected = action.payload;
    },
    setApproveGroupId: (state, action) => {
      state.approveGroupId = action.payload;
    },
    setImageDetail: (state, action) => {
      state.imageDetail = action.payload;
    },
    setPrevModal: (state, action) => {
      state.prevModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setDevices,
  setOperators,
  setResponders,
  setSocietyAdmins,
  setSupervisors,
  setApplications,
  setCusFamily,
  setCustomers,
  setWhiteList,
  setAdmins,
  setServiceContacts,
  setAnnouncements,
  setIsLoading,
  setEditUserData,
  setEditDeviceData,
  setSearchedDevices,
  setSearchedOperators,
  setSearchedResponders,
  setSearchedServiceContacts,
  setSearchedSocietyAdmins,
  setSearchedSupervisors,
  setActionVal,
  setDelBtn,
  setConfirmText,
  setApplicationdetails,
  setRejectNote,
  setApplicationBadge,
  setApprovedApplications,
  setRejectedApplications,
  setRequestApplications,
  setService,
  setConfig,
  setIsAnnLoading,
  setDirectorSecurity,
  setSearchedDirectorSecurity,
  setRefresh,
  setInternetStatus,
  setMqttConnected,
  setGroups,
  setSearchedGroups,
  setEditGroupData,
  setApproveGroupId,
  setImageDetail,
  setPrevModal,
} = userSlice.actions;

export default userSlice.reducer;
