import React, { useEffect, useState } from "react";

const MyPasswordInput = (props) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <>
      <label className="col-form-label">{props.name}:</label>
      <div className="input-group">
        {/* <span className="input-group-text">
          <i className="fas fa-lock"></i>
        </span> */}
        <input
          type={showPass ? "text" : "password"}
          className="form-control"
          id="password"
          name="password"
          placeholder=""
          value={props.val}
          onChange={(e) => props.setVal(e.target.value)}
        />
        <span className="input-group-text">
          <a
            href="#"
            className="nav-link"
            onClick={() => setShowPass(() => !showPass)}
          >
            <i
              className={showPass ? "far fa-eye" : "far fa-eye-slash"}
              id="togglePassword"
            ></i>
          </a>
        </span>
      </div>
    </>
  );
};

export default MyPasswordInput;
