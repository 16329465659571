import React, { useState } from "react";
import { useEffect } from "react";
import { Marker, Popup } from "react-map-gl";
import ResPin from "./ResPin";

const ResSpot = (props) => {
  const [myLat, setMyLat] = useState(31);
  const [myLng, setMyLng] = useState(74);

  useEffect(() => {
    animateMarkerTo(myLat, myLng, Number(props.lat), Number(props.lng));
    // setMyLat(Number(props.lat));
    // setMyLng(Number(props.lng));
  }, [props.lat, props.lng]);

  function animateMarkerTo(lat, lng, newLat, newLng) {
    var options = {
      duration: 6000,
      easing: function (x, t, b, c, d) {
        // jquery animation: swing (easeOutQuad)
        return -c * (t /= d) * (t - 2) + b;
      },
    };

    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    window.cancelAnimationFrame =
      window.cancelAnimationFrame || window.mozCancelAnimationFrame;

    var marker = {};
    // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
    marker.AT_startPosition_lat = lat;
    marker.AT_startPosition_lng = lng;
    var newPosition_lat = newLat;
    var newPosition_lng = newLng;

    // crossing the 180° meridian and going the long way around the earth?
    if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
      if (newPosition_lng > marker.AT_startPosition_lng) {
        newPosition_lng -= 360;
      } else {
        newPosition_lng += 360;
      }
    }

    var animateStep = function (marker, startTime) {
      var ellapsedTime = new Date().getTime() - startTime;
      var durationRatio = ellapsedTime / options.duration; // 0 - 1
      var easingDurationRatio = options.easing(
        durationRatio,
        ellapsedTime,
        0,
        1,
        options.duration
      );

      if (durationRatio < 1) {
        setMyLat(
          marker.AT_startPosition_lat +
            (newPosition_lat - marker.AT_startPosition_lat) *
              easingDurationRatio
        );
        setMyLng(
          marker.AT_startPosition_lng +
            (newPosition_lng - marker.AT_startPosition_lng) *
              easingDurationRatio
        );

        // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
        if (window.requestAnimationFrame) {
          marker.AT_animationHandler = window.requestAnimationFrame(
            function () {
              animateStep(marker, startTime);
            }
          );
        } else {
          marker.AT_animationHandler = setTimeout(function () {
            animateStep(marker, startTime);
          }, 17);
        }
      } else {
        setMyLat(newLat);
        setMyLng(newLng);
      }
    };

    // stop possibly running animation
    if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(marker.AT_animationHandler);
    } else {
      clearTimeout(marker.AT_animationHandler);
    }

    animateStep(marker, new Date().getTime());
  }

  const myPop = (
    <div
      className=""
      style={{
        backgroundColor: "#fff",
        minWidth: 200,
        padding: 10,
        position: "absolute",
        top: 20,
        left: 0,
        lineHeight: "5px",
        boxShadow: "1px 2px 9px grey",
      }}
    >
      <span style={{ zIndex: 1010 }}>
        <span
          className="float-end nav-link"
          style={{ cursor: "pointer" }}
          onClick={() => setPop(false)}
        >
          <i
            className="fa fa-remove"
            style={{ fontSize: "18px", color: "red" }}
          ></i>
        </span>
      </span>

      {props.content || null}
    </div>
  );
  const [pop, setPop] = useState(false);
  const getPop = () => {
    if (pop == true) {
      if (props.content == null) {
        return null;
      } else {
        return myPop;
      }
    } else {
      return null;
    }
  };
  return (
    <React.Fragment>
      <Marker
        // style={{  }}
        latitude={myLat}
        longitude={myLng}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setPop(!pop);
        }}
      >
        {/* {getPop()} */}
        <ResPin
          status={props.status}
          course={props.course}
          setPop={setPop}
          pop={pop}
          pin={props.pin}
          focused={props.focused}
          features={props.features || {}}
        />
      </Marker>

      {pop && (
        <Popup
          anchor="top"
          longitude={Number(props.lng)}
          latitude={Number(props.lat)}
          closeButton={false}
          offset={[0, 20]}
          style={{ zIndex: 1 }}
        >
          <div
            className=""
            style={{
              backgroundColor: "white",
              minWidth: 200,
              // padding: 10,
              // position: "absolute",
              // top: 50,
              // left: 0,
              lineHeight: "5px",
            }}
          >
            <span style={{ zIndex: 1 }}>
              <span
                className="float-end"
                style={{ cursor: "pointer" }}
                onClick={() => setPop(false)}
              >
                <i
                  className="fa fa-remove"
                  style={{ fontSize: "16px", color: "red" }}
                ></i>
              </span>
            </span>
            <div>{props.content || null}</div>
          </div>
        </Popup>
      )}

      {props.battery < 25 && (
        <Popup
          anchor="bottom"
          longitude={Number(props.lng)}
          latitude={Number(props.lat)}
          closeButton={false}
          offset={[0, -28]}
          style={{ zIndex: 11 }}
        >
          <div
            className=""
            style={{
              backgroundColor: "white",
              padding: "0px",
            }}
          >
            <div>
              <div>
                <i
                  style={{ color: "red", fontSize: "24px" }}
                  className="fa-solid fa-battery-quarter"
                ></i>
              </div>
              <div style={{ color: "red" }}>
                {Number(props.battery)?.toString()?.substring(0, 4) + " %"}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </React.Fragment>
  );
};

export default ResSpot;
