import { toast } from "react-toastify";

export const succesToast = (text) => {
  toast.success(text);
};

export const errorToast = (text) => {
  toast.error(text);
};

export const warnToast = (text) => {
  toast.warn(text);
};
