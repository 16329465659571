import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddContact,
  AddUserAPI,
  AddWhitelistCustomer,
  DeleteUser,
  GenerateAndSendPassword,
  GetUsers,
  SearchCustomers,
  UpdateServiceContact,
  UpdateUsers,
  UpdateWhiteListCustomer,
  UploadFile,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { URL } from "../../../../../config";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setActionVal,
  setConfirmText,
  setEditUserData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";

const AddUser = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [userType, setUserType] = useState("");
  const [contact, setContact] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [groupId, setGroupId] = useState("");
  const [house, setHouse] = useState("");
  const [block, setBlock] = useState("");
  const [street, setStreet] = useState("");
  const [phase, setPhase] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [cnic, setCnic] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [des, setDes] = useState("");
  const [edit, setEdit] = useState("");
  const [userId, setUserId] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [password, setPassword] = useState("");
  const [network, setNetwork] = useState("");

  const [file, setFile] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const editUserData = useSelector((state) => state.user.editUserData);
  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);
  const groups = useSelector((state) => state.user.groups);

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "resetPassword") {
      generateAndSend(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const confirmation = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to reset password for this user?",
        type: "resetPassword",
        id,
      })
    );
    delBtn.click();
  };

  useEffect(() => {
    if (editUserData) {
      setUserId(editUserData?.id);
      setGroupId(editUserData?.groupId);
      setFirstname(editUserData?.firstname);
      setLastname(editUserData?.lastname);
      setNetwork(editUserData?.network);
      setUsername(editUserData?.username || "");
      setContact(editUserData?.contact.substr(3, editUserData?.contact.length));
      setEmail(editUserData?.email);
      setPassword(editUserData?.password);
      setUserType(editUserData?.roleId?.toString());
      setHouse(editUserData?.house);
      setTown(editUserData?.town);
      setCity(editUserData?.city);
      setPhase(editUserData?.phase);
      setStreet(editUserData?.street);
      setBlock(editUserData?.block);
      setEdit(editUserData?.roleId?.toString());
      dispatch(setEditUserData(null));
    }
  }, [editUserData]);

  const addUser = async () => {
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }
    if (IsEmpty(username)) {
      errorToast("Username is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(password)) {
      errorToast("Password is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    dispatch(setIsLoading(true));

    const imageData = await upload();

    await AddUserAPI(dispatch, {
      firstname,
      lastname,
      username,
      roleId: userType,
      password,
      email,
      network,
      contact: "+92" + contact,
      avatarFileId: imageData?.id,
      groupId,
    })
      .then(async () => {
        await GetUsers(dispatch);
        emptyFields();
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
    dispatch(setIsLoading(false));
  };

  const updateUser = async () => {
    if (IsEmpty(userId)) {
      errorToast("User ID is required");
      return;
    }
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }
    if (IsEmpty(username)) {
      errorToast("Username is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    // if (IsEmpty(password)) {
    //   errorToast("Password is required");
    //   return;
    // }
    dispatch(setIsLoading(true));

    const imageData = await upload();

    await UpdateUsers(dispatch, {
      userId,
      firstname,
      lastname,
      username,
      roleId: userType,
      // password,
      email,
      network,
      contact: "+92" + contact,
      avatarFileId: imageData?.id,
      groupId,
    })
      .then(async () => {
        await GetUsers(dispatch);
        emptyFields(false);
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });

    dispatch(setIsLoading(false));
  };

  const updateServiceContact = async () => {
    if (IsEmpty(userId)) {
      errorToast("User ID is required");
      return;
    }
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }

    dispatch(setIsLoading(true));

    const imageData = await upload();

    await UpdateServiceContact(dispatch, {
      userId,
      firstname,
      lastname,
      email,
      network,
      contact: "+92" + contact,
      avatarFileId: imageData?.id,
      groupId,
    })
      .then(async () => {
        await GetUsers(dispatch);
        emptyFields(false);
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });

    dispatch(setIsLoading(false));
  };

  const updateWhitelistMemeber = async () => {
    if (IsEmpty(userId)) {
      errorToast("User ID is required");
      return;
    }
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(house)) {
      errorToast("House # is required");
      return;
    }

    if (IsEmpty(city)) {
      errorToast("City is required");
      return;
    }

    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    dispatch(setIsLoading(true));

    const imageData = await upload();

    await UpdateWhiteListCustomer(dispatch, {
      userId,
      firstname,
      lastname,
      roleId: userType,
      email,
      network,
      contact: "+92" + contact,
      house,
      block,
      street,
      phase,
      city,
      town,
      avatarFileId: imageData?.id,
      groupId,
    })
      .then(async () => {
        await SearchCustomers(dispatch, {});
        emptyFields(false);
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });

    dispatch(setIsLoading(false));
  };

  const addResident = async () => {
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(house)) {
      errorToast("House # is required");
      return;
    }

    if (IsEmpty(city)) {
      errorToast("City is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    dispatch(setIsLoading(true));

    await AddWhitelistCustomer(dispatch, {
      firstname,
      lastname,
      roleId: userType,
      email,
      network,
      contact: "+92" + contact,
      house,
      block,
      street,
      phase,
      city,
      town,
      groupId,
    })
      .then(() => {
        emptyFields();
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
    dispatch(setIsLoading(false));
  };

  const generateAndSend = async (id) => {
    dispatch(setIsLoading(true));
    if (IsEmpty(id)) {
      errorToast("User Id is required");
      return;
    }
    await GenerateAndSendPassword({ userId: id })
      .then(() => {
        dispatch(setIsLoading(false));
        succesToast("Password sent");
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  const addServiceContact = async () => {
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }

    dispatch(setIsLoading(true));

    await AddContact(dispatch, {
      firstname,
      lastname,
      roleId: userType,
      email,
      network,
      contact: "+92" + contact,
      groupId,
    })
      .then(() => {
        emptyFields();
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
    dispatch(setIsLoading(false));
  };

  const emptyFields = (emptyEdit = true) => {
    setFirstname("");
    setLastname("");
    setUsername("");
    setContact("");
    setEmail("");
    setPassword("");
    setUserType("");
    setHouse("");
    setTown("");
    setCity("");
    setCnic("");
    setPhase("");
    setStreet("");
    setNetwork("");
    setBlock("");
    setUserId("");
    setGroupId("");
    if (emptyEdit) {
      setEdit(null);
    }
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return <option value={val.id}>{val.name}</option>;
    });
  };

  const groupListForCus = () => {
    if (groups.length > 1) {
      const newList = groups.filter((val, index) => {
        if (val.id !== 1) {
          return true;
        }
      });
      return newList.map((val, index) => {
        return <option value={val.id}>{val.name}</option>;
      });
    } else {
      return groups.map((val, index) => {
        return <option value={val.id}>{val.name}</option>;
      });
    }
  };

  const upload = async () => {
    if (file) {
      return await UploadFile(file, "UserImage");
    }
  };

  return (
    <>
      <Modal
        title={edit ? "Update System User" : "Add System User"}
        name={"addUser"}
        icon={"fa fa-user-plus"}
      >
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              onClick={() => {
                setEdit(null);
                emptyFields();
              }}
              title="Go Back"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={edit === "11" ? "#listResidents" : "#listUser"}
            ></i>
          </a>
        </div>
        <form>
          <div className="row">
            <div className="col-4">
              <label className="col-form-label">Select User Type:</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <option value={undefined}></option>
                <option value="15">Director Security</option>
                <option value="13">Security Manager</option>
                <option value="2">Supervisor</option>
                <option value="3">Operator</option>
                <option value="4">Responder</option>
                <option value="12">Service Contact</option>
                <option value="11">Resident</option>
                <option value="16">Complaint Admin</option>
                <option value="17">Complaint Supervisor</option>
                <option value="18">Complaint Operator</option>
                <option value="19">Complaint Responder</option>
                {/* <option value="5">Device</option> */}
              </select>
            </div>
          </div>
          {userType &&
          (userType === "4" ||
            userType === "17" ||
            userType === "18" ||
            userType === "16" ||
            userType === "2" ||
            userType === "3" ||
            userType === "13" ||
            userType === "15") ? (
            <>
              <hr />
              <div className="row">
                <div className="mb-3 col-3">
                  <MyTextInput
                    name={"Firstname"}
                    val={firstname}
                    placeholder={""}
                    setVal={setFirstname}
                  />
                </div>
                <div className="mb-3 col-3">
                  <MyTextInput
                    name={"Lastname"}
                    val={lastname}
                    placeholder={""}
                    setVal={setLastname}
                  />
                </div>

                <div className="mb-3 col-3">
                  <MyTextInput
                    name={"Cell Phone #"}
                    val={contact}
                    placeholder={"3xxxxxxxxxx"}
                    setVal={setContact}
                    max={10}
                    pre={true}
                  />
                </div>
                <div className="col-3">
                  <label className="col-form-label">
                    Select Network Provider:
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="zong">Zong</option>
                    <option value="warid">Warid</option>
                    <option value="jazz">Jazz</option>
                    <option value="ufone">Ufone</option>
                    <option value="telenor">Telenor</option>
                    <option value="s-com">S-com</option>
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                <div className="mb-3 col-3">
                  <MyTextInput
                    name={"Username"}
                    val={username}
                    placeholder={""}
                    setVal={setUsername}
                  />
                </div>
                <div className="mb-3 col-3">
                  <MyTextInput name={"Email"} val={email} setVal={setEmail} />
                </div>
                <div className="col-3">
                  <label className="col-form-label">Select Group:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={groupId}
                    onChange={(e) => setGroupId(e.target.value)}
                  >
                    <option value=""></option>
                    {groupList()}
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                {edit ? (
                  <div className="mb-3 col-3">
                    <label className="col-form-label">Password:</label>
                    <div className="d-grid">
                      {isLoading ? (
                        <button
                          className="btn btn-primary flex"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          &nbsp; Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            confirmation(userId);
                            // addUser();
                          }}
                        >
                          Generate and send Password
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mb-3 col-4">
                    <MyPasswordInput
                      name={"Password"}
                      val={password}
                      placeholder={""}
                      setVal={setPassword}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-4">
                  <label className="col-form-label">Profile Picture:</label>
                  <div className="input-group mb-3">
                    <input
                      type="file"
                      className="form-control"
                      id="inputGroupFile02"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : edit ? (
                    <button
                      type="submit"
                      className="btn btn-primary w-50"
                      onClick={(e) => {
                        e.preventDefault();
                        updateUser();
                      }}
                    >
                      Update User
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-50"
                      onClick={(e) => {
                        e.preventDefault();
                        addUser();
                      }}
                    >
                      Add User
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          ) : userType === "11" || userType === "5" ? (
            <>
              <hr />
              <div className="row">
                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Firstname"}
                    val={firstname}
                    placeholder={""}
                    setVal={setFirstname}
                  />
                </div>
                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Lastname"}
                    val={lastname}
                    placeholder={""}
                    setVal={setLastname}
                  />
                </div>

                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Cell Phone #"}
                    val={contact}
                    placeholder={"3xxxxxxxxxx"}
                    setVal={setContact}
                    max={10}
                    pre={true}
                  />
                </div>
                <div className="col-4">
                  <label className="col-form-label">
                    Select Network Provider:
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="zong">Zong</option>
                    <option value="warid">Warid</option>
                    <option value="jazz">Jazz</option>
                    <option value="ufone">Ufone</option>
                    <option value="telenor">Telenor</option>
                    <option value="s-com">S-com</option>
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Email"}
                    val={email}
                    setVal={setEmail}
                    placeholder={""}
                  />
                </div>
                <div className="col-4">
                  <label className="col-form-label">Select Group:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={groupId}
                    onChange={(e) => setGroupId(e.target.value)}
                  >
                    <option value=""></option>
                    {groupListForCus()}
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-2">
                  <MyTextInput
                    name={"House #"}
                    val={house}
                    setVal={setHouse}
                    placeholder={"e.g. House # 23"}
                  />
                </div>
                <div className="mb-3 col-2">
                  <MyTextInput
                    name={"Block"}
                    val={block}
                    setVal={setBlock}
                    placeholder={"e.g. Block: B"}
                  />
                </div>
                <div className="mb-3 col-2">
                  <MyTextInput
                    name={"Street #"}
                    val={street}
                    setVal={setStreet}
                    placeholder={"e.g. street: 01"}
                  />
                </div>
                <div className="mb-3 col-2">
                  <MyTextInput
                    name={"Town "}
                    val={town}
                    setVal={setTown}
                    placeholder={"e.g. DHA"}
                  />
                </div>
                <div className="mb-3 col-2">
                  <MyTextInput
                    name={"Phase "}
                    val={phase}
                    setVal={setPhase}
                    placeholder={"e.g. Ph: 01"}
                  />
                </div>
                <div className="mb-3 col-2">
                  <MyTextInput
                    name={"City"}
                    val={city}
                    setVal={setCity}
                    placeholder={""}
                  />
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp; Loading...
                    </button>
                  ) : edit ? (
                    <button
                      type="submit"
                      className="btn btn-primary w-50"
                      onClick={(e) => {
                        e.preventDefault();
                        updateWhitelistMemeber();
                      }}
                    >
                      Update Resident
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-50"
                      onClick={(e) => {
                        e.preventDefault();
                        addResident();
                      }}
                    >
                      Add Resident
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          ) : userType === "12" ? (
            <>
              <hr />
              <div className="row">
                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Firstname"}
                    val={firstname}
                    placeholder={""}
                    setVal={setFirstname}
                  />
                </div>
                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Lastname"}
                    val={lastname}
                    placeholder={""}
                    setVal={setLastname}
                  />
                </div>

                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Cell Phone #"}
                    val={contact}
                    placeholder={"3xxxxxxxxxx"}
                    setVal={setContact}
                    max={10}
                    pre={true}
                  />
                </div>
                <div className="col-4">
                  <label className="col-form-label">
                    Select Network Provider:
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="zong">Zong</option>
                    <option value="warid">Warid</option>
                    <option value="jazz">Jazz</option>
                    <option value="ufone">Ufone</option>
                    <option value="telenor">Telenor</option>
                    <option value="s-com">S-com</option>
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                <div className="mb-3 col-4">
                  <MyTextInput
                    name={"Email"}
                    val={email}
                    setVal={setEmail}
                    placeholder={""}
                  />
                </div>
                <div className="col-4">
                  <label className="col-form-label">Select Group:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={groupId}
                    onChange={(e) => setGroupId(e.target.value)}
                  >
                    <option value=""></option>
                    {groupList()}
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp; Loading...
                    </button>
                  ) : edit ? (
                    <button
                      type="submit"
                      className="btn btn-primary "
                      onClick={(e) => {
                        e.preventDefault();
                        updateServiceContact();
                      }}
                    >
                      Update Service Contact
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary "
                      onClick={() => addServiceContact()}
                    >
                      Add Service Contact
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          ) : (
            <>
              <br />
              <br />
            </>
          )}
        </form>
      </Modal>
    </>
  );
};

export default AddUser;
