/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import MapGL, { Marker } from "@urbica/react-map-gl";
import "./style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";

const ViewLocation = (props) => {
  const dispatch = useDispatch();

  const markerLocation = useSelector((state) => state.map.markerLocation);

  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [map, setMap] = useState(false);

  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <MapGL
          initialState={{ data }}
          mapStyle={mapStyle}
          style={{ width: "100%", height: "560px" }}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={markerLocation?.latitude || 31.4676}
          longitude={markerLocation?.longitude || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          {markerLocation ? (
            <Marker
              longitude={markerLocation?.longitude || 74.325816}
              latitude={markerLocation?.latitude || 31.484722}
              // onClick={onMarkerClick}
            >
              <div style={{ zIndex: 12345 }}>
                <img
                  src={require("../../../../../assets/pins/mappin.png")}
                  alt=""
                  style={{ width: "34px" }}
                />
              </div>
            </Marker>
          ) : null}
        </MapGL>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        title={"Location"}
        name={"viewMarkerOnMap"}
        icon={"fa fa-map-location-dot"}
      >
        <div className="" onMouseEnter={() => setMap(true)}>
          {map ? (
            <>
              {getMap()}
              <br />
            </>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <p> Hover Mouse to see map. </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ViewLocation;
