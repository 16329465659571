/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserLogin } from "../../actions";
import { errorToast } from "../../helpers/toasts";
import { IsEmpty } from "../../helpers/utils";
import { setIsLoading } from "../../redux/slices/userSlice";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const isLoading = useSelector((state) => state.user.isLoading);

  const dispatch = useDispatch();

  const goto = async () => {
    dispatch(setIsLoading(true));

    if (IsEmpty(username)) {
      errorToast("Username is required");
      dispatch(setIsLoading(true));
      return;
    }

    if (IsEmpty(password)) {
      errorToast("Password is required");
      dispatch(setIsLoading(true));
      return;
    }

    await UserLogin(dispatch, { username, password, deviceId: 1 });
    dispatch(setIsLoading(false));
    Empty();
  };

  const Empty = () => {
    setUsername("");
    setPassword("");
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords) {
        // console.log("posss", position.coords);
        // props.getCenter([
        //   {
        //     latitude: Number(position.coords.latitude),
        //     longitude: Number(position.coords.longitude),
        //   },
        // ]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-4"></div>
          <div className="card col-4" style={{ marginTop: "60px" }}>
            <div className="card-body">
              <center>
                <img
                  src={require("./logo2.png")}
                  width="120"
                  height="120"
                  alt=""
                  title={"Emergency Alert & Response System"}
                />
                {/* <h3>E.A.R.S</h3> */}
              </center>
              <hr />
              <div className="input-form">
                <label className="col-form-label"> Username </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Enter Username "
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      goto();
                    }
                  }}
                />
              </div>
              <br />
              <div className="input-form">
                <label className="col-form-label"> Password </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder=" Enter Password "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      goto();
                    }
                  }}
                />
                <div className="float-end">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      errorToast(
                        "Contact System admin to reset your password."
                      );
                    }}
                    className="nav-link"
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      Forgot Password?
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <br />
                <br />
                <button
                  style={{ flex: 1, width: "100%" }}
                  title="Login"
                  className="btn btn-primary"
                  onClick={() => {
                    // setIsLoading(true);
                    goto();
                  }}
                >
                  {isLoading ? "Please Wait..." : "Login"}
                </button>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(props.user, null, 2)}</pre> */}
    </>
  );
};

export default Login;
