import React, { useEffect, useState } from "react";

const MyTextInput = (props) => {
  return (
    <>
      {props.name ? (
        <label className="col-form-label">{props.name}:</label>
      ) : null}
      <div className="input-group">
        {props.pre ? <span className="input-group-text">+92</span> : null}
        <input
          type={props.type || "text"}
          className="form-control"
          placeholder={props.placeholder}
          value={props.val}
          maxLength={props.max || 255}
          onChange={(e) => props.setVal(e.target.value)}
        />
      </div>
    </>
  );
};

export default MyTextInput;
