import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ResetPasswordAPI } from "../../actions";
import MyPasswordInput from "../../components/MyPasswordInput";
import { errorToast } from "../../helpers/toasts";
import { IsEmpty } from "../../helpers/utils";
import { setIsLoading } from "../../redux/slices/userSlice";

const ResetPassword = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPasswaod, setConfirmPasswaod] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const goto = async () => {
    // console.log(params.resetLink);
    if (IsEmpty(params.resetLink)) {
      errorToast("Reset Link is required");
      return;
    }
    if (IsEmpty(password)) {
      errorToast("Password is required");
      return;
    }
    if (IsEmpty(confirmPasswaod)) {
      errorToast("Confirm Password is required");
      return;
    }

    if (confirmPasswaod !== password) {
      errorToast("Password doesn't matched");
      return;
    }

    dispatch(setIsLoading(true));
    await ResetPasswordAPI(dispatch, {
      resetId: params.resetLink,
      password,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        Empty();
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  const Empty = () => {
    setPassword("");
    setConfirmPasswaod("");
  };

  useEffect(() => {
    if (user) {
      errorToast("User already logged in.");
      navigate("/");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords) {
        // console.log("posss", position.coords);
        // props.getCenter([
        //   {
        //     latitude: Number(position.coords.latitude),
        //     longitude: Number(position.coords.longitude),
        //   },
        // ]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12"></div>
          <div
            className="card col-lg-4 col-sm-12"
            style={{ marginTop: "60px" }}
          >
            <div className="card-body">
              <center>
                {/* <img
                  src={require("./logo2.png")}
                  width="120"
                  height="120"
                  alt=""
                  title={"Emergency Alert & Response System"}
                /> */}
                <h3>Reset Password</h3>
              </center>
              <hr />

              <div className="input-form">
                <MyPasswordInput
                  name={"New Password"}
                  val={password}
                  placeholder={""}
                  setVal={setPassword}
                />
              </div>
              <div className="input-form">
                <MyPasswordInput
                  name={"Confirm Password"}
                  val={confirmPasswaod}
                  placeholder={""}
                  setVal={setConfirmPasswaod}
                />
              </div>
              <div>
                <br />
                <br />
                <button
                  style={{ flex: 1, width: "100%" }}
                  title="Login"
                  className="btn btn-primary"
                  onClick={() => {
                    // setIsLoading(true);
                    goto();
                  }}
                >
                  {isLoading ? "Please Wait..." : "Reset"}
                </button>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(props.user, null, 2)}</pre> */}
    </>
  );
};

export default ResetPassword;
