import React, { useEffect, useState } from "react";
import ChatboxOffCanvas from "./ChatboxOffCanvas";
import LocationIcon from "./LocationIcon";
import ManageLayers from "./ManageLayers";
import MapZoom from "./MapZoom";
import ThemeIcon from "./ThemeIcon";

const MapItems = (props) => {
  return (
    <>
      <MapZoom />
      <LocationIcon />
      <ThemeIcon />
      <ChatboxOffCanvas />
      <ManageLayers />
    </>
  );
};

export default MapItems;
