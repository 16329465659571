/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { useDispatch, useSelector } from "react-redux";
import { IsEmpty } from "../../../../../helpers/utils";
import { BroadCast, GetAnnouncements } from "../../../../../actions";
import axios from "axios";
import { URL } from "../../../../../config";
import { setIsAnnLoading } from "../../../../../redux/slices/userSlice";

const CreateAnn = (props) => {
  const dispatch = useDispatch();
  const isAnnLoading = useSelector((state) => state.user.isAnnLoading);
  const groups = useSelector((state) => state.user.groups);
  const user = useSelector((state) => state.user.user);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [dataa, setDataa] = useState("");
  const [audioFile, setAudioFile] = useState("");
  const [attachment, setAttachment] = useState("");
  const [doUpload, setDoUpload] = useState("");
  const [dTime, setdTime] = useState("00:00");
  const [inter, setInter] = useState("");
  const [groupId, setGroupId] = useState("");
  const [isRecording, setIsRecording] = useState("");

  const duration = async () => {
    let num = 1;
    let interval = setInterval(() => {
      setdTime(convertHMS(num));
      num++;
    }, 1000);
    setInter(interval);
  };

  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds; // Return is HH : MM : SS
  }

  const onStop = (audioData) => {
    // console.error("audioData >>> ", audioData);
    if (inter) {
      clearInterval(inter);
    }
    // console.log("ashgdhjgsa", audioData);

    setDataa(audioData);
    // if (doUpload) {
    setAudioFile(audioData);
    // }
  };

  const creatAnn = async () => {
    if (IsEmpty(title)) {
      errorToast("Title id required");
      return;
    }
    if (IsEmpty(body) && IsEmpty(audioFile)) {
      errorToast("Announcement Body or recording required");
      return;
    }

    if (!IsEmpty(audioFile)) {
      dispatch(setIsAnnLoading(true));
      var FormData = require("form-data");
      let date = new Date();
      const fileName =
        "Ears_" + Math.floor(date.getTime() + date.getSeconds() / 2);
      var data = new FormData();
      data.append("file", audioFile.blob, fileName);

      var config = {
        method: "post",
        url: URL + "upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: data,
      };
      await axios(config)
        .then(async (response) => {
          await BroadCast({
            title,
            body,
            attachment: response.data.id,
            groupId: user.groupId === 1 ? groupId : user.groupId,
          })
            .then(async (res) => {
              await GetAnnouncements(dispatch);
              setTitle("");
              setBody("");
              setGroupId("");
              setAudioFile("");
              setdTime("00:00");
              succesToast("Announcement Added");
              dispatch(setIsAnnLoading(false));
            })
            .catch((e) => {
              dispatch(setIsAnnLoading(false));
              errorToast(e.response?.data?.err);
            });
        })
        .catch((e) => {
          dispatch(setIsAnnLoading(false));
          errorToast(e.response?.data?.err);
        });
    } else {
      await BroadCast({
        title,
        body,
        groupId: user.groupId === 1 ? groupId : user.groupId,
      })
        .then(async (res) => {
          await GetAnnouncements(dispatch);
          setTitle("");
          setBody("");
          setGroupId("");
          setAudioFile("");
          setdTime("00:00");
          succesToast("Announcement Added");
        })
        .catch((e) => {
          dispatch(setIsAnnLoading(false));

          errorToast(e.response?.data?.err);
        });
    }
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return <option value={val.id}>{val.name}</option>;
    });
  };

  return (
    <>
      <Modal
        title={"Resident Announcement"}
        name={"createAnnouncements"}
        icon={"fa fa-bullhorn"}
      >
        <form>
          <>
            <div className="row">
              <div className="col-12">
                <a href="#" className="nav-link">
                  <i
                    style={{ fontSize: "20px" }}
                    className="fa fa-light fa-arrow-left"
                    title="Go To List"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#listAnnouncements"}
                  ></i>
                </a>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="mb-3 col-12">
                    <MyTextInput
                      name={"Title"}
                      val={title}
                      placeholder={""}
                      setVal={setTitle}
                    />
                  </div>
                </div>
                {user.groupId === 1 ? (
                  <div className="col-12">
                    <label className="col-form-label">Select Group:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={groupId}
                      onChange={(e) => setGroupId(e.target.value)}
                    >
                      <option value=""></option>
                      {groupList()}
                      {/* <option value="5">Device</option> */}
                    </select>
                  </div>
                ) : null}
                <div className="row">
                  <div className="mb-3 col-12">
                    <label className="form-label" style={{ fontWeight: "500" }}>
                      Body:
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      maxLength={1000}
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                    ></textarea>
                    <div className="fst-italic float-end">
                      A Maximum of 1000 words.
                    </div>
                  </div>
                </div>
                <div className="row">
                  {audioFile ? (
                    <div className="col-12">
                      <div
                        style={{
                          marginTop: "5px",
                          // width: "260px",
                          // height: "46px",
                          backgroundColor: "#d9fdd3",
                          borderRadius: "10px",
                          boxShadow: "1px 1px 9px grey",
                          float: "left",
                        }}
                      >
                        <div
                          style={{
                            paddingBottom: -6,
                            borderRadius: 25,
                          }}
                        >
                          <audio
                            controls
                            src={`${audioFile.url}`}
                            className="send"
                          ></audio>
                          <a href="#">
                            <i
                              style={{
                                fontSize: "22px",
                                marginLeft: "5px",
                                float: "right",
                                justifyContent: "center",
                                textAlign: "center",
                                paddingTop: "16px",
                                paddingRight: "10px",
                                color: "red",
                              }}
                              title="Delete"
                              className="fa fa-light fa-trash"
                              onClick={() => {
                                setAudioFile(null);
                              }}
                            ></i>
                          </a>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  ) : (
                    <>
                      <div className="col-12">
                        <label
                          className="form-label"
                          style={{ fontWeight: "500" }}
                        >
                          Voice Note:
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="file"
                            className="form-control"
                            id="inputGroupFile02"
                            accept=".mp3,.aac,.wav"
                          />
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="inputGroupFileAddon04"
                            onClick={() => {
                              let granted = true;
                              navigator.permissions
                                .query({ name: "microphone" })
                                .then(function (permissionStatus) {
                                  console.log(permissionStatus.state); // granted, denied, prompt
                                  if (permissionStatus.state == "denied") {
                                    granted = false;
                                    errorToast(
                                      "Microphone Permission not granted."
                                    );
                                  } else {
                                    if (!isRecording) {
                                      setIsRecording(true);
                                      setDataa(RecordState.START);
                                      duration();
                                    } else {
                                      errorToast("Recording in progress.");
                                    }
                                  }
                                });
                            }}
                          >
                            <i
                              style={{ fontSize: "16px", marginLeft: "5px" }}
                              className="fa fa-light fa-microphone"
                            ></i>
                            <span> &nbsp;</span>
                            Record
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-6">
                <>
                  <div className="card">
                    <div className="card-header">
                      <i
                        style={{ fontSize: "20px", marginLeft: "5px" }}
                        className="fa fa-light fa-microphone"
                      ></i>
                      <span> &nbsp;</span>
                      Recording
                      <span> &nbsp;</span>
                      <span> &nbsp;</span>
                      {/* <span> &nbsp;</span> */}
                      {dTime}
                    </div>
                    <div className="card-body text-center">
                      {/* {isRecording ? ( */}
                      <div style={{ justifyContent: "center" }}>
                        <AudioReactRecorder
                          state={dataa}
                          onStop={(data) => {
                            onStop(data, true);
                          }}
                          // canvasWidth={"0"}
                          // canvasHeight={"0"}
                        />
                        {/* <button onClick={() => setDataa(RecordState.START)}>Start</button> */}
                      </div>
                      {/* ) : null} */}
                      {isRecording ? (
                        <a
                          href="#"
                          className="btn btn-success"
                          onClick={() => {
                            setIsRecording(false);
                            setDataa(RecordState.STOP);
                            setdTime("00:00");
                          }}
                        >
                          Submit
                        </a>
                      ) : (
                        <a
                          href="#"
                          className="btn btn-success"
                          // onClick={() => {
                          //   setIsRecording(true);
                          //   setDataa(RecordState.START);
                          //   duration();
                          // }}

                          onClick={() => {
                            navigator.permissions
                              .query({ name: "microphone" })
                              .then(function (permissionStatus) {
                                console.log(permissionStatus.state); // granted, denied, prompt
                                if (permissionStatus.state == "denied") {
                                  errorToast(
                                    "Microphone Permission not granted."
                                  );
                                } else {
                                  setIsRecording(true);
                                  setDataa(RecordState.START);
                                  duration();
                                }
                              });
                          }}
                        >
                          Start
                        </a>
                      )}
                    </div>
                  </div>
                </>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isAnnLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-50"
                    onClick={(e) => {
                      e.preventDefault();
                      creatAnn();
                    }}
                  >
                    Broadcast
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default CreateAnn;
