import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { GetCenter, GetServiceDetails } from "../../actions";
import SideBarBtn from "../../components/SideBarBtn";
import MapGl from "./MapWall/MapGl/MapGl";
import MapWall from "./MapWall/MapWall";
import SideBar from "./SideBar/SideBar";

const Main = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords) {
        GetCenter(dispatch, [
          {
            latitude: Number(position.coords.latitude),
            longitude: Number(position.coords.longitude),
          },
        ]);
      }
    });

    GetServiceDetails(dispatch);
  }, []);

  return (
    <>
      <div className="row" style={{ padding: "0px", margin: "0px" }}>
        {/* SideBar */}
        <div
          className="col-1"
          style={{
            height: "91vh",
            width: "5%",
            backgroundColor: "#121624",
            // padding: "5px",
            // paddingLeft: "4px",
            // paddingRight: "4px",
            // paddingTop: "5px",
            padding: "5px",
            margin: "0px",
            textAlign: "center",
          }}
        >
          <SideBar />
        </div>

        {/* Map Wall */}

        <div
          style={{
            height: "78vh",
            padding: "0px",
            margin: "0px",
            width: "95%",
          }}
          className="col-11"
        >
          <MapWall />
        </div>
      </div>
    </>
  );
};

export default Main;
