import "./App.css";
import TopBar from "./containers/TopBar/TopBar";
import Main from "./containers/Main/Main";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./containers/Login/Login";
import Project from "./containers/Project";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import SendLink from "./containers/ResetPassword/SendLink";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Mqtt from "./Mqtt/Mqtt";
import VerifyEmail from "./containers/VerifyEmail/VerifyEmail";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <div
          style={{
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            {/* <Mqtt /> */}
            <Project />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <>
        <div
          style={{
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            <SendLink />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/reset-password/:resetLink",
    element: (
      <>
        <div
          style={{
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            <ResetPassword />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/verify-email/:resetLink",
    element: (
      <>
        <div
          style={{
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            <VerifyEmail />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/version",
    element: <>Version 2.0.0</>,
  },
]);

function App() {
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
