/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Marker, Popup } from "react-map-gl";

const StaticMarker = (props) => {
  const [pop, setPop] = useState(false);

  return (
    <>
      <Marker
        longitude={props.item.lng}
        latitude={props.item.lat}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          console.log("popopopopopopopopopopopopo", pop);
          setPop(!pop);
        }}
      >
        <div style={{ zIndex: 5, cursor: "pointer" }}>
          <img
            src={require(`../../../../../assets/pins/${props.item.markerLogoName}.png`)}
            alt=""
            style={{ width: "34px" }}
          />
        </div>
        {/* {pop ? (
          <div
            className=""
            style={{
              backgroundColor: "#fff",
              minWidth: 160,
              maxWidth: 160,
              padding: 10,
              position: "absolute",
              top: -70,
              left: -70,
              lineHeight: "14px",
              boxShadow: "1px 2px 9px grey",
            }}
          >
            <span style={{ zIndex: 1010 }}>
              <span
                className="float-end nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => setPop(false)}
              >
                <i
                  className="fa fa-remove"
                  style={{ fontSize: "16px", color: "red" }}
                ></i>
              </span>
            </span>
            <div style={{ padding: "0px" }}>
              <div>
                <b>{props.item.title}</b>{" "}
              </div>
              <div>{props.item.des}</div>
            </div>
          </div>
        ) : null} */}
      </Marker>
      {pop && (
        <Popup
          anchor="bottom"
          longitude={Number(props.item.lng)}
          latitude={Number(props.item.lat)}
          closeButton={false}
          offset={[0, -28]}
          style={{ zIndex: 1 }}
        >
          <div
            className=""
            style={{
              backgroundColor: "white",
              minWidth: 200,
              // padding: 10,
              // position: "absolute",
              // top: 50,
              // left: 0,
              lineHeight: "5px",
            }}
          >
            <span style={{ zIndex: 1 }}>
              <span
                className="float-end"
                style={{ cursor: "pointer" }}
                onClick={() => setPop(false)}
              >
                <i
                  className="fa fa-remove"
                  style={{ fontSize: "16px", color: "red" }}
                ></i>
              </span>
            </span>
            <div
              style={{
                backgroundColor: "#fff",
                minWidth: 160,
                maxWidth: 160,
                // padding: 10,
                lineHeight: "14px",
                // boxShadow: "1px 2px 9px grey",
              }}
            >
              <div style={{ padding: "0px" }}>
                <div>
                  <b>{props.item.title}</b>{" "}
                </div>
                <div>{props.item.des}</div>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default StaticMarker;
