import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import * as geolib from "geolib";

import "react-datepicker/dist/react-datepicker.css";
import MapGL, {
  Marker,
  Popup,
  FullscreenControl,
  NavigationControl,
} from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";
import {
  DelGeofences,
  GetGeofences,
  GetPosition,
  UpdateGeofences,
} from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { setGeoIsLoading } from "../../../../../redux/slices/mapSlice";
import {
  setActionVal,
  setConfirmText,
} from "../../../../../redux/slices/userSlice";
import { IsEmpty } from "../../../../../helpers/utils";
import MarkerAndPopUp from "./MarkerAndPopUp";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

var arr = [];

const LocateDevice = (props) => {
  const dispatch = useDispatch();
  const geoIsLoading = useSelector((state) => state.map.geoIsLoading);

  const geofences = useSelector((state) => state.map.geofences);
  const latitude = useSelector((state) => state.map.latitude);
  const longitude = useSelector((state) => state.map.longitude);
  // console.log("Supervisor - Geo View");

  const applicationDetails = useSelector(
    (state) => state.user.applicationDetails
  );
  const devices = useSelector((state) => state.user.devices);
  const responders = useSelector((state) => state.user.responders);

  const btnRef = useRef();
  const [geofenceArr, setGeofenceArr] = useState([]);
  const [res, setRes] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [updatedArr, setUpdatedArr] = useState([]);
  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [obj, setObj] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [map, setMap] = useState(false);
  const [mapLat, setMapLat] = useState(null);
  const [mapLng, setMapLng] = useState(null);
  const [showPath, setShowPath] = useState(true);
  const [showMarker, setShowMarker] = useState(true);
  const [list, setList] = useState([]);
  const [position, setPosition] = useState({
    longitude: 74.325816,
    latitude: 31.484722,
  });
  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  useEffect(() => {
    setMapLat(latitude);
    setMapLng(mapLng);
  }, [latitude, longitude]);

  const search = async () => {
    if (IsEmpty(res) && IsEmpty(deviceId)) {
      errorToast("Device or User is required");
      return;
    }
    if (IsEmpty(startTime)) {
      errorToast("Start time is required");
      return;
    }
    if (IsEmpty(endTime)) {
      errorToast("End Time is required");
      return;
    }

    let endTimeUTC = endTime ? new Date(endTime).toUTCString() : null;
    let startTimeUTC = startTime ? new Date(startTime).toUTCString() : null;

    dispatch(setGeoIsLoading(true));
    await GetPosition(dispatch, {
      userId: res,
      deviceId: deviceId,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
    })
      .then((res) => {
        if (res.data.data.length === 0) {
          errorToast("No Record found.");
        }

        const data = res.data.data;
        setList(data);

        let coordinates = [];
        for (let i = 0; i < data.length; i++) {
          coordinates.push([data[i].longitude, data[i].latitude]);
        }

        const centerData = geolib.getCenter(coordinates);
        setMapLat(centerData.latitude);
        setMapLng(centerData.longitude);

        const features = {
          id: "79df15ba9d39ca19fa6a7943accd29eb",
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        };

        let struct = {
          type: "FeatureCollection",
          features: [features],
        };

        setObj(struct);

        if (res.data.length) {
          setMap(true);
        }

        dispatch(setGeoIsLoading(false));
      })
      .catch((e) => {
        // console.log(e);
        dispatch(setGeoIsLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  const getResOptions = () => {
    if (responders.length > 0) {
      return responders.map((val, index) => {
        return (
          <option value={val.id}>{`${val.firstname} ${val.lastname}`}</option>
        );
      });
    }
  };

  const markerAndPopUps = () => {
    if (list.length > 0) {
      return list.map((val, index) => {
        // console.log("asdasdasdasdasdasdasdasdsadasdasd", val);
        return <MarkerAndPopUp data={val} />;
      });
    }
  };

  const getDeviceOptions = () => {
    if (devices.length > 0) {
      return devices.map((val, index) => {
        return <option value={val.id}>{`${val.name}`}</option>;
      });
    }
  };

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJja3cwZ3M1N3liNWUxMnBxdzN2ZTlkNDM4In0.gyFSwxBqCYec5OvGW1luGA";
  let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <MapGL
          initialState={{ data }}
          mapStyle={mapStyle}
          style={{ width: "100%", height: "580px" }}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={mapLat || 31.4676}
          longitude={mapLng || 74.3209}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          {showMarker ? markerAndPopUps() : null}
          {showPath ? (
            <Draw
              pointControl={false}
              data={obj}
              lineStringControl={false}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              polygonControl={false}
              trashControl={false}
            />
          ) : null}
        </MapGL>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        title={"Device History"}
        name={"locateDevice"}
        icon={"fa fa-magnifying-glass-location"}
      >
        <div className="">
          <div className="row">
            <div className="col-11">
              <div className="row">
                <div className="col-3">
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                    value={res}
                    onChange={(e) => setRes(e.target.value)}
                  >
                    <option value="">Select User</option>
                    {getResOptions()}
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                <div className="col-3">
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                    value={deviceId}
                    onChange={(e) => setDeviceId(e.target.value)}
                  >
                    <option value="">Select Device</option>
                    {getDeviceOptions()}
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                <div className="col-3">
                  <DatePicker
                    placeholderText="Start Date Time"
                    className="form-control form-control-sm"
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                  />
                </div>
                <div className="col-3">
                  <DatePicker
                    placeholderText="End Date Time"
                    className="form-control form-control-sm"
                    selected={endTime}
                    onChange={(date) => setEndTime(date)}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                  />
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="">
                {/* <a href="#" id={`clickable`}>
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </a>
                <Tooltip anchorSelect={`#clickable`} place="top">
                  <div>Datatatatata</div>
                </Tooltip> */}
                {geoIsLoading ? (
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      search();
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "10px" }}></div>
          <div style={{ height: "75vh" }}>
            {map ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="float-end">
                      <div className="form-check form-switch">
                        <label
                          style={{
                            fontSize: "12px",
                            color: "black",
                          }}
                          className="form-check-label"
                          for="inlineCheckbox2"
                        >
                          Toggle Marker
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          checked={showMarker}
                          value={showMarker}
                          onChange={(e) => setShowMarker(!showMarker)}
                        />
                      </div>
                    </div>
                    <div className="float-end">
                      <div className="form-check form-switch">
                        <label
                          style={{
                            fontSize: "12px",
                            color: "black",
                            marginRight: "14px",
                          }}
                          className="form-check-label"
                          for="inlineCheckbox2"
                        >
                          Toggle Path
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          checked={showPath}
                          value={showPath}
                          onChange={(e) => setShowPath(!showPath)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {getMap()}
                  <br />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LocateDevice;
