/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";

import "react-datepicker/dist/react-datepicker.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import { setIsAlertLoading } from "../../../../../redux/slices/alertSlice";
import {
  SearchPanics,
  SearchStaff,
  SearchStaffAttandance,
} from "../../../../../actions";

var arr = [];

const SatffAttandance = (props) => {
  const dispatch = useDispatch();
  const isAlertLoading = useSelector((state) => state.alert.isAlertLoading);
  const attandanceData = useSelector((state) => state.logs.attandance);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [userId, setUserId] = useState("");

  const [currentList, setCurrentList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  // const itemsPerPage = 10;

  const supervisors = useSelector((state) => state.user.supervisors);
  const operators = useSelector((state) => state.user.operators);
  const directorSecurity = useSelector((state) => state.user.directorSecurity);
  const responders = useSelector((state) => state.user.responders);
  const serviceContacts = useSelector((state) => state.user.serviceContacts);
  const societyAdmins = useSelector((state) => state.user.societyAdmins);

  useEffect(() => {
    // console.log("page >>>>>>>>>>>>>>>>>>>>>>>>>>>>>", attandanceData);
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentList(attandanceData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(attandanceData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, attandanceData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % attandanceData.length;

    setItemOffset(newOffset);
  };

  const getUserList = () => {
    const all = [
      ...operators,
      ...responders,
      ...supervisors,
      ...societyAdmins,
      ...directorSecurity,
    ];
    return all.map((val, index) => {
      return (
        <option
          key={val.id}
          value={val.id}
        >{`${val.firstname} ${val.lastname}`}</option>
      );
    });
  };

  const search = async () => {
    let endTimeUTC = endTime ? new Date(endTime).toUTCString() : null;
    let startTimeUTC = startTime ? new Date(startTime).toUTCString() : null;

    dispatch(setIsAlertLoading(true));
    await SearchStaffAttandance(dispatch, {
      userId,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
    })
      .then(() => {
        dispatch(setIsAlertLoading(false));
      })
      .catch((e) => {
        dispatch(setIsAlertLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  const getList = () => {
    let count = 0;
    return currentList?.map((val, index) => {
      count++;
      return (
        <tr key={index}>
          <th scope="row">{count}</th>
          <td>{`${val.user?.firstname || ""} ${val.user?.lastname || ""}`}</td>
          <td>{val.title}</td>
          <td colSpan={2} style={{}}>
            {new Date(val.createdAt).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Staff Attandance"}
        name={"staffAttandance"}
        icon={"fa fa-light fa-user-clock"}
      >
        <div className="">
          <div className="row">
            <div className="col-4">
              {/* <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a> */}
            </div>
            <div className="col-4">
              <div className="text-center">
                <h6>{`Total Records: ${attandanceData.length}`}</h6>
              </div>
            </div>
            <div className="col-4">
              <div className="float-end">
                <select
                  style={{ width: "80px" }}
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value=""></option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
              {/* <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a> */}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">
              <DatePicker
                placeholderText="Start Date Time"
                className="form-control form-control-sm"
                selected={startTime}
                onChange={(date) => setStartTime(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd hh:mm:ss"
              />
            </div>
            <div className="col-3">
              <DatePicker
                placeholderText="End Date Time"
                className="form-control form-control-sm"
                selected={endTime}
                onChange={(date) => setEndTime(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd hh:mm:ss"
              />
            </div>
            <div className="col-1">
              <div className="">
                {isAlertLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      search();
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                )}
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    >
                      <option value=""></option>
                      {getUserList()}
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Select Staff</label>
                  </th>

                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      // value={userId}
                      // onChange={(e) => setUserId(e.target.value)}
                      disabled={true}
                    >
                      <option value="">Action</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Action</label>
                  </th>

                  <th style={{ width: "380px" }}>
                    <div className="row">
                      <div className="col-6">
                        <DatePicker
                          placeholderText="Start Date Time"
                          className="form-control form-control-sm"
                          selected={startTime}
                          onChange={(date) => setStartTime(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd hh:mm:ss"
                        />
                      </div>
                      <div className="col-6">
                        <DatePicker
                          placeholderText="End Date Time"
                          className="form-control form-control-sm"
                          selected={endTime}
                          onChange={(date) => setEndTime(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd hh:mm:ss"
                        />
                      </div>
                    </div>
                    <label className="col-form-label">Date Time</label>
                  </th>

                  {/* <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="cancel">Cancelled by initiator</option>
                      <option value="close">Closed by operator</option>
                      <option value="resolve">Resolved</option>
                    </select>
                    <label className="col-form-label">Disposal</label>
                  </th> */}
                  <th style={{ width: "80px" }}>
                    {isAlertLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            width: "40px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">{`Search `}</label>
                  </th>
                </tr>
              </thead>

              <tbody style={{ height: "58vh" }}>{getList()}</tbody>
            </table>
            <div className="row">
              <div>
                <div className="float-end">
                  <ReactPaginate
                    nextLabel="&raquo;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="&laquo;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SatffAttandance;
