import React from "react";
import { useEffect } from "react";
import { useState } from "react";

let hidden = null;
let visibilityChange = null;

if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

const TabChange = (props) => {
  const [action, setAction] = useState("show");

  //   useEffect(() => {
  //     alert(visibilityChange);
  //     document.addEventListener(visibilityChange, handleVisibilityChange, false);
  //     return document.removeEventListener(
  //       visibilityChange,
  //       handleVisibilityChange
  //     );
  //   }, []);

  const onFocus = () => {
    console.log("Tab is in focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    console.log("Tab is blurred");
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  //   const handleVisibilityChange = () => {
  //     if (document[hidden]) {
  //       setAction("hide");
  //       alert("blurred");
  //     } else {
  //       setAction("show");
  //       alert("focused");
  //     }
  //   };

  return <></>;
};

export default TabChange;
