/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";

import "react-datepicker/dist/react-datepicker.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setAlertDetail,
  setIsAlertLoading,
} from "../../../../../redux/slices/alertSlice";
import { FetchAlertChatSingle } from "../../../../../actions";
import { setAlertChatSingle } from "../../../../../redux/slices/chatSlice";
import { setImageDetail } from "../../../../../redux/slices/userSlice";
import { setMarkerLocation } from "../../../../../redux/slices/mapSlice";
import { URL } from "../../../../../config";
import MsgRightDetails from "../../MapItems/Chatbox/MsgRightDetails";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { api } from "../../../../../actions/apiClient";

var arr = [];

const AlertDetails = (props) => {
  const dispatch = useDispatch();
  const [searched, setSearched] = useState(false);
  const [feedBack, setFeedBack] = useState(null);
  const isAlertLoading = useSelector((state) => state.alert.isAlertLoading);
  const alertDetail = useSelector((state) => state.alert.alertDetail);
  const alertChatSingle = useSelector((state) => state.chat.alertChatSingle);

  useEffect(() => {
    if (alertDetail) {
      getFeedback(alertDetail.id);
    }
  }, [alertDetail]);

  const getFeedback = async (panicId) => {
    const token = localStorage.getItem("token");
    const url = URL + "panic/getFeedback";
    await api
      .post(
        url,
        { panicId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        // console.error("White list member created.", response.data);
        setFeedBack(response.data);
      })
      .catch((e) => {
        setFeedBack(null);
        console.error("Feedback error", e);
        // alert(e.response.data.err);
      });
  };

  const getRatingStar = (val) => {
    const contant = [];
    for (let index = 0; index < val; index++) {
      contant.push(
        <i style={{ color: "#EDC904" }} className="fa-solid fa-star"></i>
      );
    }
    return contant;
  };

  const getRatingStarGrey = (val) => {
    const contant = [];
    for (let index = 0; index < 5 - val; index++) {
      contant.push(
        <i style={{ color: "grey" }} className="fa-solid fa-star"></i>
      );
    }
    return contant;
  };

  const formatTime = (t) => {
    let date = new Date(t);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const fetchChat = async () => {
    if (IsEmpty(alertDetail?.id)) {
      errorToast("Alert ID is required");
      return;
    }

    dispatch(setIsAlertLoading(true));
    await FetchAlertChatSingle(dispatch, {
      panicId: alertDetail?.id,
    })
      .then(() => {
        dispatch(setIsAlertLoading(false));
      })
      .catch((e) => {
        dispatch(setIsAlertLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  const alertChatMsgsList = () => {
    return alertChatSingle.map((msgObj, index) => {
      return (
        <React.Fragment key={msgObj.uuid}>
          {msgObj.file ? (
            msgObj.file === "img" ? (
              <>
                <span style={{ fontSize: "14px" }}>
                  {`Message from ${msgObj.sender?.firstname} ${
                    msgObj?.sender?.lastname || ""
                  } (${
                    msgObj?.sender?.roleId === 2
                      ? "Supervisor"
                      : msgObj?.sender?.roleId === 3
                      ? "Operator"
                      : msgObj?.sender?.roleId === 4
                      ? "Responder"
                      : msgObj?.sender?.roleId === 5 ||
                        msgObj?.sender?.roleId === 6
                      ? "Resident"
                      : ""
                  })`}
                </span>
                <div
                  className={`clickable_${msgObj?.uuid}`}
                  style={{
                    background: "#D9FDD3",
                    color: "#111B21",
                    display: "block",
                    // flexDirection: "row",
                    marginTop: "5px",
                    padding: "10px",
                    borderRadius: "8px",
                    marginLeft: "50px",
                    marginRight: "5px",
                    float: "right",
                    fontSize: "14px",
                    boxShadow: "1px 2px 9px grey",
                  }}
                >
                  <a
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#showImage"}
                    title="Image ..."
                    onClick={() => {
                      dispatch(
                        setImageDetail(`${URL}upload/${msgObj.fileData?.id}`)
                      );
                    }}
                  >
                    <img
                      alt=""
                      src={`${URL}upload/${JSON.parse(msgObj.fileData)?.id}`}
                      style={{
                        width: 140,
                        height: 200,
                        padding: "2px",
                        margin: "0 10px",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "8px",
                        marginTop: "1px",
                        fontSize: "11px",
                        color: "grey",
                        alignSelf: "flex-end",
                      }}
                    >
                      {formatTime(msgObj.createdAt)}
                    </div>
                    <div
                      style={{
                        // marginLeft: "8px",
                        marginTop: "2px",
                        alignSelf: "flex-end",
                        // fontSize: "12px",
                        // color: "grey",
                      }}
                    >
                      {/* {msgObj.msgStage === 1 ? (
                        <div style={{ paddingLeft: "4px" }}>
                          <i
                            className="fa fa-circle-exclamation"
                            style={{
                              paddingLeft: "5px !important",
                              fontSize: "14px",
                              color: "red",
                            }}
                          ></i>
                        </div>
                      ) : msgObj.msgStage === 2 ? (
                        <>
                          <div style={{ paddingLeft: "4px" }}>
                            <i
                              className="fa fa-check"
                              style={{
                                paddingLeft: "5px !important",
                                fontSize: "14px",
                                color: "grey",
                              }}
                            ></i>
                          </div>
                        </>
                      ) : msgObj.msgStage > 2 ? (
                        msgObj.resReadState === 1 &&
                        msgObj.cusReadState === 1 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/doubleTick.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : msgObj.resReadState === 2 &&
                          msgObj.cusReadState === 1 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/doubleTick.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : msgObj.resReadState === 1 &&
                          msgObj.cusReadState === 2 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/doubleTick.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : msgObj.resReadState === 2 &&
                          msgObj.cusReadState === 2 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/tickgreen.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : (
                          <>
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-check"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "grey",
                                }}
                              ></i>
                            </div>
                          </>
                        )
                      ) : null} */}

                      {/* {msgObj.msgStage === 1 ? (
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-circle-exclamation"
                                title="Please check Internet connection"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "red",
                                }}
                              ></i>
                            </div>
                          ) : msgObj.msgStage === 2 ? (
                            <>
                              <div style={{ paddingLeft: "4px" }}>
                                <i
                                  className="fa fa-check"
                                  style={{
                                    paddingLeft: "5px !important",
                                    fontSize: "14px",
                                    color: "grey",
                                  }}
                                ></i>
                              </div>
                            </>
                          ) : msgObj.msgStage > 2 ? (
                            msgObj.resReadState === 1 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/doubleTick.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : msgObj.resReadState === 2 ? (
                              <img
                                alt=""
                                src={require("../../../../assets/pins/tickgreen.png")}
                                style={{
                                  width: "28px",
                                  padding: "2px",
                                }}
                              />
                            ) : null
                          ) : null} */}
                    </div>
                  </div>

                  <div style={{ clear: "both" }}></div>
                </div>
                <Tooltip
                  anchorSelect={`.clickable_${msgObj?.uuid}`}
                  place="top"
                >
                  <div>
                    {msgObj?.resReadState !== null
                      ? msgObj?.resReadState === 0
                        ? "Not Delivered to responder"
                        : msgObj?.resReadState === 1
                        ? "Delivered to responder"
                        : msgObj?.resReadState === 2
                        ? "Read by responder"
                        : null
                      : null}
                  </div>
                  <div>
                    {msgObj?.oprReadState !== null
                      ? msgObj?.oprReadState === 0
                        ? "Not Delivered to Operator"
                        : msgObj?.oprReadState === 1
                        ? "Delivered to Operator"
                        : msgObj?.oprReadState === 2
                        ? "Read by Operator"
                        : null
                      : null}
                  </div>
                  <div>
                    {msgObj?.cusReadState !== null
                      ? msgObj?.cusReadState === 0
                        ? "Not Delivered to customer"
                        : msgObj?.cusReadState == 1
                        ? "Delivered to customer"
                        : msgObj?.cusReadState == 2
                        ? "Read by customer"
                        : null
                      : null}
                  </div>
                </Tooltip>
                <div style={{ clear: "both" }}></div>
              </>
            ) : msgObj.file === "location" ? (
              <>
                <span style={{ fontSize: "14px" }}>
                  {`Message from ${msgObj.sender?.firstname} ${
                    msgObj?.sender?.lastname
                  }  (${
                    msgObj?.sender?.roleId === 2
                      ? "Supervisor"
                      : msgObj?.sender?.roleId === 3
                      ? "Operator"
                      : msgObj?.sender?.roleId === 4
                      ? "Responder"
                      : msgObj?.sender?.roleId === 5 ||
                        msgObj?.sender?.roleId === 6
                      ? "Resident"
                      : ""
                  })`}
                </span>
                <div
                  className={`clickable_${msgObj?.uuid}`}
                  style={{
                    marginTop: "5px",
                    marginLeft: "5px",
                    width: "120px",
                    float: "right",
                    // height: "46px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    boxShadow: "1px 1px 9px grey",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 6,
                      color:
                        msgObj.senderRole === "Responder" ? "brown" : "orange",
                      fontSize: "12px",
                    }}
                  >
                    {msgObj.senderRole}
                  </div>
                  <div style={{ padding: "0px 10px" }}>
                    <i
                      style={{ fontSize: "22px", color: "grey" }}
                      className="fa-solid fa-map"
                    ></i>{" "}
                    Location
                  </div>
                  <div
                    className="d-grid gap-2"
                    style={{ padding: "0px 10px", marginTop: "6px" }}
                  >
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#viewMarkerOnMap"
                      className={`btn btn-sm btn-success`}
                      onClick={() => {
                        dispatch(
                          setMarkerLocation(JSON.parse(msgObj.fileData))
                        );
                      }}
                    >
                      View
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 12,
                    }}
                  >
                    <div
                      style={{
                        marginTop: "1px",
                        fontSize: "11px",
                        color: "grey",
                        alignSelf: "flex-end",
                      }}
                    >
                      {formatTime(msgObj.createdAt)}
                    </div>
                  </div>
                </div>
                <Tooltip
                  anchorSelect={`.clickable_${msgObj?.uuid}`}
                  place="top"
                >
                  <div>
                    {msgObj?.resReadState !== null
                      ? msgObj?.resReadState === 0
                        ? "Not Delivered to responder"
                        : msgObj?.resReadState === 1
                        ? "Delivered to responder"
                        : msgObj?.resReadState === 2
                        ? "Read by responder"
                        : null
                      : null}
                  </div>
                  <div>
                    {msgObj?.oprReadState !== null
                      ? msgObj?.oprReadState === 0
                        ? "Not Delivered to Operator"
                        : msgObj?.oprReadState === 1
                        ? "Delivered to Operator"
                        : msgObj?.oprReadState === 2
                        ? "Read by Operator"
                        : null
                      : null}
                  </div>
                  <div>
                    {msgObj?.cusReadState !== null
                      ? msgObj?.cusReadState === 0
                        ? "Not Delivered to customer"
                        : msgObj?.cusReadState == 1
                        ? "Delivered to customer"
                        : msgObj?.cusReadState == 2
                        ? "Read by customer"
                        : null
                      : null}
                  </div>
                </Tooltip>
                <div style={{ clear: "both" }}></div>
              </>
            ) : msgObj.file === "audio" ? (
              <>
                <span style={{ fontSize: "14px" }}>
                  {`Message from ${msgObj.sender?.firstname} ${
                    msgObj?.sender?.lastname
                  }  (${
                    msgObj?.sender?.roleId === 2
                      ? "Supervisor"
                      : msgObj?.sender?.roleId === 3
                      ? "Operator"
                      : msgObj?.sender?.roleId === 4
                      ? "Responder"
                      : msgObj?.sender?.roleId === 5 ||
                        msgObj?.sender?.roleId === 6
                      ? "Resident"
                      : ""
                  })`}
                </span>
                <div
                  className={`clickable_${msgObj?.uuid}`}
                  style={{
                    marginTop: "5px",
                    marginRight: "5px",
                    width: "300px",
                    // height: "46px",
                    backgroundColor: "#d9fdd3",
                    borderRadius: "10px",
                    boxShadow: "1px 1px 9px grey",
                    float: "right",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: -6,
                      borderRadius: 25,
                    }}
                  >
                    <audio
                      controls
                      src={`${URL}upload/${JSON.parse(msgObj.fileData)?.id}`}
                      className="send"
                    ></audio>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "8px",
                        marginTop: "1px",
                        fontSize: "11px",
                        color: "grey",
                        alignSelf: "flex-end",
                      }}
                    >
                      {formatTime(msgObj.createdAt)}
                    </div>

                    <Tooltip
                      anchorSelect={`.clickable_${msgObj?.uuid}`}
                      place="top"
                    >
                      <div>
                        {msgObj?.resReadState !== null
                          ? msgObj?.resReadState === 0
                            ? "Not Delivered to responder"
                            : msgObj?.resReadState === 1
                            ? "Delivered to responder"
                            : msgObj?.resReadState === 2
                            ? "Read by responder"
                            : null
                          : null}
                      </div>
                      <div>
                        {msgObj?.oprReadState !== null
                          ? msgObj?.oprReadState === 0
                            ? "Not Delivered to Operator"
                            : msgObj?.oprReadState === 1
                            ? "Delivered to Operator"
                            : msgObj?.oprReadState === 2
                            ? "Read by Operator"
                            : null
                          : null}
                      </div>
                      <div>
                        {msgObj?.cusReadState !== null
                          ? msgObj?.cusReadState === 0
                            ? "Not Delivered to customer"
                            : msgObj?.cusReadState == 1
                            ? "Delivered to customer"
                            : msgObj?.cusReadState == 2
                            ? "Read by customer"
                            : null
                          : null}
                      </div>
                    </Tooltip>

                    {/* <div
                      style={{
                        // marginLeft: "8px",
                        marginTop: "2px",
                        alignSelf: "flex-end",
                        // fontSize: "12px",
                        // color: "grey",
                      }}
                    >
                      {msgObj.msgStage === 1 ? (
                        <div style={{ paddingLeft: "4px" }}>
                          <i
                            className="fa fa-circle-exclamation"
                            style={{
                              paddingLeft: "5px !important",
                              fontSize: "14px",
                              color: "red",
                            }}
                          ></i>
                        </div>
                      ) : msgObj.msgStage === 2 ? (
                        <>
                          <div style={{ paddingLeft: "4px" }}>
                            <i
                              className="fa fa-check"
                              style={{
                                paddingLeft: "5px !important",
                                fontSize: "14px",
                                color: "grey",
                              }}
                            ></i>
                          </div>
                        </>
                      ) : msgObj.msgStage > 2 ? (
                        msgObj.resReadState === 1 &&
                        msgObj.cusReadState === 1 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/doubleTick.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : msgObj.resReadState === 2 &&
                          msgObj.cusReadState === 1 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/doubleTick.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : msgObj.resReadState === 1 &&
                          msgObj.cusReadState === 2 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/doubleTick.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : msgObj.resReadState === 2 &&
                          msgObj.cusReadState === 2 ? (
                          <img
                            alt=""
                            src={require("../../../../assets/pins/tickgreen.png")}
                            style={{
                              width: "28px",
                              padding: "2px",
                            }}
                          />
                        ) : (
                          <>
                            <div style={{ paddingLeft: "4px" }}>
                              <i
                                className="fa fa-check"
                                style={{
                                  paddingLeft: "5px !important",
                                  fontSize: "14px",
                                  color: "grey",
                                }}
                              ></i>
                            </div>
                          </>
                        )
                      ) : null}
                    </div> */}
                  </div>
                </div>
                <div style={{ clear: "both" }}></div>
              </>
            ) : null
          ) : (
            <>
              <span style={{ fontSize: "14px" }}>
                {`Message from ${msgObj.sender?.firstname} ${
                  msgObj?.sender?.lastname
                }  (${
                  msgObj?.sender?.roleId === 2
                    ? "Supervisor"
                    : msgObj?.sender?.roleId === 3
                    ? "Operator"
                    : msgObj?.sender?.roleId === 4
                    ? "Responder"
                    : msgObj?.sender?.roleId === 5 ||
                      msgObj?.sender?.roleId === 6
                    ? "Resident"
                    : ""
                })`}
              </span>
              <MsgRightDetails direction="right" text={msgObj} />
            </>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Alert Details"}
        name={"alertDetails"}
        icon={"fa fa-clock-rotate-left"}
      >
        <div className="">
          <div className="row">
            <div className="col-4">
              <a
                href="#"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setAlertDetail(null));
                  setFeedBack(null);
                  dispatch(setAlertChatSingle([]));
                }}
              >
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-light fa-arrow-left"
                  title="Go To Alert History"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={"#alertHistory"}
                ></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div
              className="col-6"
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              Initiator Details:
            </div>
            <div
              className="col-6"
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              Alert Details:
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <ul className="list-group">
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Initiator: </div>
                  <div>
                    {" "}
                    {`${
                      alertDetail?.alertType === 2 ? "Responder" : "Resident"
                    }`}
                  </div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Name: </div>
                  <div>
                    {" "}
                    {`${alertDetail?.customer?.firstname} ${alertDetail?.customer?.lastname}`}{" "}
                  </div>
                </li>

                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Address: </div>
                  <div> {`${alertDetail?.address}`} </div>
                </li>
              </ul>
              {alertChatSingle.length ? (
                <>
                  <div
                    style={{
                      textDecoration: "underline",
                      marginTop: "12px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Chat History{" "}
                  </div>
                  <div
                    className="overflow-auto"
                    style={{
                      // marginTop: "10px",
                      height: "320px",
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    {alertChatMsgsList()}
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-6">
              <ul className="list-group">
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Alert Status: </div>
                  <div>
                    {alertDetail?.isResolved
                      ? "Resolved"
                      : alertDetail?.isCancelled
                      ? "Cancelled by Initiator"
                      : alertDetail?.isClose
                      ? "Closed By Operator/Supervisor"
                      : "Null"}
                  </div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Alert Created at: </div>
                  <div>
                    {new Date(alertDetail?.createdAt).toLocaleDateString(
                      "en-us",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>
                    Operator Attanded at:{" "}
                  </div>
                  <div>
                    {alertDetail?.panicConfirmedOprDate
                      ? new Date(
                          alertDetail?.panicConfirmedOprDate
                        ).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : alertDetail?.panicConfirmedSuprDate
                      ? new Date(
                          alertDetail?.panicConfirmedSuprDate
                        ).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "null"}
                  </div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>
                    Responder Attanded at:{" "}
                  </div>
                  <div>
                    {alertDetail?.panicConfirmedResDate
                      ? new Date(
                          alertDetail?.panicConfirmedResDate
                        ).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "null"}
                  </div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>
                    Responder Resolved at:{" "}
                  </div>
                  <div>
                    {alertDetail?.panicResolvedResDate
                      ? new Date(
                          alertDetail?.panicResolvedResDate
                        ).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "null"}
                  </div>
                </li>

                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>
                    {`Alert ${
                      alertDetail?.isResolved
                        ? "Resolved"
                        : alertDetail?.isCancelled
                        ? "Cancelled"
                        : alertDetail?.isClose
                        ? "Closed"
                        : ""
                    } at:`}
                  </div>
                  <div>
                    {alertDetail?.isResolved
                      ? alertDetail?.panicResolvedOprDate
                        ? new Date(
                            alertDetail?.panicResolvedOprDate
                          ).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "null"
                      : alertDetail?.isCancelled
                      ? alertDetail?.panicClosedCusDate
                        ? new Date(
                            alertDetail?.panicClosedCusDate
                          ).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "null"
                      : alertDetail?.isClose
                      ? alertDetail?.panicClosedSuprDate
                        ? new Date(
                            alertDetail?.panicClosedSuprDate
                          ).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "null"
                      : ""}
                  </div>
                </li>
                {alertDetail?.customerRemarks || alertDetail?.customerRating ? (
                  <li
                    style={{ height: "40px" }}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <div style={{ fontWeight: "bold" }}>
                      Initiator Rating/Remarks:
                    </div>
                    <div>
                      <span title={alertDetail?.customerRating}>
                        {getRatingStar(alertDetail?.customerRating)}
                        {getRatingStarGrey(alertDetail?.customerRating)}
                      </span>
                      {` / ${alertDetail?.customerRemarks}`}
                    </div>
                  </li>
                ) : null}
                {alertDetail?.operatorRemarks ||
                alertDetail?.supervisorRemarks ? (
                  <li
                    style={{ height: "40px" }}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <div style={{ fontWeight: "bold" }}>
                      Operator/Supervisor Remarks:
                    </div>
                    <div>
                      {`${
                        alertDetail?.supervisorRemarks
                          ? alertDetail?.supervisorRemarks + "/"
                          : ""
                      }${alertDetail?.operatorRemarks}`}
                    </div>
                  </li>
                ) : null}

                {alertDetail?.operator ? (
                  <li
                    style={{ height: "40px" }}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <div style={{ fontWeight: "bold" }}>Operator Involved:</div>
                    <div>
                      {`
                      ${alertDetail?.operator?.firstname} ${
                        alertDetail?.operator?.lastname || ""
                      }`}
                    </div>
                  </li>
                ) : null}
                {alertDetail?.supervisor ? (
                  <li
                    style={{ height: "40px" }}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <div style={{ fontWeight: "bold" }}>
                      Supervisor Involved:
                    </div>
                    <div>
                      {`
                      ${alertDetail?.supervisor?.firstname} ${
                        alertDetail?.supervisor?.lastname || ""
                      }`}
                    </div>
                  </li>
                ) : null}
                {alertDetail?.responder ? (
                  <li
                    style={{ height: "40px" }}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <div style={{ fontWeight: "bold" }}>
                      Responder Involved:
                    </div>
                    <div>
                      {`
                      ${alertDetail?.responder?.firstname} ${
                        alertDetail?.responder?.lastname || ""
                      }`}
                    </div>
                  </li>
                ) : null}
                {feedBack ? (
                  <div
                    style={{
                      marginTop: "5px",
                      marginRight: "5px",
                      width: "300px",
                      // height: "46px",
                      backgroundColor: "#d9fdd3",
                      borderRadius: "10px",
                      boxShadow: "1px 1px 9px grey",
                      float: "right",
                    }}
                  >
                    <div
                      style={{
                        paddingBottom: -6,
                        borderRadius: 25,
                      }}
                    >
                      <audio
                        controls
                        src={`${URL}upload/${feedBack?.id}`}
                        className="send"
                      ></audio>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        marginRight: 10,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "8px",
                          marginTop: "1px",
                          fontSize: "11px",
                          color: "grey",
                          alignSelf: "flex-end",
                        }}
                      >
                        {formatTime(feedBack.createdAt)}
                      </div>
                    </div>
                  </div>
                ) : null}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-10"></div>
            <div className="col-2">
              <div className="float-end" style={{ marginTop: "6px" }}>
                {isAlertLoading ? (
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : alertChatSingle?.length ? null : (
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchChat();
                      //   search();
                    }}
                  >
                    Show Chat
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertDetails;
