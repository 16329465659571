import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteDeviceAPI,
  GetAllDevice,
  SearchDevices,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import {
  setActionVal,
  setConfirmText,
  setEditDeviceData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";

const ListDevices = (props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [contact, setContact] = useState("");
  const [imei, setImei] = useState("");
  const [des, setDes] = useState("");
  const [type, setType] = useState("");

  const searchedDevices = useSelector((state) => state.user.searchedDevices);
  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    dispatch(setIsLoading(true));
    await SearchDevices(dispatch, {
      name,
      contact,
      des,
      imei,
      type,
      uniqueId,
    });
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "device") {
      deleteDeviceAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const deleteDevice = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Device?",
        type: "device",
        id,
      })
    );
    delBtn.click();
  };

  const deleteDeviceAction = async (id) => {
    await DeleteDeviceAPI(dispatch, { deviceId: id });
    await search();
  };

  const getList = () => {
    let count = 0;
    return searchedDevices?.map((val, index) => {
      count++;
      return (
        <tr key={index} className={val.registeredAt ? "table-success" : ""}>
          <th scope="row">{count}</th>
          <td>
            {`${val.name}`}
            {val.des ? " / " : ""}
            {`${val.des} `}
          </td>
          {/* <td>{`${val.des}`}</td> */}
          <td>{`${val.imei}`}</td>
          <td>
            {val.deviceType === 1
              ? "Emergency"
              : val.deviceType === 2
              ? "Medical"
              : "Fire"}
            <span>
              <img
                src={require(`../../../../../assets/pins/${
                  val.iconName || "bike"
                }.png`)}
                alt=""
                title={val.iconName}
                style={{ marginLeft: "20px", width: "18px" }}
              />
            </span>
          </td>
          <td>{`${val.contact}`}</td>
          <td>
            {val.type === 1 ? (
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-mobile-screen"
                title="Mobile"
                // onClick={() => alert("TODO")}
              ></i>
            ) : (
              <>
                <i
                  style={{ fontSize: "20px", bottom: -5, position: "relative" }}
                  className="fa fa-light fa-car"
                  title="Tracker"
                  // onClick={() => alert("TODO")}
                ></i>
                <i
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    top: -10.5,
                    left: -18,
                    // color: "red",
                    zIndex: 11111,
                  }}
                  className="fa fa-light fa-wifi"
                  title="Tracker"
                  // onClick={() => alert("TODO")}
                ></i>
              </>
            )}
          </td>
          <td>
            {new Date(val.createdAt).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </td>

          <td> {val.group?.name} </td>
          <td>
            <a
              className={`nav-link ${user.roleId === 1 ? "" : "disabled"}`}
              href="#"
            >
              <i
                style={{
                  fontSize: "20px",
                  color: "blue",
                  opacity: user.roleId === 1 ? 1 : 0.6,
                }}
                className="fa fa-light fa-pencil"
                onClick={() => {
                  dispatch(setEditDeviceData(val));
                }}
                title="Edit"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target="#addDevice"
              ></i>
              <i
                style={{
                  fontSize: "20px",
                  color: "red",
                  marginLeft: "10px",
                  opacity: user.roleId === 1 ? 1 : 0.6,
                }}
                className="fa fa-light fa-trash"
                onClick={() => deleteDevice(val.id)}
                title="Delete"
              ></i>
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Devices List"}
        name={"listDevices"}
        icon={"fa fa-mobile-screen"}
      >
        <div className="row">
          <div className="col-10">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add Device"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addDevice"}
              ></i>
            </a>
          </div>
          <div className="col-2">
            <div className="float-end">
              <div
                className=""
                style={{
                  width: "120px",
                  backgroundColor: "#d1e7dd",
                  padding: "2px 13px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Registered
              </div>
            </div>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={name}
                      placeholder={"Name"}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Name / Des</label>
                  </th>
                  {/* <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={des}
                      placeholder={""}
                      onChange={(e) => setDes(e.target.value)}
                    />
                    <label className="col-form-label">Description</label>
                  </th> */}
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={imei}
                      placeholder={""}
                      onChange={(e) => setImei(e.target.value)}
                    />
                    <label className="col-form-label">Device ID</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={uniqueId}
                      onChange={(e) => setUniqueId(e.target.value)}
                      placeholder={""}
                    />
                    <label className="col-form-label">Device Type</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      placeholder={""}
                    />
                    <label className="col-form-label">Contact</label>
                  </th>
                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="1">Mobile</option>
                      <option value="2">Tracker</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Type</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      // value={username}
                      placeholder={"Created At"}
                      // onChange={(e) => (e.target.value)}
                      disabled={true}
                    />
                    <label className="col-form-label">Created At</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      // value={username}
                      placeholder={"Group"}
                      // onChange={(e) => (e.target.value)}
                      disabled={true}
                    />
                    <label className="col-form-label">Group</label>
                  </th>
                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            width: "40px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody>{getList()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListDevices;
