/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Marker, Popup } from "@urbica/react-map-gl";

const MarkerAndPop = (props) => {
  const [pop, setPop] = useState(false);

  return (
    <>
      <Marker
        longitude={props.item.lng}
        latitude={props.item.lat}
        onClick={() => setPop(true)}
      >
        <div style={{ zIndex: 5, cursor: "pointer" }}>
          <img
            src={require(`../../../../../../assets/pins/${props.item.markerLogoName}.png`)}
            alt=""
            style={{ width: "34px" }}
          />
        </div>
      </Marker>
      {pop ? (
        <Popup
          longitude={props.item.lng}
          latitude={props.item.lat}
          closeButton={false}
          closeOnClick={false}
          offset={[0, -20]}
        >
          <div style={{ width: "120px" }}>
            <div className="float-end ">
              <a
                style={{ fontSize: "16px" }}
                onClick={() => {
                  setPop(false);
                }}
                href="#"
                className="nav-link"
              >
                <i className="fa fa-solid fa-xmark"></i>
              </a>
            </div>
            <div style={{ width: "100%", fontWeight: "bold" }}>
              {props.item.title}
            </div>
            <div style={{ width: "100%" }}>{props.item.des}</div>
            <div className="float-end" style={{ width: "100%" }}>
              <a
                style={{ fontSize: "18px" }}
                onClick={() => {
                  props.editMarker(props.item);
                  props.setPage("Add");
                }}
                href="#"
                className="nav-link float-end"
              >
                <i className="fa fa-solid fa-pencil"></i>
              </a>
              <a
                style={{ fontSize: "18px", marginRight: "10px", color: "red" }}
                onClick={() => {
                  props.deleteMarker(props.item.id);
                }}
                href="#"
                className="nav-link float-end"
              >
                <i className="fa fa-solid fa-trash-can"></i>
              </a>
            </div>
          </div>
        </Popup>
      ) : null}
    </>
  );
};

export default MarkerAndPop;
