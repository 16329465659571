/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setZoom } from "../../../../redux/slices/mapSlice";

const MapZoom = (props) => {
  const mapZoom = useSelector((state) => state.map.mapZoom);
  const dispatch = useDispatch();

  return (
    <>
      {mapZoom ? (
        <div
          className="rotate "
          style={{
            position: "absolute",
            zIndex: 1,
            top: 115,
            right: -15,
            // border: "1px solid #37747C",
            // padding: "10px",
            // background: "#37747C",
            // opacity: 0.3,
          }}
        >
          <div>
            <div
              style={{
                position: "relative",
                top: 28,
                left: 122,
              }}
            >
              <a href="#" className="nav-link">
                <i
                  style={{
                    fontSize: "24px",
                    color: "#438AA2",
                  }}
                  className="fa-solid fa-square-plus"
                  onClick={() => {
                    if (mapZoom < 18) {
                      dispatch(setZoom(mapZoom + 1));
                    }
                  }}
                ></i>
              </a>
            </div>
            <input
              type="range"
              className="slider"
              style={{ zIndex: 1, boxShadow: "1px 2px 9px grey" }}
              max="18"
              min="1"
              value={mapZoom}
              onChange={(e) => dispatch(setZoom(parseInt(e.target.value)))}
            />
            <div
              style={{
                position: "relative",
                top: 31,
                right: 81,
                transform: "rotate(90deg)",
              }}
            >
              <a href="#" className="nav-link">
                <i
                  style={{
                    fontSize: "24px",
                    color: "#438AA2",
                  }}
                  onClick={() => {
                    if (mapZoom > 1) {
                      dispatch(setZoom(mapZoom - 1));
                    }
                  }}
                  className="fa-solid fa-square-minus"
                ></i>
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MapZoom;
