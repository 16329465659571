/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAnnouncement,
  GetAnnouncements,
  SearchAnnouncements,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import { URL } from "../../../../../config";
import {
  setActionVal,
  setConfirmText,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";

const ListAnn = (props) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("");

  const announcements = useSelector((state) => state.user.announcements);
  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);
  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  const dispatch = useDispatch();

  useEffect(() => {
    GetAnnouncements(dispatch);
  }, []);

  const search = async () => {
    dispatch(setIsLoading(true));
    await SearchAnnouncements(dispatch, {
      title,
      body,
      type,
    });
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "ann") {
      deleteAction(actionVal.id);
      dispatch(setActionVal(null));
    }
    // alert(actionVal.action);
  }, [actionVal]);

  const deleteAnn = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this announcement?",
        type: "ann",
        id,
      })
    );
    delBtn.click();
  };

  const deleteAction = async (id) => {
    await DeleteAnnouncement(dispatch, { id });
    await search();
  };

  const getList = () => {
    let count = 0;
    return announcements?.map((val, index) => {
      // console.log(`${URL}upload/${val.attachment}`);
      count++;
      return (
        <tr key={index}>
          <th scope="row">{count}</th>
          <td>{`${val.title}`}</td>
          {/* <td></td> */}
          <td>
            <div> {`${val.body}`} </div>
            <div>
              {val.attachment ? (
                <div className="col-12">
                  <div
                    style={{
                      marginTop: "5px",
                      // width: "260px",
                      // height: "46px",
                      backgroundColor: "#d9fdd3",
                      borderRadius: "10px",
                      boxShadow: "1px 1px 9px grey",
                      float: "left",
                    }}
                  >
                    <div
                      style={{
                        paddingBottom: -6,
                        borderRadius: 25,
                      }}
                    >
                      <audio
                        controls
                        src={`${URL}upload/${val.attachment}`}
                        className="send"
                      ></audio>
                    </div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
              ) : null}
            </div>
          </td>
          <td>
            {new Date(val.createdAt).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </td>
          <td>{`${val.user?.firstname || ""} ${val.user?.lastname || ""}`}</td>
          <td style={{ textAlign: "center" }}>
            {val.type ? (
              // <i
              //   style={{
              //     fontSize: "22px",
              //     marginTop: val.attachment ? "24px" : "0px",
              //   }}
              //   title="Responder Announcement"
              //   className="fa-solid fa-registered"
              // ></i>
              <img
                alt=""
                title="Responder Announcement"
                src={require("../../../../../assets/pins/resann.png")}
                style={{
                  width: "34px",
                  // padding: "2px",
                }}
              />
            ) : (
              // <i
              //   style={{
              //     fontSize: "22px",
              //     marginTop: val.attachment ? "24px" : "0px",
              //   }}
              //   title="Customer Announcement"
              //   className="fa-solid fa-copyright"
              // ></i>
              <img
                alt=""
                title="Customer Announcement"
                src={require("../../../../../assets/pins/houseann.png")}
                style={{
                  width: "30px",
                  // padding: "2px",
                }}
              />
            )}
          </td>
          <td>
            <a
              className={`dropdown-item ${user.roleId === 1 ? "" : "disabled"}`}
              href="#"
              style={{
                marginTop: val.attachment ? "24px" : "0px",
                opacity: user.roleId === 1 ? 1 : 0.6,
              }}
            >
              <i
                style={{ fontSize: "20px", color: "red", marginLeft: "10px" }}
                className="fa fa-light fa-trash"
                onClick={() => deleteAnn(val.id)}
                title="Delete"
              ></i>
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Announcements List"}
        name={"listAnnouncements"}
        icon={"fa fa-list"}
      >
        <div className="row">
          <div className="col-12">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add Announcement"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#createAnnouncements"}
              ></i>
            </a>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th style={{ width: "240px" }}>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={title}
                      placeholder={""}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <label className="col-form-label">Title</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={body}
                      placeholder={""}
                      onChange={(e) => setBody(e.target.value)}
                    />
                    <label className="col-form-label">Body</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      // value={body}
                      placeholder={""}
                      disabled={true}
                      // onChange={(e) => setBody(e.target.value)}
                    />
                    <label className="col-form-label">Created At</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      // value={body}
                      placeholder={""}
                      disabled={true}
                      // onChange={(e) => setBody(e.target.value)}
                    />
                    <label className="col-form-label">Created By</label>
                  </th>
                  {/* <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={body}
                      placeholder={""}
                      // onChange={(e) => }
                      disabled={true}
                    />
                    <label className="col-form-label">Attachment</label>
                  </th> */}

                  <th style={{ minWidth: "80px", maxWidth: "80px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value={0}>Customer</option>
                      <option value={1}>Responder</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">For User</label>
                  </th>

                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            width: "40px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody>{getList()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListAnn;
